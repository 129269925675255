import { IBillingPaymentAccountComponent, IPreBillingComponent } from '../../interface/component/bill/i-bill-component'
// defineStoreをインポート
import {defineStore} from 'pinia'

export const useRegistrationSettlementDialogStore = defineStore('registrationSettlementDialogStore', {
    state: () => ({
        display: false as boolean,
        noCallback: null as any,
        yesCallback: null as any,
        message: "" as string,
        preBillComponent: [] as IPreBillingComponent[],
    }),
    actions: {
        addSettlementBPaymentAccountComponent() {
            return initialiseSettlementPaymentAccountComponent() as IBillingPaymentAccountComponent;
        }
    }
})

// 入金ダイアログ
function initialiseSettlementPaymentAccountComponent(): IBillingPaymentAccountComponent {
    return {
        accountingItemId: 0,
        accountingItemName: '',
        accountingItemNameE: '',	
        accountingItemPrintTitle: '',
        accountingItemPrintTitleE: '',
        accountingItemInterfaceCd01: '',
        accountingItemInterfaceCd02: '',
        billingAddressId: 0,
        billingAddressName: '',
        paymentAmount: 0,
        roomInfomationId: 0,
        packagePlanId: 0,
        bRoomUnitId: 0
    } as IBillingPaymentAccountComponent
}