import { IBillingComponent, IBillingDetailComponent, IBillingPaymentComponent, IBillingPlanComponent } from './../../interface/component/bill/i-bill-component';
// defineStoreをインポート
import {defineStore} from 'pinia'
import { useAuthStore } from '../auth';

export const useCheckInDialogStore = defineStore('checkInDialogStore', {
    state: () => ({
        display: false as boolean,
        billingComponent: null as IBillingComponent | null,
        staffNo: "" as string,
        noCallback: null as any,
        yesCallback: null as any,
        message: "" as string,
    }),
    actions: {
        addBillingComponent() {
            return initialiseBillingComponent() as IBillingComponent;
        },
        addBillingPaymentComponent() {
            return initialiseBillingPaymentComponent() as IBillingPaymentComponent
        },
        addBillingPlanComponent() {
            return initialiseBillingPlanComponent() as IBillingPlanComponent
        },
        addBillingDetailComponent() {
            return initialiseBillingDetailComponent() as IBillingDetailComponent
        }

    }
})

// 領収情報
function initialiseBillingComponent() {
    const authStore = useAuthStore();
    return {
        groupId: authStore.targetStore?.groupId,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID
        // bRoomInformationId: 0,	//	部屋情報ID
        bGuestInformationId: 0,	// ゲスト情報ID

        billingPayments: [] as IBillingPaymentComponent[],
        billingPlans: [] as IBillingPlanComponent[],
        billingDetails: [] as IBillingDetailComponent[],
    } as IBillingComponent;
}

// 領収支払情報
function initialiseBillingPaymentComponent(): IBillingPaymentComponent {
    const authStore = useAuthStore();
    return {
        id: 0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID
        // bRoomInformationId: 0,	//	部屋情報ID
        billId: 0,	//	精算情報ID

        paymentMethodId: 0,	// 
        paymentMethodName: "",	// 
        paymentAccountId: 0,	//
        paymentAccountName: "",	//
        paymentManageReceivable: "", //
        paymentBookkeepingCd: "", //

        paymentAmount: 0,	//

        paymentDate: "",
        bussinessDate: "",
        status: "",
        billPage: 0,
        paymentRoomId: 0,
        paymentRoomName: "",
        accountBussinessDate: "",
        accountStatus: "",
        exportedStatus: "",
        billingAddressId: 0,
        billingAddressName: ""
    }
}

// 領収プラン情報
function initialiseBillingPlanComponent(): IBillingPlanComponent {
    const authStore = useAuthStore();
    return{
        id: 0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID
        bRoomInformationId: 0,	//	部屋情報ID
        billId: 0,	//	精算情報ID

        bOrderId: 0,	//	利用明細情報ID

        planId: 0,	// 企画(パッケージ)id
        planName: "",	// 企画(パッケージ)名
        planCode: "",	// 企画(パッケージ)コード

        useDate: "", //CConvertor.formatJpDateTime(new Date(), false, false),	// 利用年月日

        useRoomId: 0,	// 利用部屋
        useRoomNo: "",	// 利用部屋名

        unitPrice: 0,	// 単価
        quantity: 0,	// 数量

        amount: 0,	// 合計金額
        taxation: '',   // 課税・非課税
        taxRateType: '',  // 消費税区分
        taxRate: 0,	// 消費税率
        taxAmount: 0,	// 消費税額

        totalAmount: 0,	// 合計金額


        billRoomId: 0,	// 伝票 部屋ID
        billRoomNo: "",	// 伝票 部屋NO

        perPaxRate: 0,	//	大人単価（一人料金)
        perChildA70Rate: 0,	//	子供A単価（一人料金)
        perChildB50Rate: 0,	//	子供B単価（一人料金)
        perChildC30Rate: 0,	//	子供C単価（一人料金)
        perChildDrate: 0,	//	子供D単価（一人料金)
        perChildErate: 0,	//	子供E単価（一人料金)
        perChildFrate: 0,	//	子供F単価（一人料金)
        perChildOtherRate: 0,	//	子供その他単価（一人料金)

        perRoomPaxCount: 0,	//	1室利用人数
        roomPaxMaleCount: 0,	//	大人人員(男性)
        roomPaxFemaleCount: 0,	//	大人人員(女性)
        roomChildA70Count: 0,	//	子供A人数
        roomChildB50Count: 0,	//	子供B人数
        roomChildC30Count: 0,	//	子供C人数
        roomChildDnoneCount: 0,	//	子供D人数
        roomChildEcount: 0,	//	子供E人数
        roomChildFcount: 0,	//	子供F人数
        roomChildOtherCount: 0,	//	子供その他人数

        billingDetails: [],
    }
}

// 利用明細
function initialiseBillingDetailComponent(): IBillingDetailComponent {
    const authStore = useAuthStore();
    return{
        id: 0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID
        bRoomInformationId: 0,	//	部屋情報ID
        billId: 0,	//	精算情報ID

        billPage: 0, // ビルページ

        bOrderId: 0,	//	利用明細情報ID

        useDate: "", //CConvertor.formatJpDateTime(new Date(), false, false),	// 利用年月日

        useRoomId: 0,	// 利用部屋
        useRoomNo: "",	// 利用部屋名
        itemId:	0,	// 商品コード
        itemName: "",	// 商品名
        itemNameE: "",	// 商品名（英語）
        itemNameCd: "", // 商品コード

        itemBookkeepingCd: "",	//	商品コード

        unitPrice: 0,	// 単価
        quantity: 0,	// 数量

        amount: 0,	// 合計金額
        taxation: '',   // 課税・非課税
        taxRateType: '',  // 消費税区分
        taxRate: 0,	// 消費税率
        taxAmount: 0,	// 消費税額

        totalAmount: 0,	// 合計金額


        billRoomId: 0,	// 伝票 部屋ID
        billRoomNo: "",	// 伝票 部屋NO

        perPaxRate: 0,	//	大人単価（一人料金)
        perChildA70Rate: 0,	//	子供A単価（一人料金)
        perChildB50Rate: 0,	//	子供B単価（一人料金)
        perChildC30Rate: 0,	//	子供C単価（一人料金)
        perChildDrate: 0,	//	子供D単価（一人料金)
        perChildErate: 0,	//	子供E単価（一人料金)
        perChildFrate: 0,	//	子供F単価（一人料金)
        perChildOtherRate: 0,	//	子供その他単価（一人料金)

        perRoomPaxCount: 0,	//	1室利用人数
        roomPaxMaleCount: 0,	//	大人人員(男性)
        roomPaxFemaleCount: 0,	//	大人人員(女性)
        roomChildA70Count: 0,	//	子供A人数
        roomChildB50Count: 0,	//	子供B人数
        roomChildC30Count: 0,	//	子供C人数
        roomChildDnoneCount: 0,	//	子供D人数
        roomChildEcount: 0,	//	子供E人数
        roomChildFcount: 0,	//	子供F人数
        roomChildOtherCount: 0,	//	子供その他人数

    }
}

