import { IUserTragetStoreInfo } from '@/interface/serverData/common/i-user-info';
import http from "@/http-common";
import { IInternalResponse } from "@/interface/api/i-internal-response";
import { IRegistrationListResponse } from "@/interface/api/registration/i-registration-list-response";
import { IRegistrationListComponent, IRegistrationListCondtionComponent } from "@/interface/component/registrationList/i-registration-list-component";
import { CConvertor } from "@/utils/c-convertor";
import { format } from "date-fns";
import { useAuthStore } from "@/store/auth";


export default class RegistrationListService {

    getBussinessDate (condition: IRegistrationListCondtionComponent) {
        const sendData = {
            group_id: condition.groupId,
            company_id: condition.companyId,
            store_id: condition.storeId,
        }
        return new Promise<string>((resolve, reject) => {
            try {
                http.post("/api/registration/getBussinessDate", sendData)
                    .then(response => {
                        const bussinessDate = CConvertor.toCamelCaseObject(response.data) as string;
                        if (bussinessDate) {
                            resolve(bussinessDate);
                        } else {
                            reject(null);
                        }
                    }).catch(response => {
                        console.log("getBussinessDate Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    sendData (condition: IRegistrationListCondtionComponent,inputBy: string) {
        let checkInDateFrom: string;
        if (condition.checkInDateFrom === undefined || condition.checkInDateFrom == null) {
            checkInDateFrom = "";
        } else {
            checkInDateFrom = format(condition.checkInDateFrom!, 'yyyy-MM-dd');
        }
    
        let checkInDateTo: string;
        if (condition.checkInDateTo === undefined || condition.checkInDateTo == null) {
            checkInDateTo = "";
        } else {
            checkInDateTo = format(condition.checkInDateTo!, 'yyyy-MM-dd');
        }
    
        let checkOutDateFrom: string;
        if (condition.checkOutDateFrom === undefined || condition.checkOutDateFrom == null) {
            checkOutDateFrom = "";
        } else {
            checkOutDateFrom = format(condition.checkOutDateFrom!, 'yyyy-MM-dd');
        }
    
        let checkOutDateTo: string;
        if (condition.checkOutDateTo === undefined || condition.checkOutDateTo == null) {
            checkOutDateTo = "";
        } else {
            checkOutDateTo = format(condition.checkOutDateTo!, 'yyyy-MM-dd');
        }
        let checkInDate: string;
        if (condition.checkInDate === undefined || condition.checkInDate == null) {
            checkInDate = "";
        } else {
            checkInDate = condition.checkInDate;//format(condition.checkInDate!, 'yyyy-MM-dd');
        }
        let checkOutDate: string;
        if (condition.checkOutDate === undefined || condition.checkOutDate == null) {
            checkOutDate = "";
        } else {
            checkOutDate = condition.checkOutDate;//format(condition.checkOutDate!, 'yyyy-MM-dd');
        }
        let deadlineFrom: string;
        if (condition.deadlineFrom === undefined || condition.deadlineFrom == null) {
            deadlineFrom = "";
        } else {
            deadlineFrom = format(condition.deadlineFrom!, 'yyyy-MM-dd');
        }
        let deadlineTo: string;
        if (condition.deadlineTo === undefined || condition.deadlineTo == null) {
            deadlineTo = "";
        } else {
            deadlineTo = format(condition.deadlineTo!, 'yyyy-MM-dd');
        }
        const searchDate = condition.searchBussessDate;//format(new Date, 'yyyy-MM-dd');

        const sendData: { [key: string]: any; } = {
            group_id: condition.groupId,
            company_id: condition.companyId,
            store_id: condition.storeId,
            status: condition.status,
            booking_no: condition.bookingNo,
            check_in_date_from: checkInDateFrom,
            check_in_date_to: checkInDateTo,
            searchDate: searchDate,
            check_out_date_from: checkOutDateFrom,
            check_out_date_to: checkOutDateTo,
            pdfTitle: inputBy,
            check_in_date: checkInDate,
            check_out_date: checkOutDate,
            userName: condition.userName,
            companyName: condition.companyName,
            phonNumber: condition.phonNumber,
            deadlineFrom: deadlineFrom,
            deadlineTo: deadlineTo,
            marketingRoute: condition.marketingRoute,
            roomType: condition.roomType,
            roomNumber: condition.roomNumber,
            registerStatus: condition.registerStatus,
            freeNotes: condition.freeNotes,
            unsigned: condition.unsigned,
            travelAgencyNo: condition.travelAgencyNo,
            groupType: condition.groupType
        };
        return  sendData
    }

    getRegistrationList = (condition: IRegistrationListCondtionComponent,inputBy: string, roomNumberList?: any[]) => {
        const sendData = this.sendData(condition,inputBy)
        let registerStatus = null;
        let roomNumber = null;
        // const selectStatus = [
        //     {value: 'BOOK'},
        //     {value: 'STAY'},
        //     {value: 'TEMP_CHECK_IN'},
        //     {value: 'DEPARTURE'},
        //     {value: 'CHECK_OUT'},
        //     {value: 'NO_SHOW'},
        //     {value: 'TEMP_BOOK'},
        //     {value: 'WAIT_CANCEL'},
        //     {value: 'CANCEL'},
        // ];
        if (sendData.registerStatus) {
            if (sendData.registerStatus.length === 1 && sendData.registerStatus[0] == 'ALL') {
                // selectStatus.forEach((item: any) => {
                //     registerStatus.push(item.value);
                // })
                // const index = registerStatus.indexOf('ALL');  
                // if (index !== -1) {  
                //     registerStatus.splice(index, 1);  
                // }
                registerStatus = null;
            } else {
                registerStatus = [];
                sendData.registerStatus.forEach((item: any) => {
                    registerStatus.push(item);
                })
            }
        }
        if (sendData.roomNumber && roomNumberList) {
            if (sendData.roomNumber.length === 1 && sendData.roomNumber[0] == 'ALL') {
                // roomNumberList.forEach((item: any) => {
                //     roomNumber.push(item.id);
                // })
                // const index = roomNumber.indexOf('ALL');  
                // if (index !== -1) {  
                //     roomNumber.splice(index, 1);  
                // }
                roomNumber = null
            } else {
                roomNumber = [];
                sendData.roomNumber.forEach((item: any) => {
                    roomNumber.push(item);
                })
            }
        }
        sendData.registerStatus = registerStatus;
        sendData.roomNumber = roomNumber;
        console.log(JSON.stringify(sendData));

        return new Promise<IRegistrationListComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/search", sendData)
                    .then(response => {
                        // context.commit('SET_IS_AUTH', true);
                        const data = CConvertor.toCamelCaseObject(response.data) as IRegistrationListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const registrationList = data.registrationList as unknown as IRegistrationListComponent[];
                            console.log(JSON.stringify(registrationList))
                            resolve(registrationList);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("checkin Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    getScheduleList = (condition: IRegistrationListCondtionComponent) => {

        let checkInDate: string;
        if (condition.checkInDate === undefined) {
            checkInDate = "";
        } else {
            checkInDate = condition.checkInDate;//format(condition.checkInDate!, 'yyyy/MM/dd');
        }

        let checkOutDate: string;
        if (condition.checkOutDate === undefined) {
            checkOutDate = "";
        } else {
            checkOutDate = condition.checkOutDate;//format(condition.checkOutDate!, 'yyyy/MM/dd');
        }

        const sendData: { [key: string]: any; } = {
            group_id: condition.groupId,
            company_id: condition.companyId,
            store_id: condition.storeId,
            status: condition.status,
            booking_no: condition.bookingNo,
            check_in_date: checkInDate,
            check_out_date: checkOutDate,
        };

        console.log(JSON.stringify(sendData));

        return new Promise<IRegistrationListComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/search", sendData)
                    .then(response => {
                        // context.commit('SET_IS_AUTH', true);
                        const data = CConvertor.toCamelCaseObject(response.data) as IRegistrationListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const registrationList = data.registrationList as unknown as IRegistrationListComponent[];
                            console.log(JSON.stringify(registrationList))
                            resolve(registrationList);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("checkin Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }

    checkIn = (
        targetStore: IUserTragetStoreInfo,
        input_by: string) => {

        const sendData: { [key: string]: any; } = {
            group_id: targetStore.groupId,
            company_id: targetStore.companyId!,
            store_id: targetStore.storeId!,
            input_by: input_by,
            b_basic_information: {
                id: 1,
                b_room_unit: [
                    {
                        id: 1
                    },
                    {
                        id:2
                    }
                ]
            }
        };

        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/registration/checkin", sendData)
                    .then(response => {
                        // context.commit('SET_IS_AUTH', true);
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            console.debug(JSON.stringify(data));
                            resolve(data);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("checkin Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }
    downLoadPdf = (condition: IRegistrationListCondtionComponent,inputBy: string) => {
        const sendData = this.sendData(condition,inputBy)
        return new Promise<IInternalResponse>((resolve, reject) => {
            try {

                http.post("/api/registration/downLoadPdf", sendData)
                    .then(response => {
                        const data = CConvertor.toCamelCaseObject(response.data) as IInternalResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            console.debug(JSON.stringify(data));
                            resolve(data);
                        } else {
                            reject(data);
                        }
                        // const data = response.data as IInternalResponse;
                        resolve(data);
                    }).catch(response => {
                        console.log("downLoadPdf Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });
    }

    getRegistrationListToTopBar = (qParam: string) => {
        const authStore = useAuthStore();
        const { groupId, companyId, storeId } = authStore.targetStore!;
        const sendData = {
            group_id: groupId,
            company_id: companyId,
            store_id: storeId,
            qParam
        }
        return new Promise<IRegistrationListComponent[]>((resolve, reject) => {
            try {
                http.post("/api/registration/menuTopAutoSearch", sendData)
                    .then(response => {
                        // context.commit('SET_IS_AUTH', true);
                        const data = CConvertor.toCamelCaseObject(response.data) as IRegistrationListResponse;
                        console.log(data.returnCode);
                        if (data.returnCode === 200) {
                            const registrationList = data.registrationList as unknown as IRegistrationListComponent[];
                            console.log(JSON.stringify(registrationList))
                            resolve(registrationList);
                        } else {
                            reject(data);
                        }
                    }).catch(response => {
                        console.log("checkin Error");
                        console.log(response);
                        reject(response);
                    });
            } catch(e) {
                console.log(e+"処理失敗");
                reject(e);
            }
        });

    }
}
