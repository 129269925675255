import { defineStore } from 'pinia'
import { IPurposeComponent } from '@/interface/component/master/i-purpose-component';

export const usePurposeStore = defineStore('purpose', {
    state: () => ({
        purposes: [] as IPurposeComponent[],
    }),
    
    actions: {
        getPurpose(id: number){
            const resFind = this.purposes.find((data) => data.id === id);
            return resFind;
        },

        getPurposeName(id: number) {
            const resFind = this.purposes.find((data) => data.id === id);
            if (resFind) {
                return resFind.name;
            } else {
                return '';
            }
        },
    },
    // persist: {
    //     key: 'purpose',
    //     storage: localStorage,
    // }
})

