// defineStoreをインポート
import { defineStore } from 'pinia'

import { useAuthStore } from '@/store/auth'
import { useTemairazuStore } from '@/store/linkage/temairazuStore';

import { addDays, isBefore, isAfter, isSameDay, startOfDay, format, subDays } from 'date-fns';
import { CConvertor } from '@/utils/c-convertor';
import { StringControl } from '@/utils/StringControl';
import { DateControl } from '@/utils/DateControl';

import { IMultipCheckoutRoomInfoComponent, IRegistrationComponent } from '@/interface/component/registration/i-registration-component';
import { IRoomUnitComponent, IBFreememoComponent, IGuestInformationComponent } from '@/interface/component/registration/i-registration-component';
import { IRoomInformationComponent, IBArrangementComponent } from '@/interface/component/registration/i-registration-component';
import { IOrderComponent } from '@/interface/component/registration/i-registration-component';
import { IBPaymentComponent } from '@/interface/component/registration/i-registration-component';
import { IPlanArrangementDropDownComponent, IPlanDropDownComponent, IPlanItemDropDownComponent } from '@/interface/component/master/i-plan-component';

import { IInformationOptionComponent } from '@/interface/component/registration/i-registration-component';

import { IPreBillingComponent, IBillingPaymentAccountComponent } from '@/interface/component/bill/i-bill-component';

import { IBBasicInformation, IBBasicRateInformation, IBGuestInformation, IBInformationOption, IBMember, IBRoomInformation, IBRoomRateInformation, IBRoomUnit } from '@/interface/registration/i-registration';
import { IReceiptDetailComponent } from '@/interface/component/registration/i-registration-receipt-component';
import { useReceiptDialogStore } from './receiptDialogStore';
import { useArrangementStore } from '@/store/common/arrangementStore';
import { useRoomAssignStore } from '@/store/registration/roomAssignStore';


export const useRegistrationStore = defineStore('registration', {
    state: () => ({
        // registration: IRegistrationDetail,
        bBasicInformation: initializeComponent() as IRegistrationComponent,
        roomIdx: 0 as number,
        roomInfoIdx: 0 as number,
        roomActiveIndex: 0 as number,   // Room TabIndex
        guestInfoIndex: 0 as number,   // ゲストInfo
        // operationHistories: [] as IOperationHistoryComponent[] | [],
        historyType: 'OPERATION' as string,
        mode: '' as string,
        isLoadComplete: false as boolean,
        awaitLoadTask: [] as {taskId: string, setTimeId: number}[]
    }),
    getters: {
        /**
         * 当面の部屋単位取得
         * @param state 
         */
        getRoomUnit(state) {
            return state.bBasicInformation.bRoomUnit[state.roomIdx];
        },

        /**
         * 選択している部屋情報取得
         * @param state 
         */
        getRoomInfomation(state) {

            if (this.getRoomUnit.bRoomInformation === undefined) {
                return [];
            }

            return state.bBasicInformation.bRoomUnit[state.roomIdx].bRoomInformation[state.roomInfoIdx];
        },

        /**
         * 売上明細取得
         * @param state 
         */
        getOrders(state) {
            const orders = [] as IOrderComponent[];

            if (this.getRoomUnit.bOrders === undefined) {
                return [];
            }

            // 利用明細を取得
            for (let index = 0; index < this.getRoomUnit.bOrders.length; index++) {
                const order = this.getRoomUnit.bOrders[index];
                orders.push(order);
            }

            return orders as IOrderComponent[];
        },

        /**
         * 入金明細取得
         * @param state 
         */
        getPayments(state) {
            const payments = [] as IBPaymentComponent[];

            if (this.getRoomUnit.bPayments === undefined) {
                return [];
            }

            for (let index = 0; index < this.getRoomUnit.bPayments.length; index++) {
                const payment = this.getRoomUnit.bPayments[index];
                payments.push(payment);
            }

            return payments as IBPaymentComponent[];
        },


        /**
         * 利用条件画面部屋の明細
         * @param state 
         */
        getRoomInfoDispOrders(state) {
            return (targetDate: string) => {

                console.log(targetDate);

                const orders = [] as IOrderComponent[];

                if (this.getRoomUnit.bOrders === undefined) {
                    return [];
                }

                // 未清算利用明細を取得
                for (let index = 0; index < this.getRoomUnit.bOrders.length; index++) {
                    const order = this.getRoomUnit.bOrders[index];

                    if (targetDate == order.useDate && order.bRoomUnitId === this.getRoomUnit.id &&
                        (order.packagePlanId !== 0 && order.packagePlanId !== undefined)) {
                        orders.push(order);
                    }

                }

                console.log(orders);

                return orders as IOrderComponent[];
            }
        },

        /**
         * 利用明細画面表示用の売上明細、部屋の売上を合わせる
         * @param state
         */
        getUseDetailDispOrders(state) {

            return (mode: string) => {
                const orders = [] as IOrderComponent[];

                if (this.getRoomUnit.bOrders === undefined) {
                    return [];
                }

                let rowIndex = 0;

                const allOrders = [] as IOrderComponent[];

                // 当面の部屋
                this.getRoomUnit.bOrders?.forEach(order => {
                    allOrders.push(order);
                });

                if (this.getRoomUnit.isRepresentativeRoom === 'YES') {
                    if (this.bBasicInformation.payIndivisualOrBulk === 'BULK') {
                        // 全明細代表部屋払い
                        console.log('全明細代表部屋払い')

                        this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                            if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                                roomUnit.bOrders?.forEach(order => {
                                    if (order.bRoomUnitId !== this.getRoomUnit.id) {
                                        allOrders.push(order);
                                    }
                                })
                            }
                        })

                    } else if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE') {
                        // 室料のみ代表部屋払い
                        console.log('室料のみ代表部屋払い')

                        this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                            if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                                roomUnit.bOrders?.forEach(order => {
                                    if (order.packagePlanId !== 0 && order.packagePlanId !== undefined && order.bRoomUnitId !== this.getRoomUnit.id) {
                                        allOrders.push(order);
                                    }
                                })
                            }
                        })

                    } else {
                        // 個別精算
                        console.log('個別精算')
                    }
                }

                // allOrders.sort((a, b) => {
                //     if (a.dataType === 'add' && b.dataType === undefined) {
                //         return 1
                //     } else if (b.dataType === 'add' && a.dataType === undefined) {
                //         return -1
                //     } else {
                //         if (a.bRoomUnitId === 0 && b.bRoomUnitId === 0) {
                //             return 0;
                //         } else if (a.bRoomUnitId === 0) {
                //             return 1;
                //         } else if (b.bRoomUnitId === 0) {
                //             return -1;
                //         } else if (a.bRoomUnitId > b.bRoomUnitId) {
                //             return 1;
                //         } else if (a.bRoomUnitId < b.bRoomUnitId) {
                //             return -1;
                //         } else {
                            
                //             if ((a.packagePlanId !== undefined && a.packagePlanId !== 0) && (b.packagePlanId !== undefined && b.packagePlanId !== 0)) {
                //                 return 0;
                //             } else if ((a.packagePlanId !== undefined && a.packagePlanId !== 0) && (b.packagePlanId === undefined || b.packagePlanId === 0)) {
                //                 return -1;
                //             } else if ((a.packagePlanId === undefined || a.packagePlanId === 0) && (b.packagePlanId !== undefined && b.packagePlanId !== 0)) {
                //                 return 1;
                //             } else {
                                
                //                 const a_is_arrangement = (a.bArrangementId !== undefined && a.bArrangementId !== 0 && a.bArrangementId !== null) 
                //                 || (a.tempBArrangementId !== undefined);
                                
                //                 const b_is_arrangement = (b.bArrangementId !== undefined && b.bArrangementId !== 0 && b.bArrangementId !== null) 
                //                 || (b.tempBArrangementId !== undefined);

                //                 if (a_is_arrangement && b_is_arrangement) {
                //                     return 0;
                //                 } else if (a_is_arrangement && !b_is_arrangement) {
                //                     return -1;
                //                 } else if (!a_is_arrangement && b_is_arrangement) {
                //                     return 1;
                //                 } else {
                //                     if (!a.useDate && !b.useDate) {
                //                         return 0;
                //                     } else if (!a.useDate) {
                //                         return 1;
                //                     } else if (!b.useDate) {
                //                         return -1;
                //                     } else if (a.useDate.toUpperCase() > b.useDate.toUpperCase()) {
                //                         return 1;
                //                     } else if (a.useDate.toUpperCase() < b.useDate.toUpperCase()) {
                //                         return -1;
                //                     } else {
                //                         return 0;
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // })

                for (let index = 0; index < allOrders.length; index++) {
                    
                    const order = allOrders[index];

                    if (order.packagePlanId !== 0 && order.packagePlanId !== undefined) {
                        // プラン 部屋情報
                        const roomOrders = orders.filter((obj) => {
                            // plan
                            const isPlanOrder = obj.packagePlanId !== 0 && obj.packagePlanId !== undefined;

                            // same room unit
                            const isSameRoomUnit = (obj.bRoomUnitId !== 0 && obj.bRoomUnitId !== undefined) && obj.bRoomUnitId === order.bRoomUnitId;

                            const isSameRoomUnitByTemp = (obj.bRoomUnitId === 0 || obj.bRoomUnitId === undefined) && obj.tempUnitId === order.tempUnitId;
                            
                            // (obj.bRoomUnitId == order.bRoomUnitId && order.bRoomUnitId !== 0) || ((obj.bRoomUnitId === 0 || obj.bRoomUnitId === undefined) && obj.tempUnitId === order.tempUnitId);

                            // same room information
                            const isSameRoomInformation = (obj.bRoomInformationId !== 0 && obj.bRoomInformationId !== undefined) && obj.bRoomInformationId === order.bRoomInformationId;

                            // new room unit  new room infor
                            const isSameRoomByTemp = (obj.bRoomInformationId === 0 || obj.bRoomInformationId === undefined) && obj.tempBRoomInformationId === order.tempBRoomInformationId;

                            const repaymentFlag = order.amount < 0 ? obj.amount < 0 : obj.amount >= 0;

                            return isPlanOrder && (isSameRoomUnit || isSameRoomUnitByTemp) && (isSameRoomInformation || isSameRoomByTemp) && repaymentFlag;
                        });
    
                        if (roomOrders.length > 0) {
                            // 存在している場合、合計加える
                            
                            roomOrders[0].amount += order.amount + (order.otaxAmount?order.otaxAmount:0);
                            // HOTELOS_DEV-544 start
                            roomOrders[0].unitPrice = roomOrders[0].amount;
                            roomOrders[0].quantity = 1;
                            // HOTELOS_DEV-544 end

                            order.rowIndex = roomOrders[0].rowIndex;
                        } else {
                            // 存在していない場合、部屋情報のOrders 追加
                            const roomOrder = initialiseBOrderComponent(order.useDate) as IOrderComponent;
            
                            roomOrder.groupId = order.groupId;
                            roomOrder.companyId = order.companyId;
                            roomOrder.storeId = order.storeId;
                            roomOrder.bBasicInformationId = order.bBasicInformationId;
                            roomOrder.bRoomUnitId = order.bRoomUnitId;
                            roomOrder.bRoomInformationId = order.bRoomInformationId;
                            
                            roomOrder.useDate = order.useDate;
                            // roomOrder.itemName = StringControl.isEmpty(order.bRoomInformationPackagePlanPrintTitle) ? order.packagePlanName : order.bRoomInformationPackagePlanPrintTitle;
                            roomOrder.itemName = StringControl.isEmpty(order.bRoomInformationPackagePlanPrintTitle) ? (StringControl.isEmpty(order.itemPrintTitle) ? order.itemName : order.itemPrintTitle) : order.bRoomInformationPackagePlanPrintTitle;
                            
                            roomOrder.unitPrice = order.unitPrice;
                            // HOTELOS_DEV-544 start
                            // roomOrder.quantity = 1;
                            roomOrder.quantity = order.quantity;
                            // HOTELOS_DEV-544 end

                            roomOrder.amount = order.amount + (order.otaxAmount?order.otaxAmount:0);
        
                            roomOrder.billId = order.billId;
                            roomOrder.billPage = order.billPage;
                            roomOrder.billStatus = order.billStatus;
                            roomOrder.billBussinessDate = order.billBussinessDate;
        
                            roomOrder.useRoomId = order.useRoomId;
                            roomOrder.useRoomNo = order.useRoomNo;
                            
                            roomOrder.packagePlanId = order.packagePlanId;
                            roomOrder.packagePlanName = order.packagePlanName;
                            roomOrder.packagePlanCode = order.packagePlanCode;

                            roomOrder.needCheck = order.needCheck;

                            roomOrder.tempUnitId = order.tempUnitId;
                            roomOrder.tempBRoomInformationId = order.tempBRoomInformationId;

                            switch (mode) {
                                case "REGISTREGIST_SLIP":
                                    if (roomOrder.billStatus === 'DONE') {   //DONE: 精算済
                                        order.rowIndex = -1;
                                        continue;
                                    }
                                    if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE' && this.getRoomUnit.isRepresentativeRoom === 'NO') {
                                        // 室料のみ代表部屋払い && 子供部屋
                                        order.rowIndex = -1;
                                        continue;
                                    }
                                    break;
                                case "CHECK_OUT":
                                    if (roomOrder.billStatus === 'DONE') {   //DONE: 精算済
                                        order.rowIndex = -1;
                                        continue;
                                    }
                                    if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE' && this.getRoomUnit.isRepresentativeRoom === 'NO') {
                                        // 室料のみ代表部屋払い && 子供部屋
                                        order.rowIndex = -1;
                                        continue;
                                    }
                                    break;
                                case "MID_FLOW_PAYMENT": {
                                    if (roomOrder.billStatus === 'DONE') {   //DONE: 精算済
                                        order.rowIndex = -1;
                                        continue;
                                    }

                                    if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE' && this.getRoomUnit.isRepresentativeRoom === 'NO') {
                                        // 室料のみ代表部屋払い && 子供部屋
                                        order.rowIndex = -1;
                                        continue;
                                    }

                                    break;
                                }
                                case "SHOW_PAID_DETAIL": {
                                    if (roomOrder.billStatus === 'NOT' || roomOrder.billStatus === null) {   // NOT: 未清算
                                        order.rowIndex = -1;
                                        continue;
                                    }
                                    if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE' && this.getRoomUnit.isRepresentativeRoom === 'NO') {
                                        order.rowIndex = -1;
                                        // 室料のみ代表部屋払い && 子供部屋
                                        continue;
                                    }
                                    break;
                                }
                                default:
                                    break;
                            }
        
                            if ((this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === order.billPage)
                            || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                            || (this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === 0)) {

                                roomOrder.rowIndex = rowIndex;
                                order.rowIndex = rowIndex;
                                rowIndex++;

                                // todo 428
                                // if (this.getRoomUnit.bRoomInformationForDel === undefined || !this.getRoomUnit.bRoomInformationForDel?.includes(roomOrder.bRoomInformationId)) {
                                //     orders.push(roomOrder);
                                // }

                                orders.push(roomOrder);
                            }
                        }
                    } else {
                        // プラン以外
                        switch (mode) {
                            case "REGISTREGIST_SLIP":
                                if (order.billStatus === 'DONE') {   //DONE: 精算済
                                    order.rowIndex = -1;
                                    continue;
                                }
                                break;
                            case "CHECK_OUT":
                                if (order.billStatus === 'DONE') {   //DONE: 精算済
                                    order.rowIndex = -1;
                                    continue;
                                }
                                break;
                            case "MID_FLOW_PAYMENT": {
                                if (order.billStatus === 'DONE') {   //DONE: 精算済
                                    order.rowIndex = -1;
                                    continue;
                                }
                                break;
                            }
                            case "SHOW_PAID_DETAIL": {
                                if (order.billStatus === 'NOT') {   // NOT: 未清算
                                    order.rowIndex = -1;
                                    continue;
                                }
                                break;
                            }
                            default:
                                break;
                        }

                        if (order.packagePlanId === 0) {
                            if ((this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === order.billPage)
                            || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                            || (this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === 0)) {

                                order.rowIndex = rowIndex;
                                rowIndex++;
                                orders.push(order);
                            }
                        }
                    }
                }

                return orders as IOrderComponent[];
            }
        },

        /**
         * 利用明細画面表示用の売上明細の合計
         * @param state
         */
        getUseDetailDispOrdersTotal(state) {
            return (mode: string) => {
                const orders = this.getUseDetailDispOrders(mode);

                let total = 0;
                for(const order of orders) {
                    total += order.amount;
                }

                return total.toLocaleString('en-US', {style: 'currency', currency: 'USD'}) as string;
            };
        },
        
        /**
         * 利用明細画面表示用の入金明細
         * @param state
         */
        getUseDetailDispBPayments(state) {

            return (mode: string) => {
                const bPayments = [] as IBPaymentComponent[];

                if (this.getRoomUnit.bPayments === undefined) {
                    return [];
                }

                let rowIndex = 0;

                const allPayments = [] as IBPaymentComponent[];

                this.getRoomUnit.bPayments?.forEach(payment => {
                    allPayments.push(payment);
                });

                if (this.getRoomUnit.isRepresentativeRoom === 'YES') {
                    if (this.bBasicInformation.payIndivisualOrBulk === 'BULK') {
                        // 全明細代表部屋払い
                        console.log('全明細代表部屋払い')

                        this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                            if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                                roomUnit.bPayments?.forEach(payment => {
                                    if (payment.bRoomUnitId !== this.getRoomUnit.id) {
                                        allPayments.push(payment);
                                    }
                                })
                            }
                        })

                    } else if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE') {
                        // 室料のみ代表部屋払い
                        console.log('室料のみ代表部屋払い')

                    } else {
                        // 個別精算
                        console.log('個別精算')
                    }
                }

                // allPayments.sort((a, b) => {
                //     if (a.dataType === 'add' && b.dataType === undefined) {
                //         return 1
                //     } else if (b.dataType === 'add' && a.dataType === undefined) {
                //         return -1
                //     } else {
                //         if (a.bRoomUnitId === 0 && b.bRoomUnitId === 0) {
                //             return 0;
                //         } else if (a.bRoomUnitId === 0) {
                //             return 1;
                //         } else if (b.bRoomUnitId === 0) {
                //             return -1;
                //         } else if (a.bRoomUnitId > b.bRoomUnitId) {
                //             return 1;
                //         } else if (a.bRoomUnitId < b.bRoomUnitId) {
                //             return -1;
                //         } else if (!a.paymentDate && !b.paymentDate) {
                //             return 0;
                //         } else if (!a.paymentDate) {
                //             return 1;
                //         } else if (!b.paymentDate) {
                //             return -1;
                //         } else if (a.paymentDate.toUpperCase() > b.paymentDate.toUpperCase()) {
                //             return 1;
                //         } else if (a.paymentDate.toUpperCase() < b.paymentDate.toUpperCase()) {
                //             return -1;
                //         } else {
                //             return 0;
                //         }
                //     }
                // })

                // 未清算利用明細を取得
                for (let index = 0; index < allPayments.length; index++) {
                    const bPayment = allPayments[index];

                    switch (mode) {
                        case "REGISTREGIST_SLIP":
                            if (bPayment.billStatus === 'DONE') {   //DONE: 精算済
                                bPayment.rowIndex = -1;
                                continue;
                            }
                            break;
                        case "CHECK_OUT":
                            if (bPayment.billStatus === 'DONE') {   //DONE: 精算済
                                bPayment.rowIndex = -1;
                                continue;
                            }
                            break;
                        case "MID_FLOW_PAYMENT": {
                            if (bPayment.billStatus === 'DONE') {   //DONE: 精算済
                                bPayment.rowIndex = -1;
                                continue;
                            }
                            break;
                        }
                        case "SHOW_PAID_DETAIL": {
                            if (bPayment.billStatus === 'NOT') {   // NOT: 未清算
                                bPayment.rowIndex = -1;
                                continue;
                            }
                            break;
                        }
                        default:
                            break;
                    }

                    if ((this.getRoomUnit.displayBillId === bPayment.billId && this.getRoomUnit.displayBillPage === bPayment.billPage) 
                    || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                    || (this.getRoomUnit.displayBillId === bPayment.billId && this.getRoomUnit.displayBillPage === 0)) {
                        bPayment.rowIndex = rowIndex;
                        rowIndex++;
                        bPayments.push(bPayment);
                    }
                    
                }

                return bPayments as IBPaymentComponent[];
            }
        },
        
        /**
         * 表示用手配
         * @param state 
         */
        getArrangementDispWithMode(state) {
            return (oldData: boolean, onlyTicket: boolean) => {
                const authStore = useAuthStore();
                const arrangementStore = useArrangementStore();
                const roomAssignStore = useRoomAssignStore();
                const bArrangements = [] as IBArrangementComponent[];

                if (this.getRoomUnit.bArrangements === undefined) {
                    return [];
                }

                let rowIndex = 0;

                const allArrangements = [] as IBArrangementComponent[];

                this.getRoomUnit.bArrangements?.forEach(item => {
                    allArrangements.push(item);
                });

                
                // if (a.dataType === 'add' && b.dataType === undefined) {
                //     return 1
                // } else if (b.dataType === 'add' && a.dataType === undefined) {
                //     return -1
                // } else {
                            
                //     const a_is_plan_arrangement = (a.planArrangementId !== undefined && a.planArrangementId !== 0 && a.planArrangementId !== null);
                            
                //     const b_is_plan_arrangement = (b.planArrangementId !== undefined && b.planArrangementId !== 0 && b.planArrangementId !== null);
                            
                //     if (a_is_plan_arrangement && !b_is_plan_arrangement) {
                //         return -1;
                //     } else if (!a_is_plan_arrangement && b_is_plan_arrangement) {
                //         return 1;
                //     } else {
                //         if (a.bRoomUnitId === 0 && b.bRoomUnitId === 0) {
                //             return 0;
                //         } else if (a.bRoomUnitId === 0) {
                //             return 1;
                //         } else if (b.bRoomUnitId === 0) {
                //             return -1;
                //         } else if (a.bRoomUnitId > b.bRoomUnitId) {
                //             return 1;
                //         } else if (a.bRoomUnitId < b.bRoomUnitId) {
                //             return -1;
                //         } else if (!a.useDate && !b.useDate) {
                //             return 0;
                //         } else if (!a.useDate) {
                //             return 1;
                //         } else if (!b.useDate) {
                //             return -1;
                //         } else if (a.useDate.toUpperCase() > b.useDate.toUpperCase()) {
                //             if (!a.id) {
                //                 return 0;
                //             }
                //             return 1;
                //         } else if (a.useDate.toUpperCase() < b.useDate.toUpperCase()) {
                //             if (!a.id) {
                //                 return 0;
                //             }
                //             return -1;
                //         } else {
                //             if (!a.id && !b.id) {
                //                 return 0;
                //             } else if (!a.id) {
                //                 return 1;
                //             } else if (!b.id) {
                //                 return -1;
                //             } else if (a.id > b.id) {
                //                 return 1;
                //             } else if (a.id < b.id) {
                //                 return -1;
                //             } else {
                //                 return 0;
                //             }
                //         }
                //     }
                // }

                for (let i = 0; i < allArrangements.length; i++) {

                    const item = allArrangements[i];

                    const isDataFilterPassed = isSameDay(new Date(item.useDate), new Date(authStore.bussinessDate!)) 
                            || isAfter(new Date(item.useDate), new Date(authStore.bussinessDate!));

                    if ((oldData && !onlyTicket) || (oldData && onlyTicket && (item.arrangementTicketManageType === 'YES' || item.planArrangementTicketManageType === 'YES')) || 
                    (!oldData && !onlyTicket && isDataFilterPassed) ||
                    (oldData && onlyTicket && isDataFilterPassed && (item.arrangementTicketManageType === 'YES' || item.planArrangementTicketManageType === 'YES')) || 
                    item.dataType === 'add') {

                        item.rowIndex = rowIndex;
                        rowIndex++;
                        bArrangements.push(item);
                    }
                }

                return bArrangements as IBArrangementComponent[];
            }
        },


        /**
         * 今のステータスによって、選択可能のステータスを取得
         * @param state 
         */
        getSelectableBookingStatus(state) {
            const status = state.bBasicInformation.bRoomUnit[state.roomIdx].originStatus;
            const bookingNo = state.bBasicInformation.bookingNo;

            const authStore = useAuthStore();

            let array = [];

            // 初期化
            if (isSameDay(state.bBasicInformation.bRoomUnit[state.roomIdx].checkInDate, new Date(authStore.bussinessDate!))) {
                array = [
                    { "label": "予約", "value": "BOOK" },
                    { "label": "チェックイン", "value": "STAY" },
                ];
            } else {
                array = [
                    { "label": "予約", "value": "BOOK" }
                ];
            }

            if (this.getRoomUnit.tempBookDeadline !== undefined && this.getRoomUnit.tempBookDeadline !== null) {
                // ステータスを強制的に仮予約に変更
                this.getRoomUnit.status = 'TEMP_BOOK';

                array = [
                    { "label": "予約", "value": "BOOK" },
                    { "label": "仮予約", "value": "TEMP_BOOK" }
                ];

                return array;
            }
            

            if (!StringControl.isEmpty(bookingNo)) {

                switch (status) {
                    case 'BOOK':
                        if (isSameDay(state.bBasicInformation.bRoomUnit[state.roomIdx].checkInDate, new Date(authStore.bussinessDate!))) {
                            if (isSameDay(state.bBasicInformation.bRoomUnit[state.roomIdx].checkInDate, state.bBasicInformation.bRoomUnit[state.roomIdx].checkOutDate)) {
                                array = [
                                    { "label": "予約", "value": "BOOK" },
                                    // { "label": "出発予定", "value": "DEPARTURE" },
                                    { "label": "チェックイン", "value": "STAY" },
                                    { "label": "仮予約", "value": "TEMP_BOOK" },
                                    { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                                    { "label": "キャンセル", "value": "CANCEL" },                    
                                ];
                            } else {
                                array = [
                                    { "label": "予約", "value": "BOOK" },
                                    { "label": "チェックイン", "value": "STAY" },
                                    { "label": "仮予約", "value": "TEMP_BOOK" },
                                    { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                                    { "label": "キャンセル", "value": "CANCEL" },                    
                                ];
                            }
                        } else {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "仮予約", "value": "TEMP_BOOK" },
                                { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                                { "label": "キャンセル", "value": "CANCEL" },                    
                            ];
                        }
                        break;

                    case 'STAY':
                        if (isSameDay(state.bBasicInformation.bRoomUnit[state.roomIdx].checkInDate, new Date(authStore.bussinessDate!))) {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "滞在", "value": "STAY" }, 
                            ];
                        } else {
                            array = [
                                { "label": "滞在", "value": "STAY" },
                            ];
                        }
                        break;
                    case 'DEPARTURE':
                        if (isSameDay(state.bBasicInformation.bRoomUnit[state.roomIdx].checkInDate, new Date(authStore.bussinessDate!))) {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "出発予定", "value": "DEPARTURE" },
                            ];
                        } else {
                            array = [
                                { "label": "出発予定", "value": "DEPARTURE" },
                            ];
                        }
                        break;
                    case 'CHECK_OUT':
                        if (isBefore(new Date(authStore.bussinessDate!), this.getRoomUnit.checkOutDate) || isSameDay(new Date(), this.getRoomUnit.checkOutDate)) {
                            array = [
                                // { "label": "滞在", "value": "STAY" },
                                { "label": "出発予定", "value": "DEPARTURE" }, // チェックアウト当日
                                { "label": "出発済み", "value": "CHECK_OUT" },
                                // { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                                // { "label": "キャンセル", "value": "CANCEL" },     
                            ];
                        } else {
                            array = [
                                // { "label": "滞在", "value": "STAY" },
                                // { "label": "出発予定", "value": "DEPARTURE" }, // チェックアウト当日
                                { "label": "出発済み", "value": "CHECK_OUT" },
                                // { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                                // { "label": "キャンセル", "value": "CANCEL" },     
                            ];
                        }
                        
                        break;
                    case 'WAIT_CANCEL':
                        if (isBefore(new Date(authStore.bussinessDate!), this.getRoomUnit.checkInDate)) {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                            ];
                        } else {
                            array = [
                                { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                            ];
                        }
                        
                        break;
                    case 'CANCEL':
                        if (isBefore(new Date(authStore.bussinessDate!), this.getRoomUnit.checkInDate)) {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "キャンセル", "value": "CANCEL" }, 
                            ];
                        } else {
                            array = [
                                { "label": "キャンセル", "value": "CANCEL" }, 
                            ];
                        }
                        
                        break;
                    case 'TEMP_BOOK':
                        if (isBefore(new Date(authStore.bussinessDate!), this.getRoomUnit.checkInDate)) {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "仮予約", "value": "TEMP_BOOK" },
                            ];
                        } else {
                            array = [
                                { "label": "予約", "value": "BOOK" },
                                { "label": "仮予約", "value": "TEMP_BOOK" },
                            ];
                        }
                        
                        break;
                    case 'NO_SHOW':
                        array = [
                            // { "label": "予約", "value": "BOOK" },
                            // { "label": "仮予約", "value": "TEMP_BOOK" },
                            { "label": "ノーショウ", "value": "NO_SHOW" },
                            // { "label": "キャンセル待ち", "value": "WAIT_CANCEL" },
                            // { "label": "キャンセル", "value": "CANCEL" }, 
                        ];
                        break;
                    default:
                        break;
                }
            }

            
            return array;
        },

        
        /**
         * 部屋単位の人数を取得
         * @param state 
         */
        getPersonCount(state) {
            let count = 0;
            for (let i = 0; i < this.getRoomUnit.bRoomInformation.length; i++) {
                const roomInfo = this.getRoomUnit.bRoomInformation[i];

                const roomPerson = (roomInfo.roomPaxMaleCount + roomInfo.roomPaxFemaleCount + 
                    roomInfo.roomChildA70Count + roomInfo.perChildB50Rate + 
                    roomInfo.roomChildC30Count + roomInfo.roomChildDnoneCount + 
                    roomInfo.roomChildEcount + roomInfo.roomChildFcount + 
                    roomInfo.roomChildOtherCount);

                if (roomPerson > count) {
                    count = roomPerson;
                }
            }

            return count;
        },

        /**
         * 部屋単位のステータスによって、操作可能を取得
         * @param state 
         */
        roomUnitOperatable(state) {
            const authStore = useAuthStore();
            const businessDate = new Date(authStore.bussinessDate!);
            const checkOutDate = this.getRoomUnit.checkOutDate;

            if ((this.getRoomUnit.originStatus === 'CHECK_OUT' || this.getRoomUnit.originStatus === 'NO_SHOW'
                || this.getRoomUnit.originStatus === 'TEMP_BOOK' || this.getRoomUnit.originStatus === 'WAIT_CANCEL'
                || this.getRoomUnit.originStatus === 'CANCEL') && isAfter(businessDate, checkOutDate))
            {
                console.log('roomUnitOperatable false')
                return false;
            } else {
                console.log('roomUnitOperatable true')
                return true;
            }
        },

        /**
         * キャンセル・キャンセル待ち・ノーショウのステータス以内、操作可能を取得
         * @param state 
         */
        roomUnitTempOperatable(state) {
            const statusFrom = this.getRoomUnit.originStatus === 'CANCEL' || this.getRoomUnit.originStatus === 'WAIT_CANCEL' || this.getRoomUnit.originStatus === 'NO_SHOW' ;
            const statusTo = this.getRoomUnit.status === 'CANCEL' || this.getRoomUnit.status === 'WAIT_CANCEL' || this.getRoomUnit.status === 'NO_SHOW';
            if (statusFrom && statusTo && this.getRoomUnit.originStatus !== undefined) {
                return false;
            } else {
                return true;
            }
        },

        /**
         * 部屋単位の請求金額合計を取得
         * @param state 
         */
        getBillAmount(state) {
            let amount = 0;

            this.getRoomUnit.bOrders?.forEach(order => {
                amount += order.amount;
            });

            this.getRoomUnit.bPayments?.forEach(payment => {
                amount -= payment.paymentAmount;
            });

            return amount;
        },

        /**
         * レジストレーション制御・売上
         */
        getStatusControlWithOrder(state) {
            if (this.getRoomUnit.originStatus === 'DEPARTURE' || this.getRoomUnit.originStatus === 'TEMP_CHECK_IN') {
                return true;
            } else if (this.getRoomUnit.originStatus === 'STAY') {

                const authStore = useAuthStore();

                const businessDate = new Date(authStore.bussinessDate!);

                const ci = isBefore(this.getRoomUnit.checkInDate, businessDate) || isSameDay(this.getRoomUnit.checkInDate, businessDate);
                console.log('ci = ' + ci);
                const co = isAfter(this.getRoomUnit.checkOutDate, businessDate) || isSameDay(this.getRoomUnit.checkOutDate, businessDate);
                console.log('co = ' + co);

                if (ci && co) {
                    return true;
                } else {
                    return false;
                }
            } else if (this.getRoomUnit.originStatus === 'BOOK') {
                const authStore = useAuthStore();
                const businessDate = new Date(authStore.bussinessDate!);
                const co = isAfter(this.getRoomUnit.checkOutDate, businessDate) || isSameDay(this.getRoomUnit.checkOutDate, businessDate);
                if (co) {
                    return true;
                }
            } else {
                return false;
            }
        },

        /**
         * レジストレーション制御・入金
         */
        getStatusControlWithPayment(state) {
            if (this.getRoomUnit.originStatus === 'STAY' || this.getRoomUnit.originStatus === 'BOOK') {
                // レジステータス：予約
                // 「業務日付 <= C/I」の場合は入金可能にしてください。
                /**
                const authStore = useAuthStore();
                const businessDateStr = authStore.bussinessDate!;
                const checkInDateStr = DateControl.formatDateToStringForFormat1(this.getRoomUnit.checkInDate);
                const checkOutDateStr = DateControl.formatDateToStringForFormat1(this.getRoomUnit.checkOutDate);
                
                if (isAfter(new Date(checkInDateStr), new Date(businessDateStr)) || isBefore(new Date(checkOutDateStr), new Date(businessDateStr))) {
                    return false;
                }
                */
                
                return true;
            } else if (this.getRoomUnit.originStatus === 'DEPARTURE' || this.getRoomUnit.originStatus === 'TEMP_CHECK_IN') {
                return true;
            } else {
                return false;
            }
        },

        /**
         * レジストレーション制御・精算
         * @param mode  mid / CO
         */
        getStatusControlWithSettlement(state) {
            return (mode: string) => {
                // mode : mid / CO

                if (mode === 'CO') {
                    if (this.getRoomUnit.originStatus === 'DEPARTURE') {
                        // 出発予定のみチェックアウト可
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (this.getRoomUnit.originStatus === 'STAY' || this.getRoomUnit.originStatus === 'DEPARTURE' || this.getRoomUnit.originStatus === 'TEMP_CHECK_IN') {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },

        /**
         * レジストレーション制御・領収書
         * @param mode : NEW / AGAIN / HISTORY 
         */
        getStatusControlWithReceipt(state) {
            return (mode: string) => {

                let total = 0;
                let minus = false;
                this.getOrders.forEach(item => {
                    total += item.amount;
                    if (item.amount < 0) {
                        minus = true;
                    }
                });

                if (total === 0 && !minus) {
                    return false;
                }

                // mode : NEW / AGAIN / HISTORY
                //       新規 / 再 / 発行履歴		
                if (this.getRoomUnit.originStatus === 'DEPARTURE' || this.getRoomUnit.originStatus === 'TEMP_CHECK_IN') {
                    return true;
                } else if (this.getRoomUnit.originStatus === 'BOOK') {
                    if (mode === 'HISTORY') {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.getRoomUnit.originStatus === 'STAY') {
                    return true;
                } else if (this.getRoomUnit.originStatus === 'CHECK_OUT') {
                    if (mode === 'AGAIN' || mode === 'HISTORY') {
                        return true;
                    } else {
                        return false;
                    }
                } else if (this.getRoomUnit.originStatus === 'NO_SHOW' || this.getRoomUnit.originStatus === 'TEMP_BOOK' 
                || this.getRoomUnit.originStatus === 'WAIT_CANCEL' || this.getRoomUnit.originStatus === 'CANCEL') {
                    if (mode === 'HISTORY') {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return false;
                }
            }
        },

        getSelectableRooms(state) {
            return () => {
                const rooms = [] as any[];
                const indexs = [] as any[];
                
                const addElement = (item : IRoomInformationComponent) => {
                    if (!indexs.includes(item.assignedRoomId) && item.assignedRoomId !== 0) {
                        const r = {
                            label: item.assignedRoomNumber,
                            value: item.assignedRoomId
                        }
    
                        rooms.push(r);
                        indexs.push(item.assignedRoomId);
                    }
                };
                
                if (this.getRoomUnit.isRepresentativeRoom === 'YES' 
                && (this.bBasicInformation.payIndivisualOrBulk === 'BULK' || this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE')) {
                    
                    for (let index = 0; index < this.bBasicInformation.bRoomUnit.length; index++) {
                        const roomUnit = this.bBasicInformation.bRoomUnit[index];

                        if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                            roomUnit.bRoomInformation.forEach(item => {
                                addElement(item);
                            });
                        }
                    }
                    
                } else {
                    this.getRoomUnit.bRoomInformation.forEach(item => {
                        addElement(item);
                    });
                }
    
                return rooms;
            }
        },

        /**
         * 業務日付に精算済みあるのをチェック行う
         * @param orderIndexs 選択した売上
         * @param paymentIndexs 選択した入金
         * @returns 0  : 精算取消可 1+  : 精算取消不可
         */
        billDoneWithOtherBusinessDay(state) {
            return (orderIndexs : number[], paymentIndexs: number[]) => {
                
                const authStore = useAuthStore();

                const allOrderFlag = orderIndexs.length === 0;
                const allPaymentFlag = paymentIndexs.length === 0;

                let error = 0

                this.getUseDetailDispOrders('SHOW_PAID_DETAIL').forEach(order => {
                    if ((allOrderFlag || orderIndexs.indexOf(order.rowIndex) > -1) && 
                    order.billStatus === 'DONE' && !isSameDay(new Date(order.billBussinessDate!), new Date(authStore.bussinessDate!))) {
                        error++;
                    }
                })

                if (error > 0) {
                    return error;
                }

                this.getUseDetailDispBPayments('SHOW_PAID_DETAIL').forEach(payment => {
                    if ((allPaymentFlag || paymentIndexs.indexOf(payment.rowIndex) > -1) && 
                    payment.billStatus === 'DONE' && !isSameDay(new Date(payment.billBussinessDate!), new Date(authStore.bussinessDate!))) {
                        error++;
                    }
                });

                
                return error;
            }
        },

        /**
         * 減泊の際、減泊先の売上を取得
         * @param targetCheckOutDate 減泊先C/O日
         */
        getPreDeleteOrders(state) {
            return (targetCheckOutDate: Date) => {

                // 減泊先の利用情報を計算
                // 減泊後の泊数
                const targetNights : number = DateControl.differenceInDaysWithStartOfDay(targetCheckOutDate, this.getRoomUnit.checkInDate);

                // 元の泊数
                const oldNights = this.getRoomUnit.nights;

                if (targetNights >= oldNights) {
                    // 減泊ではない場合
                    return [] as IOrderComponent[];
                } else {

                    const resultOrders = [] as IOrderComponent[];

                    const diff: number = oldNights - targetNights;

                    // 減泊先の利用情報Id array
                    const preDeleteRoomInfoIds = [] as number[];
  
                    // 減泊先の利用情報
                    for (let index = 0; index < diff; index++) {
    
                        const i = this.getRoomUnit.bRoomInformation.length - 1 - index;
                        const preDeleteRoomInfo = this.getRoomUnit.bRoomInformation![i];
    
                        if (preDeleteRoomInfo?.id !== undefined && preDeleteRoomInfo?.id !== 0) {
                            preDeleteRoomInfoIds.push(preDeleteRoomInfo.id);
                        }
                    }
    
                    this.getRoomUnit.bOrders?.forEach(item => {
                        if (item.packagePlanId !== 0 && item.packagePlanId !== undefined) {
                            // 室料の場合
                            if (item.bRoomInformationId !== undefined && item.bRoomInformationId !== 0 ) {
                                // 利用情報あり
                                if (preDeleteRoomInfoIds.includes(item.bRoomInformationId)) {
                                    if (item.id !== undefined && item.id !== 0) {
                                        resultOrders.push(item);
                                    }
                                }
                            }
                        }                
                    });
                    
                    return resultOrders;
                }
            };
        },

        getPreDeleteCustomeArrangements(state) {
            return (targetCheckOutDate: Date) => {

                console.log('getPreDeletePlanArrangements');
                // 減泊先の利用情報を計算
                // 減泊後の泊数
                const targetNights : number = DateControl.differenceInDaysWithStartOfDay(targetCheckOutDate, this.getRoomUnit.checkInDate);

                // 元の泊数
                const oldNights = this.getRoomUnit.nights;

                if (targetNights >= oldNights) {
                    // 減泊ではない場合
                    return [] as IBArrangementComponent[];
                } else {

                    const resultArrangements = [] as IBArrangementComponent[];

                    const diff: number = oldNights - targetNights;

                    // 減泊先の利用情報Id array
                    const preDeleteRoomInfoDates = [] as string[];
                    const preDeleteRoomInfoIds = [] as any[];
  
                    // 減泊先の利用情報
                    for (let index = 0; index < diff; index++) {
    
                        const i = this.getRoomUnit.bRoomInformation.length - 1 - index;
                        const preDeleteRoomInfo = this.getRoomUnit.bRoomInformation![i];
    
                        if (preDeleteRoomInfo?.id !== undefined && preDeleteRoomInfo?.id !== 0) {
                            preDeleteRoomInfoIds.push(preDeleteRoomInfo?.id);
                        } else {
                            preDeleteRoomInfoDates.push(preDeleteRoomInfo.roomDate);
                        }
                    }
    
                    this.getRoomUnit.bArrangements?.forEach(item => {
                        if (StringControl.numberTypeIsEmpty(item.planArrangementId) && !StringControl.numberTypeIsEmpty(item.arrangementId)) {
                            // プラン手配
                            const date = item.tempBRoomInformationId?.substring('room_info_'.length) ?? '';
                            if (preDeleteRoomInfoDates.includes(date) || preDeleteRoomInfoIds.includes(item.bRoomInformationId)) {
                                resultArrangements.push(item);
                            }
                        }                
                    });
                    
                    return resultArrangements;
                }
            };
        },

        getPreDeletePlanArrangements(state) {
            return (targetCheckOutDate: Date) => {

                console.log('getPreDeletePlanArrangements');
                // 減泊先の利用情報を計算
                // 減泊後の泊数
                const targetNights : number = DateControl.differenceInDaysWithStartOfDay(targetCheckOutDate, this.getRoomUnit.checkInDate);

                // 元の泊数
                const oldNights = this.getRoomUnit.nights;

                if (targetNights >= oldNights) {
                    // 減泊ではない場合
                    return [] as IBArrangementComponent[];
                } else {

                    const resultArrangements = [] as IBArrangementComponent[];

                    const diff: number = oldNights - targetNights;

                    // 減泊先の利用情報Id array
                    const preDeleteRoomInfoDates = [] as string[];
  
                    // 減泊先の利用情報
                    for (let index = 0; index < diff; index++) {
    
                        const i = this.getRoomUnit.bRoomInformation.length - 1 - index;
                        const preDeleteRoomInfo = this.getRoomUnit.bRoomInformation![i];
    
                        if (preDeleteRoomInfo?.id !== undefined && preDeleteRoomInfo?.id !== 0) {
                            preDeleteRoomInfoDates.push(preDeleteRoomInfo.roomDate);
                        }
                    }
    
                    this.getRoomUnit.bArrangements?.forEach(item => {
                        if (item.planArrangementId !== 0 && item.planArrangementId !== undefined && item.planArrangementId !== null) {
                            // プラン手配
                            const date = item.tempBRoomInformationId?.substring('room_info_'.length) ?? '';
                            if (preDeleteRoomInfoDates.includes(date)) {
                                if (item.id !== undefined && item.id !== 0) {
                                    resultArrangements.push(item);
                                }
                            }
                        }                
                    });
                    
                    return resultArrangements;
                }
            };
        },


        /**
         * 一括チェックアウト対象リストを取得する
         * @param displayNotBillData 未精算を表示
         * @param selectedArray 選択しているRoomUnitId
         */
        multipCheckoutRoomInfos(state) {
            // displayNotBillData: 未精算を表示
            return (displayNotBillData: boolean, selectedArray: number[]) => {

                const result = [] as IMultipCheckoutRoomInfoComponent[];
                
                let representativeRoom = undefined;

                for (let i = 0; i < state.bBasicInformation.bRoomUnit.length; i++) {
                    const preCheckoutRoomInfo = {
                        roomUnitId: 0,
                        roomNo: '',
                        roomGuestKana: '',
                        checkInDateStr: '',
                        checkOutDateStr: '',
                        billAmout: 0,
                        checkOutEnble: false,
                        selected: false,
                        status: '',
                        representative: ''
                    } as IMultipCheckoutRoomInfoComponent;

                    const roomUnit = state.bBasicInformation.bRoomUnit[i];

                    if (roomUnit.isRepresentativeRoom === 'YES') {
                        representativeRoom = roomUnit;
                    }

                    preCheckoutRoomInfo.roomUnitId = roomUnit.id!;
                    preCheckoutRoomInfo.checkInDate = roomUnit.checkInDate;
                    preCheckoutRoomInfo.checkOutDate = roomUnit.checkOutDate;
                    preCheckoutRoomInfo.checkInDateStr = DateControl.formatDateToStringForFormat1(roomUnit.checkInDate);
                    preCheckoutRoomInfo.checkOutDateStr = DateControl.formatDateToStringForFormat1(roomUnit.checkOutDate);

                    // dev-827 add
                    preCheckoutRoomInfo.representative = roomUnit.isRepresentativeRoom;

                    preCheckoutRoomInfo.selected = selectedArray.includes(preCheckoutRoomInfo.roomUnitId!);

                    const lastRoomInfo = roomUnit.bRoomInformation[roomUnit.bRoomInformation.length - 1];
                    preCheckoutRoomInfo.roomNo = lastRoomInfo.assignedRoomNumber;

                    const guest = roomUnit.bGuestInformation[roomUnit.bGuestInformation.length - 1];
                    preCheckoutRoomInfo.roomGuestKana = guest.guestNameSingleByte!;

                    // dev-827 add status
                    preCheckoutRoomInfo.status = roomUnit.status;

                    let orderTotal = 0;
                    for (const order of roomUnit.bOrders!) {
                        // 未精算を表示
                        if (order.billStatus !== 'DONE') {
                            orderTotal += order.amount;
                        }
                    }

                    let paymentTotal = 0;
                    for (const payment of roomUnit.bPayments!) {
                        if (payment.billStatus !== 'DONE') {
                            paymentTotal += payment.paymentAmount;
                        }
                    }
                    preCheckoutRoomInfo.billAmout = orderTotal - paymentTotal;

                    // dev-827 add
                    const notStayStatus: string[] = ['NO_SHOW', 'CANCEL'];
                    const findNotStayStatus = notStayStatus.find(item => item === roomUnit.status);
                    if (findNotStayStatus) {
                        preCheckoutRoomInfo.billAmoutStr = '0 円' ;
                    } else {
                        preCheckoutRoomInfo.billAmoutStr = StringControl.formatCurrency(preCheckoutRoomInfo.billAmout) + ' 円' ;
                    }

                    if (preCheckoutRoomInfo.billAmout !== 0) {
                        preCheckoutRoomInfo.checkOutEnble = false;
                    } else {
                        preCheckoutRoomInfo.checkOutEnble = true;
                    }

                    if (roomUnit.originStatus === 'CHECK_OUT') {
                        preCheckoutRoomInfo.checkOutEnble = false;
                    }

                    if (displayNotBillData) {
                        result.push(preCheckoutRoomInfo);
                    } else {
                        if (preCheckoutRoomInfo.billAmout === 0) {
                            result.push(preCheckoutRoomInfo);
                        }
                    }
                }

                if (representativeRoom === undefined) {
                    return [];
                }

                for (const r of result) {
                    if (!isSameDay(r.checkOutDate, representativeRoom!.checkOutDate)) {
                        r.checkOutEnble = false;
                    }
                }

                return result;
            }
        }

    },
    actions: {
        initializeComponent() {
            return initializeComponent() as IRegistrationComponent;
        },
        addBRoomUnit() {
            return initializeRoomUnitComponent() as IRoomUnitComponent;
        },
        addBRoomInformation() {
            return initializeRoomInfoComponent() as IRoomInformationComponent;
        },
        addBGuestInformation() {
            return initializeGuestInfoComponent() as IGuestInformationComponent;
        },
        addOrderComponent(date: string) {
            return initialiseBOrderComponent(date) as IOrderComponent;
        },
        addBPaymentComponent() {
            return initialiseBPaymentComponent() as IBPaymentComponent;
        },
        addBFreememoComponent() {
            return initialiseBFreememoComponent() as IBFreememoComponent;
        },

        addBArrangementComponent() {
            return initializeBArrangementComponent() as IBArrangementComponent;
        },

        // bug-622 too long
        waitForDataToOperate(cycleMilliTime: number, taskId: string) {
            return new Promise((resolve)=>{
                if (this.isLoadComplete) {
                    resolve(true);
                } else {
                    const intervalId = setInterval(() => {
                        if (this.isLoadComplete) {
                            const clearTask = this.awaitLoadTask.find(item => {return item.taskId === taskId});
                            const clearTaskIndex = this.awaitLoadTask.findIndex(item => {return item.taskId === taskId});
                            clearInterval(clearTask?.setTimeId);
                            this.awaitLoadTask.splice(clearTaskIndex, 1);
                            resolve(true);
                        }
                    }, cycleMilliTime);
                    this.awaitLoadTask.push({taskId: taskId, setTimeId: intervalId});
                }
            });
        },
        
        /**
         * index移行に部屋情報をコピーする
         * @param index 
         */
        copyBRoomInformation(index : number) {
            const currentRoomInformation = this.getRoomUnit.bRoomInformation[index];

            // 部屋情報
            this.getRoomUnit.bRoomInformation.forEach((item, i) => {

                if (i > index) {
                    item.id = item.id?item.id:0;
                    item.groupId = currentRoomInformation.groupId;
                    item.companyId = currentRoomInformation.companyId;
                    item.storeId = currentRoomInformation.storeId;
                    item.bBasicInformationId = currentRoomInformation.bBasicInformationId;
                    item.bRoomUnitId = currentRoomInformation.bRoomUnitId;
                    item.roomTypeId = currentRoomInformation.roomTypeId;
                    item.roomTypeCode = currentRoomInformation.roomTypeCode;
                    item.roomTypeName = currentRoomInformation.roomTypeName;
                    item.roomCategory = currentRoomInformation.roomCategory;
                    item.viewType = currentRoomInformation.viewType;
                    item.smokingOrNonSmoking = currentRoomInformation.smokingOrNonSmoking;
                    item.perRoomPaxCount = currentRoomInformation.perRoomPaxCount;
                    item.roomPaxMaleCount = currentRoomInformation.roomPaxMaleCount;
                    item.roomPaxFemaleCount = currentRoomInformation.roomPaxFemaleCount;
                    item.roomChildA70Count = currentRoomInformation.roomChildA70Count;
                    item.roomChildB50Count = currentRoomInformation.roomChildB50Count;
                    item.roomChildC30Count = currentRoomInformation.roomChildC30Count;
                    item.roomChildDnoneCount = currentRoomInformation.roomChildDnoneCount;
                    item.roomChildEcount = currentRoomInformation.roomChildEcount;
                    item.roomChildFcount = currentRoomInformation.roomChildFcount;
                    item.roomChildOtherCount = currentRoomInformation.roomChildOtherCount;
                    item.roomChildA70Name = currentRoomInformation.roomChildA70Name;
                    item.roomChildB50Name = currentRoomInformation.roomChildB50Name;
                    item.roomChildC30Name = currentRoomInformation.roomChildC30Name;
                    item.roomChildDnoneName = currentRoomInformation.roomChildDnoneName;
                    item.roomChildEname = currentRoomInformation.roomChildEname;
                    item.roomChildFname = currentRoomInformation.roomChildFname;
                    item.roomChildOtherName = currentRoomInformation.roomChildOtherName;
                    item.facilities = currentRoomInformation.facilities;

                    // if (data.roomDates.includes(item.roomDate)) {
                    //     item.assignedRoomId = 0;
                    //     item.assignedRoomNumber = '';
                    // } else {
                    //     item.assignedRoomId = currentRoomInformation.assignedRoomId;
                    //     item.assignedRoomNumber = currentRoomInformation.assignedRoomNumber;
                    // }
                    item.assignedRoomId = currentRoomInformation.assignedRoomId;
                    item.assignedRoomNumber = currentRoomInformation.assignedRoomNumber;
                    
                    item.assignedRoomTypeId = currentRoomInformation.assignedRoomTypeId;
                    item.assignedRoomTypeName = currentRoomInformation.assignedRoomTypeName;

                    item.roomSpecialRequest = currentRoomInformation.roomSpecialRequest;
    
                    item.travelAgencyId = currentRoomInformation.travelAgencyId;
                    item.travelAgencyBookingNumber = currentRoomInformation.travelAgencyBookingNumber;
                    item.travelAgencyBookingDate = currentRoomInformation.travelAgencyBookingDate;
                    item.travelAgencyBookingTime = currentRoomInformation.travelAgencyBookingTime;
                    item.travelAgencyReportNumber = currentRoomInformation.travelAgencyReportNumber;
                    item.purposeId = currentRoomInformation.purposeId;
                    item.salesRouteId = currentRoomInformation.salesRouteId;
    
                    // item.packagePlanId = currentRoomInformation.packagePlanId;
                    // item.packagePlanName = currentRoomInformation.packagePlanName;
                    // item.packageType = currentRoomInformation.packageType;
                    // item.packagePlanCode = currentRoomInformation.packagePlanCode;
                    // item.packagePlanContent = currentRoomInformation.packagePlanContent;
                    
                    // item.packagePlanPrintTitle = currentRoomInformation.packagePlanPrintTitle;
                    // item.packagePlanPrintTitleE = currentRoomInformation.packagePlanPrintTitleE;

                    item.perPaxRate = currentRoomInformation.perPaxRate;
                    item.perChildA70Rate = currentRoomInformation.perChildA70Rate;
                    item.perChildB50Rate = currentRoomInformation.perChildB50Rate;
                    item.perChildC30Rate = currentRoomInformation.perChildC30Rate;
                    item.perChildDrate = currentRoomInformation.perChildDrate;
                    item.perChildErate = currentRoomInformation.perChildErate;
                    item.perChildFrate = currentRoomInformation.perChildFrate;
                    item.perChildOtherRate = currentRoomInformation.perChildOtherRate;
                    item.roomChildA70RateName = currentRoomInformation.roomChildA70RateName;
                    item.roomChildB50RateName = currentRoomInformation.roomChildB50RateName;
                    item.roomChildC30RateName = currentRoomInformation.roomChildC30RateName;
                    item.roomChildDrateName = currentRoomInformation.roomChildDrateName;
                    item.roomChildErateName = currentRoomInformation.roomChildErateName;
                    item.roomChildFrateName = currentRoomInformation.roomChildFrateName;
                    item.roomChildOtherRateName = currentRoomInformation.roomChildOtherRateName;
                    item.totalPerRoomRate = currentRoomInformation.totalPerRoomRate;
                    item.totalPerRoomConsumptionTax = currentRoomInformation.totalPerRoomConsumptionTax;
                    item.totalRoomHotSpringTax = currentRoomInformation.totalRoomHotSpringTax;
                    item.totalPerRoomServiceFee = currentRoomInformation.totalPerRoomServiceFee;
                    
    
                    item.fareMethodId = currentRoomInformation.fareMethodId;
                }
            });
        },

        /**
         * 選択売上と入金により、精算リスト作成
         * @param orderIndexs 
         * @param paymentIndexs 
         * @returns 
         */
        getSettlementPreBill(orderIndexs : number[], paymentIndexs: number[]) {

            const result: IPreBillingComponent[] = [];

            let lastRoomInfoId: number | null = null;
            let lastPlanId: number | null = null;

            const maxPages = [] as number[];

            this.getUseDetailDispOrders('MID_FLOW_PAYMENT').forEach(order => {
                if (orderIndexs.indexOf(order.rowIndex) > -1) {
                    if (maxPages.indexOf(order.billPage) === -1 && order.billPage !== null && order.billPage !== undefined) {
                        maxPages.push(order.billPage);
                    }
                }
            });

            this.getUseDetailDispBPayments('MID_FLOW_PAYMENT').forEach(payment => {
                if (paymentIndexs.indexOf(payment.rowIndex) > -1) {
                    if (maxPages.indexOf(payment.billPage) === -1 && payment.billPage !== null && payment.billPage !== undefined) {
                        maxPages.push(payment.billPage);
                    }
                }
            });

            const pageArr = maxPages.sort(function (a, b) {
                return a - b;
            });

            // get current date room 
            const authStore = useAuthStore();
            let roomId = 0;
            let roomNo = '';
            const roomInfo = this.getRoomUnit.bRoomInformation.find(roomInfo => {
                return roomInfo.roomDate === authStore.bussinessDate;
            });
            if (roomInfo === undefined) {
                const index = this.getRoomUnit.bRoomInformation.length - 1 < 0 ? 0 : this.getRoomUnit.bRoomInformation.length - 1;
                roomId = this.getRoomUnit.bRoomInformation[index].assignedRoomId;
                roomNo = this.getRoomUnit.bRoomInformation[index].assignedRoomNumber;
            } else {
                roomId = roomInfo.assignedRoomId;
                roomNo = roomInfo.assignedRoomNumber;
            }

            for (let i = 0; i < pageArr.length; i++) {

                const prePaymentAccounts: IBillingPaymentAccountComponent[] = [];
                const preBorders: IOrderComponent[] = [];
                const preBpayments: IBPaymentComponent[] = [];
                const preRoomInformationIds: number[] = [];

                const preBilling = {
                    bRoomInformationIds: preRoomInformationIds,
                    paymentAccounts: prePaymentAccounts,
                    bOrders: preBorders,
                    bPayments: preBpayments,
                    balance: 0
                } as IPreBillingComponent;

                const paymentAccount = initialiseSettlementPaymentAccountComponent();
                paymentAccount.paymentAmount = 0;
                paymentAccount.useRoomId = roomId;
                paymentAccount.useRoomNo = roomNo;
                prePaymentAccounts.push(paymentAccount);

                console.log(this.getRoomUnit.bOrders);

                this.getUseDetailDispOrders('MID_FLOW_PAYMENT').forEach((order, j) => {

                    if (order.billStatus !== 'DONE') {
                        if (order.billPage === pageArr[i]) {
                            if (order.packagePlanId === 0) {
                                // プラン以外の場合
                                if (orderIndexs.indexOf(order.rowIndex) > -1) {
                                    preBorders.push(order);
    
                                    preBilling.balance += order.amount;
                                }
                            } else {
                                
                                // プランの場合
                                if (orderIndexs.indexOf(order.rowIndex) > -1) {
                                    lastRoomInfoId = order.bRoomInformationId;
                                    lastPlanId = order.packagePlanId;
                                    preRoomInformationIds.push(order.bRoomInformationId);
                                }
                                
                                if (lastRoomInfoId === order.bRoomInformationId && lastPlanId === order.packagePlanId) {
                                    preBorders.push(order);
    
                                    preBilling.balance += order.amount;
    
                                    prePaymentAccounts.forEach(obj => {
                                        if (obj.packagePlanId === order.packagePlanId && obj.roomInfomationId === order.bRoomInformationId &&
                                            obj.packagePlanId !== 0 && obj.roomInfomationId !== 0) {
                                            obj.paymentAmount += order.amount;
                                        }
                                    })
                                }
                            }
                        }
                    }
                });

                this.getUseDetailDispBPayments('MID_FLOW_PAYMENT').forEach(payment => {
                    if (payment.billStatus !== 'DONE') {
                        if (payment.billPage === pageArr[i] && payment.status !== '') {
                            // プラン以外の場合
                            if (paymentIndexs.indexOf(payment.rowIndex) > -1) {
                                preBpayments.push(payment);
    
                                preBilling.balance += (payment.paymentAmount * -1);
                            }
                        }
                    }
                });

                result.push(preBilling);
            }

            return result;
        },

        /**
         * 領収書すべてデータを取得
         */
        getReceiptAllDetail(orderIndexs : number[], paymentIndexs: number[], displayBillStatus: string) {

            console.log('getReceiptAllDetail')

            const authStore = useAuthStore();
            const resultList = [] as IReceiptDetailComponent[];

            // 売上の明細
            const orders = [] as IOrderComponent[];

            if (this.getRoomUnit.bOrders === undefined) {
                return [];
            }

            let rowIndex = 0;

            const allOrders = [] as IOrderComponent[];

            // 当面の部屋
            this.getRoomUnit.bOrders?.forEach(order => {
                allOrders.push(order);
            });

            if (this.getRoomUnit.isRepresentativeRoom === 'YES') {
                if (this.bBasicInformation.payIndivisualOrBulk === 'BULK') {
                    // 全明細代表部屋払い
                    console.log('全明細代表部屋払い')

                    this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                        if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                            roomUnit.bOrders?.forEach(order => {
                                if (order.bRoomUnitId !== this.getRoomUnit.id) {
                                    allOrders.push(order);
                                }
                            })
                        }
                    })

                } else if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE') {
                    // 室料のみ代表部屋払い
                    console.log('室料のみ代表部屋払い')

                    this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                        if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                            roomUnit.bOrders?.forEach(order => {
                                if (order.packagePlanId !== 0 && order.packagePlanId !== undefined && order.bRoomUnitId !== this.getRoomUnit.id) {
                                    allOrders.push(order);
                                }
                            })
                        }
                    })

                } else {
                    // 個別精算
                    console.log('個別精算')
                }
            }

            allOrders.sort((a, b) => {
                if (a.dataType === 'add' && b.dataType === undefined) {
                    return 1
                } else if (b.dataType === 'add' && a.dataType === undefined) {
                    return -1
                } else {
                    if (a.bRoomUnitId === 0 && b.bRoomUnitId === 0) {
                        return 0;
                    } else if (a.bRoomUnitId === 0) {
                        return 1;
                    } else if (b.bRoomUnitId === 0) {
                        return -1;
                    } else if (a.bRoomUnitId > b.bRoomUnitId) {
                        return 1;
                    } else if (a.bRoomUnitId < b.bRoomUnitId) {
                        return -1;
                    } else {
                        
                        if ((a.packagePlanId !== undefined && a.packagePlanId !== 0) && (b.packagePlanId !== undefined && b.packagePlanId !== 0)) {
                            return 0;
                        } else if ((a.packagePlanId !== undefined && a.packagePlanId !== 0) && (b.packagePlanId === undefined || b.packagePlanId === 0)) {
                            return -1;
                        } else if ((a.packagePlanId === undefined || a.packagePlanId === 0) && (b.packagePlanId !== undefined && b.packagePlanId !== 0)) {
                            return 1;
                        } else {

                            if (!a.useDate && !b.useDate) {
                                return 0;
                            } else if (!a.useDate) {
                                return 1;
                            } else if (!b.useDate) {
                                return -1;
                            } else if (a.useDate.toUpperCase() > b.useDate.toUpperCase()) {
                                return 1;
                            } else if (a.useDate.toUpperCase() < b.useDate.toUpperCase()) {
                                return -1;
                            } else {
                                return 0;
                            }
                        }
                    }
                }
            })

            for (let index = 0; index < allOrders!.length; index++) {
                
                const order = allOrders[index];

                if (order.packagePlanId !== 0 && order.packagePlanId !== undefined) {
                    // プラン 部屋情報
                    const roomOrders = orders.filter((obj) => {
                        // plan
                        const isPlanOrder = obj.packagePlanId !== 0 && obj.packagePlanId !== undefined;

                        // same room unit
                        const isSameRoomUnit = (obj.bRoomUnitId !== 0 && obj.bRoomUnitId !== undefined) && obj.bRoomUnitId === order.bRoomUnitId;

                        const isSameRoomUnitByTemp = (obj.bRoomUnitId === 0 || obj.bRoomUnitId === undefined) && obj.tempUnitId === order.tempUnitId;
                        
                        // (obj.bRoomUnitId == order.bRoomUnitId && order.bRoomUnitId !== 0) || ((obj.bRoomUnitId === 0 || obj.bRoomUnitId === undefined) && obj.tempUnitId === order.tempUnitId);

                        // same room information
                        const isSameRoomInformation = (obj.bRoomInformationId !== 0 && obj.bRoomInformationId !== undefined) && obj.bRoomInformationId === order.bRoomInformationId;

                        // new room unit  new room infor
                        const isSameRoomByTemp = (obj.bRoomInformationId === 0 || obj.bRoomInformationId === undefined) && obj.tempBRoomInformationId === order.tempBRoomInformationId;

                        const repaymentFlag = order.amount < 0 ? obj.amount < 0 : obj.amount >= 0;

                        return isPlanOrder && (isSameRoomUnit || isSameRoomUnitByTemp) && (isSameRoomInformation || isSameRoomByTemp) && repaymentFlag;
                    });

                    if (roomOrders.length > 0) {
                        // 存在している場合、合計加える

                        roomOrders[0].amount += order.amount;
                        // HOTELOS_DEV-544 start
                        roomOrders[0].unitPrice = roomOrders[0].amount;
                        // HOTELOS_DEV-544 end

                        // HOTELOS_DEV-586 start
                        if (order.taxation === 'NO') {
                            // 課税・非課税 :YES: 課税 NO: 非課税
                            // 非課税合計
                            roomOrders[0].nonTaxAmount = (roomOrders[0].nonTaxAmount ?? 0) + order.taxAmount;
    
                        } else {
                            // 宿泊税合計
                            roomOrders[0].stayTaxAmount = (roomOrders[0].stayTaxAmount ?? 0) + order.taxAmount;
                        }
    
                        if (order.taxRateType === 'CON') {
                            // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                            // 標準税率対象合計
                            roomOrders[0].standardAmount = (roomOrders[0].standardAmount ?? 0) + order.amount;
    
                            // 標準税率対象税金合計
                            roomOrders[0].standardTaxAmount = (roomOrders[0].standardTaxAmount ?? 0) + order.taxAmount;
    
                        } else if (order.taxRateType === 'REDUCED') {
                            // 軽減税率対象合計
                            roomOrders[0].reducedAmount = (roomOrders[0].reducedAmount ?? 0) + order.amount;
    
                            // 軽減税率対象税金合計
                            roomOrders[0].reducedTaxAmount = (roomOrders[0].reducedTaxAmount ?? 0) + order.taxAmount;
                        }
                        // HOTELOS_DEV-586 end

                        order.rowIndex = roomOrders[0].rowIndex;
                    } else {
                        // 存在していない場合、部屋情報のOrders 追加
                        const roomOrder = initialiseBOrderComponent(order.useDate) as IOrderComponent;
        
                        roomOrder.groupId = order.groupId;
                        roomOrder.companyId = order.companyId;
                        roomOrder.storeId = order.storeId;
                        roomOrder.bBasicInformationId = order.bBasicInformationId;
                        roomOrder.bRoomUnitId = order.bRoomUnitId;
                        roomOrder.bRoomInformationId = order.bRoomInformationId;
                        
                        roomOrder.useDate = order.useDate;
                        // roomOrder.itemName = StringControl.isEmpty(order.bRoomInformationPackagePlanPrintTitle) ? order.packagePlanName : order.bRoomInformationPackagePlanPrintTitle;
                        roomOrder.itemName = StringControl.isEmpty(order.bRoomInformationPackagePlanPrintTitle) ? (StringControl.isEmpty(order.itemPrintTitle) ? order.itemName : order.itemPrintTitle) : order.bRoomInformationPackagePlanPrintTitle;
                        
                        roomOrder.unitPrice = order.unitPrice;
                        // HOTELOS_DEV-544 start
                        roomOrder.quantity = 1;
                        // roomOrder.quantity = order.quantity;
                        // HOTELOS_DEV-544 end
                        
                        roomOrder.amount = order.amount;

                        // HOTELOS_DEV-586 start
                        roomOrder.taxation = order.taxation; // 課税・非課税 :YES: 課税 NO: 非課税
                        roomOrder.taxRateType = order.taxRateType; // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                        roomOrder.taxRate = order.taxRate;	// 消費税率
                        roomOrder.taxAmount = order.taxAmount;	// 消費税額
                        roomOrder.totalAmount = order.totalAmount;	// 合計金額
                        // HOTELOS_DEV-586 end
    
                        roomOrder.billId = order.billId;
                        roomOrder.billPage = order.billPage;
                        roomOrder.billStatus = order.billStatus;
                        roomOrder.billBussinessDate = order.billBussinessDate;
    
                        roomOrder.useRoomId = order.useRoomId;
                        roomOrder.useRoomNo = order.useRoomNo;
                        
                        roomOrder.packagePlanId = order.packagePlanId;
                        roomOrder.packagePlanName = order.packagePlanName;
                        roomOrder.packagePlanCode = order.packagePlanCode;

                        roomOrder.needCheck = order.needCheck;

                        roomOrder.tempUnitId = order.tempUnitId;
                        roomOrder.tempBRoomInformationId = order.tempBRoomInformationId;

                        // HOTELOS_DEV-586 start
                        roomOrder.orderType = 'room_plan';
                        if (order.taxation === 'NO') {
                            // 課税・非課税 :YES: 課税 NO: 非課税
                            // 非課税合計
                            roomOrder.nonTaxAmount = order.taxAmount;
    
                        } else {
                            // 宿泊税合計
                            roomOrder.stayTaxAmount = order.taxAmount;
                        }
    
                        if (order.taxRateType === 'CON') {
                            // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                            // 標準税率対象合計
                            roomOrder.standardAmount = order.amount;
    
                            // 標準税率対象税金合計
                            roomOrder.standardTaxAmount = order.taxAmount;
    
                        } else if (order.taxRateType === 'REDUCED') {
                            // 軽減税率対象合計
                            roomOrder.reducedAmount = order.amount;
    
                            // 軽減税率対象税金合計
                            roomOrder.reducedTaxAmount = order.taxAmount;
                        }
                        // HOTELOS_DEV-586 end

                        if (roomOrder.billStatus !== displayBillStatus) {   //DONE: 精算済
                            order.rowIndex = -1;
                            continue;
                        }

                        if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE' && this.getRoomUnit.isRepresentativeRoom === 'NO') {
                            // 室料のみ代表部屋払い && 子供部屋
                            order.rowIndex = -1;
                            continue;
                        }
    
                        if ((this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === order.billPage)
                        || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                        || (this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === 0)) {

                            roomOrder.rowIndex = rowIndex;
                            order.rowIndex = rowIndex;
                            rowIndex++;

                            orders.push(roomOrder);
                        }
                    }
                } else {
                    // プラン以外
                    if (order.billStatus !== displayBillStatus) {   //DONE: 精算済
                        order.rowIndex = -1;
                        continue;
                    }

                    if (order.packagePlanId === 0) {
                        if ((this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === order.billPage)
                        || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                        || (this.getRoomUnit.displayBillId === order.billId && this.getRoomUnit.displayBillPage === 0)) {

                            // HOTELOS_DEV-586 start
                            
                            if (order.taxation === 'NO') {
                                // 課税・非課税 :YES: 課税 NO: 非課税
                                // 非課税合計
                                order.nonTaxAmount = order.taxAmount;
        
                            } else {
                                // 宿泊税合計
                                order.stayTaxAmount = order.taxAmount;
                            }
        
                            if (order.taxRateType === 'CON') {
                                // 消費税区分 : CON: 標準 REDUCED: 軽減税率
                                // 標準税率対象合計
                                order.standardAmount = order.amount;
        
                                // 標準税率対象税金合計
                                order.standardTaxAmount = order.taxAmount;
        
                            } else if (order.taxRateType === 'REDUCED') {
                                // 軽減税率対象合計
                                order.reducedAmount = order.amount;
        
                                // 軽減税率対象税金合計
                                order.reducedTaxAmount = order.taxAmount;
                            }
                            // HOTELOS_DEV-586 end

                            order.rowIndex = rowIndex;
                            rowIndex++;
                            orders.push(order);
                        }
                    }
                }
            }

            orders.forEach((order, index) => {
                
                const detail = {
                    id: 0,
                    groupId: authStore.targetStore!.groupId,	//	グループID
                    companyId: authStore.targetStore!.companyId!,	//	会社ID
                    storeId: authStore.targetStore!.storeId!,	//	店舗ID
                
                    receiptHistoryId: 0, // 領収書印刷履歴情報ID
                    detailDate: "", // 日付
                    detailMark: "", // マーク ※(軽減税率対象)
                    detailName: "", // 明細名
                    debitAmount: 0, // ご利用金額
                    creditAmount: 0, // お支払金額
                    remarks: "", // 備考
                    flag: "",
                    billPage: 1
                } as IReceiptDetailComponent

                detail.receiptHistoryId = 0;
                detail.detailDate = order.useDate;
                detail.detailMark = ""; // マーク ※(軽減税率対象)
                if (!StringControl.isEmpty(order.itemPrintTitle)) {
                    detail.detailName = order.itemPrintTitle // 明細名
                } else {
                    detail.detailName = order.itemName // 明細名
                }
                detail.debitAmount = order.amount, // ご利用金額
                detail.remarks = order.memo; // 備考

                detail.billPage = order.billPage;

                detail.unitPrice = order.unitPrice;
                detail.quantity = order.quantity;
                detail.amount = order.amount;
            
                detail.useRoomId = order.useRoomId
                detail.useRoomNo = order.useRoomNo;

                detail.bOrderId = order.id;
                
                detail.taxation = order.taxation;
                detail.taxRateType = order.taxRateType;
                detail.taxRate = order.taxRate;
                detail.taxAmount = order.taxAmount;

                // HOTELOS_DEV-586 start
                // 非課税合計
                detail.nonTaxAmount = order.nonTaxAmount;
                // 宿泊税合計
                detail.stayTaxAmount = order.stayTaxAmount;
                // 標準税率対象合計
                detail.standardAmount = order.standardAmount;
                // 標準税率対象税金合計
                detail.standardTaxAmount = order.standardTaxAmount;
                // 軽減税率対象合計
                detail.reducedAmount = order.reducedAmount;
                // 軽減税率対象税金合計
                detail.reducedTaxAmount = order.reducedTaxAmount;
                // HOTELOS_DEV-586 end

                if (order.taxRateType === 'REDUCED') {
                    detail.detailMark = "※"; // マーク ※(軽減税率対象)
                }

                detail.flag = 'order';

                if (orderIndexs.indexOf(order.rowIndex) > -1 && displayBillStatus === order.billStatus) {
                    resultList.push(detail);
                }
            })
        
        
            // 入金の明細
            const bPayments = [] as IBPaymentComponent[];

            if (this.getRoomUnit.bPayments === undefined) {
                return [];
            }

            rowIndex = 0;

            const allPayments = [] as IBPaymentComponent[];

            this.getRoomUnit.bPayments?.forEach(payment => {
                allPayments.push(payment);
            });

            if (this.getRoomUnit.isRepresentativeRoom === 'YES') {
                if (this.bBasicInformation.payIndivisualOrBulk === 'BULK') {
                    // 全明細代表部屋払い
                    console.log('全明細代表部屋払い')

                    this.bBasicInformation.bRoomUnit.forEach(roomUnit => {
                        if (roomUnit.status !== 'NO_SHOW' && roomUnit.status !== 'CANCEL') {
                            roomUnit.bPayments?.forEach(payment => {
                                if (payment.bRoomUnitId !== this.getRoomUnit.id) {
                                    allPayments.push(payment);
                                }
                            })
                        }
                    })

                } else if (this.bBasicInformation.payIndivisualOrBulk === 'ROOM_FEE') {
                    // 室料のみ代表部屋払い
                    console.log('室料のみ代表部屋払い')

                } else {
                    // 個別精算
                    console.log('個別精算')
                }
            }

            allPayments.sort((a, b) => {
                if (a.dataType === 'add' && b.dataType === undefined) {
                    return 1
                } else if (b.dataType === 'add' && a.dataType === undefined) {
                    return -1
                } else {
                    if (a.bRoomUnitId === 0 && b.bRoomUnitId === 0) {
                        return 0;
                    } else if (a.bRoomUnitId === 0) {
                        return 1;
                    } else if (b.bRoomUnitId === 0) {
                        return -1;
                    } else if (a.bRoomUnitId > b.bRoomUnitId) {
                        return 1;
                    } else if (a.bRoomUnitId < b.bRoomUnitId) {
                        return -1;
                    } else if (!a.paymentDate && !b.paymentDate) {
                        return 0;
                    } else if (!a.paymentDate) {
                        return 1;
                    } else if (!b.paymentDate) {
                        return -1;
                    } else if (a.paymentDate.toUpperCase() > b.paymentDate.toUpperCase()) {
                        return 1;
                    } else if (a.paymentDate.toUpperCase() < b.paymentDate.toUpperCase()) {
                        return -1;
                    } else {
                        return 0;
                    }
                }
            })

            // 未清算利用明細を取得
            for (let index = 0; index < allPayments.length; index++) {
                const bPayment = allPayments[index];

                if (bPayment.billStatus !== displayBillStatus) {   //DONE: 精算済
                    bPayment.rowIndex = -1;
                    continue;
                }

                if ((this.getRoomUnit.displayBillId === bPayment.billId && this.getRoomUnit.displayBillPage === bPayment.billPage) 
                || (this.getRoomUnit.displayBillId === undefined && this.getRoomUnit.displayBillPage === undefined)
                || (this.getRoomUnit.displayBillId === bPayment.billId && this.getRoomUnit.displayBillPage === 0)) {
                    bPayment.rowIndex = rowIndex;
                    rowIndex++;
                    bPayments.push(bPayment);
                }
            }

            bPayments.forEach((bPayment, index) => {
                const detail = {
                    id: 0,
                    groupId: authStore.targetStore!.groupId,	//	グループID
                    companyId: authStore.targetStore!.companyId!,	//	会社ID
                    storeId: authStore.targetStore!.storeId!,	//	店舗ID
                
                    receiptHistoryId: 0, // 領収書印刷履歴情報ID
                    detailDate: "", // 日付
                    detailMark: "", // マーク ※(軽減税率対象)
                    detailName: "", // 明細名
                    debitAmount: 0, // ご利用金額
                    creditAmount: 0, // お支払金額
                    remarks: "", // 備考
                    flag: "",
                    billPage: 1
                } as IReceiptDetailComponent

                detail.receiptHistoryId = 0;
                detail.detailDate = bPayment.paymentDate;
                if (bPayment.accountingItemPrintTitle) {
                    detail.detailName = bPayment.accountingItemPrintTitle // 明細名
                }else {
                    detail.detailName = bPayment.accountingItemName // 明細名
                }
                detail.creditAmount = bPayment.paymentAmount; // お支払金額
                detail.remarks = bPayment.memo; // 備考

                detail.billPage = bPayment.billPage;

                detail.amount = bPayment.paymentAmount;
            
                detail.useRoomId = bPayment.useRoomId
                detail.useRoomNo = bPayment.useRoomNo;

                detail.flag = 'payment';

                if (paymentIndexs.indexOf(bPayment.rowIndex) > -1 && displayBillStatus === bPayment.billStatus) {
                    resultList.push(detail); 
                }
            })
            
            return resultList;
        },
        

        addSettlementBPaymentAccountComponent() {
            return initialiseSettlementPaymentAccountComponent() as IBillingPaymentAccountComponent;
        },

        /**
         * プランにより、部屋の室料、大人の分、order作成
         * @param roomData 部屋情報
         * @param planId プランid
         * @param plan プラン情報
         */
        addRoomOrderForAdult(roomData : IRoomInformationComponent, planId: number, plan:IPlanDropDownComponent, copyRoom?: IRoomInformationComponent | null, delOrdersFlag?: any[], ctax?: any) {
            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;
        
            // 人数取得 大人
            const adultPeople = roomData.roomPaxMaleCount + roomData.roomPaxFemaleCount;
        
            // 人数取得 子供
            const roomChildA70Count = roomData.roomChildA70Count;
            const roomChildB50Count = roomData.roomChildB50Count;
            const roomChildC30Count = roomData.roomChildC30Count;
            const roomChildDnoneCount = roomData.roomChildDnoneCount;
            const roomChildEcount = roomData.roomChildEcount;
            const childrenCount = roomChildA70Count + roomChildB50Count + roomChildC30Count + roomChildDnoneCount + roomChildEcount;
        
            // 室料の設定
            // 大人室料の計算
            const order = this.addOrderComponent(roomData.roomDate);
        
            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;
        
            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;
        
            order.packagePlanId = planId;
            order.packagePlanCode = plan?.planCd!;
            order.packagePlanName = plan?.name!;
        
            order.unitPrice = plan?.roomPrice!;
            order.quantity = adultPeople;
            order.amount = order.unitPrice * order.quantity;
            if (plan && plan.otax && plan.otax.length > 0 && this.getRoomUnit.nights > 0 && this.getRoomUnit.otaxNotCounted==='NO') {
                plan.otax.sort((a,b) => {
                    if (isSameDay(new Date(a.start), new Date(b.start))) {
                        return 0;
                    } else if (isBefore(new Date(a.start), new Date(b.start))) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                // const startDate = plan.otax[0].start;
                // const otaxFilterArr = plan.otax.filter(item => {
                //     return item.start === startDate;
                // })

                //compute order ctax
                if (ctax) {
                    const taxRate = ctax.percentage / 100;
                    const tax = order.unitPrice?(order.unitPrice / (1 + taxRate) * taxRate) : 0;
                    if (authStore.currentStoreInformation) {
                        if (authStore.currentStoreInformation!.consumptionTaxFraction === 'FLOOR') {
                            order.taxAmount = Math.floor(tax);
                        } else if (authStore.currentStoreInformation!.consumptionTaxFraction === 'CEIL') {
                            order.taxAmount = Math.ceil(tax);
                        } else {
                            order.taxAmount= Math.round(tax);
                        }
                    } else {
                        order.taxAmount = Math.round(tax);
                    }
                }

                let start = '';
                let otaxPrice = 0;
                let applyFromLimit = 0;
                plan.otax.forEach(item => { 
                    if (item.applyFromLimit !== null && item.applyFromLimit !== undefined) {
                        if ((order.unitPrice-order.taxAmount) >= item.applyFromLimit) {
                            if (applyFromLimit<item.applyFromLimit && (start==='' 
                                || (start!=='' && start === format(new Date(item.start),'yyyy/MM/dd'))) ) {
                                start = format(new Date(item.start),'yyyy/MM/dd');
                                applyFromLimit = item.applyFromLimit;
                                otaxPrice = item.taxAmount?item.taxAmount:0;
                            }
                        }
                    }
                })
                order.otaxAmount = otaxPrice * order.quantity;
            } else {
                order.otaxAmount = 0;
            }
        
            order.useDate = roomData.roomDate;
            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;
        
            order.itemId = plan?.roomAccountingItemId!;
            if (childrenCount > 0) {
                order.itemName = plan?.roomAccountingItemName! + '）' + '大人';
                order.itemNameE = plan?.roomAccountingItemNameE! + '）' + 'Adult';
            } else {
                order.itemName = plan?.roomAccountingItemName!;
                order.itemNameE = plan?.roomAccountingItemNameE!
            }
        
            order.itemPrintTitle = plan?.roomAccountingPrintTitle!;
            order.itemPrintTitleE = plan?.roomAccountingPrintTitleE!;
            order.bRoomInformationPackagePlanPrintTitle = plan?.printTitle;
        
            order.itemRecordTerm = '';
        
            order.taxation = plan?.roomAccountingTaxation!;
            order.taxRateType = plan?.roomAccountingTaxRateType!;
            order.billPage = 1;
            order.billStatus = "NOT";
        
            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }
        
            if (roomData.id === 0 || roomData.id === undefined) {
                order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
            }

            //dev-616 copy add
            if (copyRoom && copyRoom.isTotalRateChange === 'YES') {
                if (this.getRoomUnit.bOrders) {
                    this.getRoomUnit.bOrders.forEach(item=>{
                        if (item.isUnitPriceChange==='YES'&&item.itemName===order.itemName&&item.itemId===order.itemId&&item.useDate===copyRoom.roomDate&&item.packagePlanId===copyRoom.packagePlanId) {
                            order.unitPrice = item.unitPrice;
                            order.amount = order.quantity*order.unitPrice;
                            order.isUnitPriceChange = 'YES';
                        }
                    })
                }
            } else if (delOrdersFlag && delOrdersFlag.length > 0) {
                delOrdersFlag.forEach(item=>{
                    if (item.isUnitPriceChange==='YES'&&item.itemName===order.itemName&&item.itemId===order.itemId&&item.useDate===roomData.roomDate&&item.packagePlanId===roomData.packagePlanId) {
                        order.unitPrice = item.unitPrice;
                        order.amount = order.quantity*order.unitPrice;
                        order.isUnitPriceChange = 'YES';
                    }
                })
            }
            order.tempBArrangementId = '';
            order.orderIsRoomFee = true;
            return order;
        },

        /**
         * プランにより、部屋の室料、子供の分、order作成
         * @param roomData 
         * @param planId 
         * @param plan 
         * @param childFeeType 
         * @param percentage 
         * @param staticPrice 
         * @param childCount 
         * @param childName 
         * @param childNameE 
         */
        addRoomOrderForChild(
            roomData : IRoomInformationComponent, planId: number, plan: IPlanDropDownComponent,
            childFeeType: string, percentage: number, staticPrice: number, childCount: number,
            childName: string, childNameE: string, copyRoom?: IRoomInformationComponent | null, delOrdersFlag?: any[],ctax?: any){
            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;
        
            // 子供室料の計算
            // 子供料金マスタのデータを基に、子供A～Eの料金をそれぞれ計算する。
            // 子供料金マスタ.大人１名当たり料金のN%の場合
            //     上記、「大人室料で取得した price * 子供料金マスタ.大人１名当たり料金のN%」
            // 子供料金マスタ.固定の場合
            //     「子供料金マスタ.固定」の値
            //     固定料金の場合 （子供Aの場合）
            //           子供料金 = child_fees.child_a70_static * 子供A人数
        
            const order = this.addOrderComponent(roomData.roomDate);
        
            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;
        
            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;
        
            order.packagePlanId = planId;
            order.packagePlanCode = plan?.planCd!;
            order.packagePlanName = plan?.name!;
        
            if (childFeeType === 'PERCENTAGE') {
                order.unitPrice = plan?.roomPrice! * percentage / 100;
            } else {
                order.unitPrice = staticPrice;
            }
            order.quantity = childCount;
        
            order.amount = order.unitPrice * order.quantity;
        
            order.useDate = roomData.roomDate;
            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;
            if (plan && plan.otax && plan.otax.length > 0 && this.getRoomUnit.nights > 0 && this.getRoomUnit.otaxNotCounted==='NO') {
                plan.otax.sort((a,b) => {
                    if (isSameDay(new Date(a.start), new Date(b.start))) {
                        return 0;
                    } else if (isBefore(new Date(a.start), new Date(b.start))) {
                        return 1;
                    } else {
                        return -1;
                    }
                })
                // const startDate = plan.otax[0].start;
                // const otaxFilterArr = plan.otax.filter(item => {
                //     return item.start === startDate;
                // })

                //compute order ctax
                if (ctax) {
                    const taxRate = ctax.percentage / 100;
                    const tax = order.unitPrice?(order.unitPrice / (1 + taxRate) * taxRate) : 0;
                    if (authStore.currentStoreInformation) {
                        if (authStore.currentStoreInformation!.consumptionTaxFraction === 'FLOOR') {
                            order.taxAmount = Math.floor(tax);
                        } else if (authStore.currentStoreInformation!.consumptionTaxFraction === 'CEIL') {
                            order.taxAmount = Math.ceil(tax);
                        } else {
                            order.taxAmount= Math.round(tax);
                        }
                    } else {
                        order.taxAmount = Math.round(tax);
                    }
                }

                let start = '';
                let otaxPrice = 0;
                let applyFromLimit = 0;
                plan.otax.forEach(item => { 
                    if (item.applyFromLimit !== null && item.applyFromLimit !== undefined) {
                        if ((order.unitPrice-order.taxAmount) >= item.applyFromLimit) {
                            if (applyFromLimit<item.applyFromLimit && (start==='' 
                                || (start!=='' && start === format(new Date(item.start),'yyyy/MM/dd'))) ) {
                                start = format(new Date(item.start),'yyyy/MM/dd');
                                applyFromLimit = item.applyFromLimit;
                                otaxPrice = item.taxAmount?item.taxAmount:0;
                            }
                        }
                    }
                })
                order.otaxAmount = otaxPrice * order.quantity;
            } else {
                order.otaxAmount = 0;
            }
        
            order.itemId = plan?.roomAccountingItemId!;
            order.itemName = plan?.roomAccountingItemName! + '）' + childName;
            order.itemNameE = plan?.roomAccountingItemNameE! + '）' + childNameE;
        
            order.itemPrintTitle = plan?.roomAccountingPrintTitle!;
            order.itemPrintTitleE = plan?.roomAccountingPrintTitleE!;
            order.bRoomInformationPackagePlanPrintTitle = plan?.printTitle;
        
            order.taxation = plan?.roomAccountingTaxation!;
            order.taxRateType = plan?.roomAccountingTaxRateType!;
            order.billStatus = "NOT";
            order.billPage = 1;
        
            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }
        
            if (roomData.id === 0 || roomData.id === undefined) {
                order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
            }

            //dev-616 copy add
            if (copyRoom && copyRoom.isTotalRateChange === 'YES') {
                if (this.getRoomUnit.bOrders) {
                    this.getRoomUnit.bOrders.forEach(item=>{
                        if (item.isUnitPriceChange==='YES'&&item.itemName===order.itemName&&item.itemId===order.itemId&&item.useDate===copyRoom.roomDate&&item.packagePlanId===copyRoom.packagePlanId) {
                            order.unitPrice = item.unitPrice;
                            order.amount = order.quantity*order.unitPrice;
                            order.isUnitPriceChange = 'YES';
                        }
                    })
                }
            } else if (delOrdersFlag && delOrdersFlag.length > 0) {
                delOrdersFlag.forEach(item=>{
                    if (item.isUnitPriceChange==='YES'&&item.itemName===order.itemName&&item.itemId===order.itemId&&item.useDate===roomData.roomDate&&item.packagePlanId===roomData.packagePlanId) {
                        order.unitPrice = item.unitPrice;
                        order.amount = order.quantity*order.unitPrice;
                        order.isUnitPriceChange = 'YES';
                    }
                })
            }
            order.tempBArrangementId = '';
            order.orderIsRoomFee = true;
            return order;
        },

        /**
         * プランにより、部屋の手配、大人の分、order作成
         * @param roomData 部屋情報
         * @param planId プランid
         * @param plan プラン情報
         * @param planItem プラン手配情報
         * @param quantity 数量
         */
        addRoomItemOrderForAdult(roomData : IRoomInformationComponent, planId: number, plan:IPlanDropDownComponent, planItem: IPlanItemDropDownComponent, quantity: number) {
            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;

            // 室料の設定
            const order = this.addOrderComponent(roomData.roomDate);

            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;

            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;

            order.packagePlanId = planId;
            order.packagePlanCode = plan?.planCd!;
            order.packagePlanName = plan?.name!;

            order.useDate = roomData.roomDate;
            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;

            order.itemId = planItem?.itemId!;
            order.itemName = planItem?.itemName!;
            order.itemNameE = planItem?.itemNameE!;
            order.itemNameCd = planItem?.itemNameCd!;

            order.itemPrintTitle = planItem?.itemPrintTitle!;
            order.itemPrintTitleE = planItem?.itemPrintTitleE!;

            order.itemRecordTerm = planItem?.recordTerm;

            order.unitPrice = planItem?.unitPrice!;
            order.taxation = planItem?.taxation!;
            order.taxRateType = planItem?.taxRateType!;
            order.taxRate = planItem?.taxRate!;

            order.taxAmount = 10;
            order.billPage = 1;
            order.billStatus = "NOT";
            order.quantity = quantity;

            order.amount = order.unitPrice * order.quantity;

            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }

            if (roomData.id === 0 || roomData.id === undefined) {
                order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
            }

            order.tempBArrangementId = '';

            return order;
        },

        /**
         * プランにより、部屋の室料、子供の分order作成
         * @param roomData 
         * @param planId 
         * @param plan 
         * @param planItem 
         * @param childFeeType 
         * @param percentage 
         * @param staticPrice 
         * @param childCount 
         * @param childName 
         * @param childNameE 
         */
        addRoomItemOrderForChild(
            roomData : IRoomInformationComponent, planId: number, plan: IPlanDropDownComponent, planItem: IPlanItemDropDownComponent,
            childFeeType: string, percentage: number, staticPrice: number, childCount: number,
            childName: string, childNameE: string) {
            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;
        
            // 子供室料の計算
            // 子供料金マスタのデータを基に、子供A～Eの料金をそれぞれ計算する。
            // 子供料金マスタ.大人１名当たり料金のN%の場合
            //     上記、「大人室料で取得した price * 子供料金マスタ.大人１名当たり料金のN%」
            // 子供料金マスタ.固定の場合
            //     「子供料金マスタ.固定」の値
            //     固定料金の場合 （子供Aの場合）
            //           子供料金 = child_fees.child_a70_static * 子供A人数
        
            const order = this.addOrderComponent(roomData.roomDate);
        
            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;
        
            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;
        
            order.packagePlanId = planId;
            order.packagePlanCode = plan?.planCd!;
            order.packagePlanName = plan?.name!;
        
            if (childFeeType === 'PERCENTAGE') {
                order.unitPrice = plan?.roomPrice! * percentage / 100;
            } else {
                order.unitPrice = staticPrice;
            }
            order.quantity = childCount;
        
            order.amount = order.unitPrice * order.quantity;
        
            order.useDate = roomData.roomDate;
            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;
        
            order.itemId = planItem?.itemId!;
            order.itemName = planItem?.itemName! + '）' + childName;
            order.itemNameE = planItem?.itemNameE! + '）' + childNameE;
            order.itemNameCd = planItem?.itemNameCd!;
        
            order.itemPrintTitle = planItem?.itemPrintTitle!;
            order.itemPrintTitleE = planItem?.itemPrintTitleE!;
        
            order.itemRecordTerm = planItem?.recordTerm;
        
            order.taxation = planItem?.taxation!;
            order.taxRateType = planItem?.taxRateType!;
            order.taxRate = planItem?.taxRate!;
            order.taxAmount = 10;
            order.billStatus = "NOT";
            order.billPage = 1;
        
            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }
        
            if (roomData.id === 0 || roomData.id === undefined) {
                order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
            }

            order.tempBArrangementId = '';
        
            return order;
        },

        /**
         * プランに含まれる手配を追加
         * @param roomData 部屋情報
         * @param planId プランID
         * @param planArrangement プラン手配情報
         */
        addBArrangementComponentWithPlan(roomData : IRoomInformationComponent, planId: number, planArrangement:IPlanArrangementDropDownComponent) {

            console.log('addBArrangementComponentWithPlan')

            const arrangement = initializeBArrangementComponent() as IBArrangementComponent;

            arrangement.bBasicInformationId = this.bBasicInformation.id ?? 0;

            arrangement.bRoomUnitId = this.getRoomUnit.id ?? 0;
            arrangement.bRoomInformationId = roomData.id ?? 0;

            arrangement.useDate = roomData.roomDate;

            arrangement.planArrangementId = planArrangement.id;
            arrangement.planArrangementName = planArrangement.name;
            arrangement.planArrangementAccountingName = planArrangement.accountingItemName;

            arrangement.planArrangementStockManagementType = planArrangement.stockManagementType;
            arrangement.planArrangementTicketManageType = planArrangement.ticketManageType;
            arrangement.planArrangementMasterArrangementId = planArrangement.arrangementId;
            

            arrangement.arrangementId = undefined;
            
            arrangement.unitPrice = planArrangement.accountingItemPrice;
            arrangement.quantityType = planArrangement.quantityType;
            
            let quantity = 0;
            if (planArrangement.quantityType === 'ROOM') {
                arrangement.quantityRoom = planArrangement.quantityRoom;
                arrangement.quantityPerson = undefined;

                quantity = arrangement.quantityRoom!;
            } else if (planArrangement.quantityType === 'PERSON') {
                // quantity_person:  "MALE,FEMALE,A,B,C,D,E"

                const personArray = planArrangement.quantityPerson!.split(',');

                let personCount = 0;

                personArray.forEach((str: string) => {
                    switch (str) {
                        case 'MALE':
                            personCount += roomData.roomPaxMaleCount;
                            break;
                        case 'FEMALE':
                            personCount += roomData.roomPaxFemaleCount;
                            break;
                        case 'A':
                            personCount += roomData.roomChildA70Count;
                            break;
                        case 'B':
                            personCount += roomData.roomChildB50Count;
                            break;
                        case 'C':
                            personCount += roomData.roomChildC30Count;
                            break;
                        case 'D':
                            personCount += roomData.roomChildDnoneCount;
                            break;
                        case 'E':
                            personCount += roomData.roomChildEcount;
                            break; 
                        default:
                            break;
                    }
                })

                arrangement.quantityPerson = personCount;
                arrangement.quantityRoom   = undefined;
                quantity = arrangement.quantityPerson;
            }

            arrangement.roomPaxMaleCount    = roomData.roomPaxMaleCount;
            arrangement.roomPaxFemaleCount  = roomData.roomPaxFemaleCount;
            arrangement.roomChildA70Count   = roomData.roomChildA70Count;
            arrangement.roomChildB50Count   = roomData.roomChildB50Count;
            arrangement.roomChildC30Count   = roomData.roomChildC30Count;
            arrangement.roomChildDnoneCount = roomData.roomChildDnoneCount;
            arrangement.roomChildEcount     = roomData.roomChildEcount;
            arrangement.roomChildFcount     = 0;
            arrangement.roomChildOtherCount = 0;
            arrangement.totalPrice = quantity * arrangement.unitPrice;

            if (planArrangement.arrangementTicketType === 'TICKET') {
                arrangement.status = 'TICKET_BLANK';
            } else {
                arrangement.status = 'BLANK';
            }
            
            arrangement.tempBArrangementId = 'plan_arrangement_' + roomData.roomDate + '_' + arrangement.planArrangementId;
            
            if (roomData.id === 0 || roomData.id === undefined) {
                arrangement.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
            }

            const checkInDate = this.bBasicInformation.bRoomUnit[this.roomIdx].checkInDate;

            const roomDateStr = roomData.roomDate;
            const roomDate = new Date(roomDateStr);

            // FIRST_DAY: 初日のみ THE_DAY: 宿泊日 NEXT_DAY: 宿泊日（翌日）
            if (planArrangement?.termType === 'THE_DAY'  
            || planArrangement?.termType === 'NEXT_DAY' 
            || (planArrangement?.termType === 'FIRST_DAY' && isSameDay(roomDate, checkInDate))) {

                if (planArrangement?.termType === 'NEXT_DAY' ) {
                    arrangement.useDate = DateControl.formatDateToStringForFormat1(addDays(roomDate, 1));
                }


                // 「初日のみ」の場合、チェックイン日のみ設定

                this.getRoomUnit.bArrangements.push(arrangement);

                if (planArrangement.accountingItemPrice > 0) {

                    const order = this.addPlanArrangementOrder(roomData, planId, planArrangement);
                    
                    order.tempBArrangementId = arrangement.tempBArrangementId;

                    if (planArrangement?.termType === 'NEXT_DAY' ) {
                        order.useDate = arrangement.useDate;
                    }

                    //order.dataType = 'add';

                    this.getRoomUnit.bOrders!.push(order);
                }
            }

            return arrangement;
        },

        /**
         * プラン有料手配の売上作成
         * @param roomData 
         * @param planId 
         * @param planArrangement 
         */
        addPlanArrangementOrder(roomData : IRoomInformationComponent, planId: number, planArrangement:IPlanArrangementDropDownComponent) {

            // planArrangement:
            // { accounting_item_id
            //   accounting_item_name
            //   accounting_item_name_e
            //   accounting_item_payment_type
            //   accounting_item_price
            //   accounting_item_print_title
            //   accounting_item_tax_rate_type
            //   accounting_item_taxation
            //   arrangement_id
            //   arrangement_list_type
            //   arrangement_ticket_type
            //   cleaning_list_type
            //   display_orders }


            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;
        
            // 人数取得 大人
            const adultPeople = roomData.roomPaxMaleCount + roomData.roomPaxFemaleCount;
        
            // 人数取得 子供
            const roomChildA70Count = roomData.roomChildA70Count;
            const roomChildB50Count = roomData.roomChildB50Count;
            const roomChildC30Count = roomData.roomChildC30Count;
            const roomChildDnoneCount = roomData.roomChildDnoneCount;
            const roomChildEcount = roomData.roomChildEcount;
            const childrenCount = roomChildA70Count + roomChildB50Count + roomChildC30Count + roomChildDnoneCount + roomChildEcount;
        
            // 室料の設定
            // 大人室料の計算
            const order = this.addOrderComponent(roomData.roomDate);
        
            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;
            order.bBasicInformationId = this.bBasicInformation.id!
        
            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;
            order.useDate = roomData.roomDate;


            if (planArrangement.quantityType === 'ROOM') {
                order.quantity = planArrangement.quantityRoom ?? 1;
            } else if (planArrangement.quantityType === 'PERSON') {
                // quantity_person:  "MALE,FEMALE,A,B,C,D,E"

                const personArray = planArrangement.quantityPerson!.split(',');

                let personCount = 0;

                personArray.forEach((str: string) => {
                    switch (str) {
                        case 'MALE':
                            personCount += roomData.roomPaxMaleCount;
                            break;
                        case 'FEMALE':
                            personCount += roomData.roomPaxFemaleCount;
                            break;
                        case 'A':
                            personCount += roomChildA70Count;
                            break;
                        case 'B':
                            personCount += roomChildB50Count;
                            break;
                        case 'C':
                            personCount += roomChildC30Count;
                            break;
                        case 'D':
                            personCount += roomChildDnoneCount;
                            break;
                        case 'E':
                            personCount += roomChildEcount;
                            break; 
                        default:
                            break;
                    }
                })


                order.quantity = personCount;
            }

            order.unitPrice = planArrangement.accountingItemPrice;

            order.amount = order.quantity * order.unitPrice;

            order.itemId = planArrangement.accountingItemId
            order.itemName = planArrangement.name;
            order.itemPrintTitle = planArrangement.accountingItemPrintTitle;
            order.itemPrintTitleE = planArrangement.accountingItemPrintTitleE;
            order.taxation = planArrangement.accountingItemTaxation;
            order.taxRateType = planArrangement.accountingItemTaxRateType;


            order.billStatus = 'NOT';
            //order.dataType = 'add';
            

            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;

            order.perRoomPaxCount     = adultPeople + childrenCount;
            order.roomPaxMaleCount    = roomData.roomPaxMaleCount;
            order.roomPaxFemaleCount  = roomData.roomPaxFemaleCount;
            order.roomChildA70Count   = roomChildA70Count;
            order.roomChildB50Count   = roomChildB50Count;
            order.roomChildC30Count   = roomChildC30Count;
            order.roomChildDnoneCount = roomChildDnoneCount;
            order.roomChildEcount     = roomChildEcount;

            order.bArrangementId = 0;
        
            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }

            order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
        
            return order;
        },

        /**
         * 手入力手配を追加
         * @param roomData 部屋情報
         * @param planId プランID
         * @param planArrangement プラン手配情報
         */
        updateBArrangementWithArrangement(bArrangement : IBArrangementComponent, roomData : IRoomInformationComponent, masterArrangement:any, rowIndex:number) {
            
            bArrangement.bRoomInformationId = roomData.id ?? 0;
            bArrangement.planArrangementId = undefined;
            bArrangement.arrangementId = masterArrangement.id;
            
            bArrangement.unitPrice = masterArrangement.accountingItemPrice;

            if (StringControl.isEmpty(bArrangement.quantityType) || (bArrangement.quantityRoom === undefined && bArrangement.quantityPerson === undefined)) {
                bArrangement.quantityType = masterArrangement.quantityType;
            }

            bArrangement.arrangementStockManagementType = masterArrangement.stockManagementType;
            bArrangement.arrangementTicketManageType = masterArrangement.ticketManageType;
            bArrangement.arrangementMasterArrangementId = masterArrangement.id;

            let quantity = 0;
            if (bArrangement.quantityType === 'ROOM') {

                if (bArrangement.quantityRoom === undefined) {
                    bArrangement.quantityRoom = masterArrangement.quantityRoom;
                }
                
                bArrangement.quantityPerson = undefined;

                quantity = bArrangement.quantityRoom!;
            } else if (bArrangement.quantityType === 'PERSON') {
                // quantity_person:  "MALE,FEMALE,A,B,C,D,E"

                if (bArrangement.quantityPerson === undefined || bArrangement.quantityPerson === null) {

                    let personCount = 0;

                    if (masterArrangement.quantityType === 'PERSON') {
                        const personArray = masterArrangement.quantityPerson.split(',');
                    
                        personArray.forEach((str: string) => {
                            switch (str) {
                                case 'MALE':
                                    personCount += roomData.roomPaxMaleCount;
                                    break;
                                case 'FEMALE':
                                    personCount += roomData.roomPaxFemaleCount;
                                    break;
                                case 'A':
                                    personCount += roomData.roomChildA70Count;
                                    break;
                                case 'B':
                                    personCount += roomData.roomChildB50Count;
                                    break;
                                case 'C':
                                    personCount += roomData.roomChildC30Count;
                                    break;
                                case 'D':
                                    personCount += roomData.roomChildDnoneCount;
                                    break;
                                case 'E':
                                    personCount += roomData.roomChildEcount;
                                    break; 
                                default:
                                    break;
                            }
                        });
                    } else {
                        personCount = roomData!.roomPaxMaleCount + roomData!.roomPaxFemaleCount 
                        + roomData!.roomChildA70Count + roomData!.roomChildB50Count + roomData!.roomChildC30Count 
                        + roomData!.roomChildDnoneCount + roomData!.roomChildEcount;
                    }

                    bArrangement.quantityPerson = personCount;
                    bArrangement.quantityRoom   = undefined;

                    bArrangement.roomPaxMaleCount    = roomData.roomPaxMaleCount;
                    bArrangement.roomPaxFemaleCount  = roomData.roomPaxFemaleCount;
                    bArrangement.roomChildA70Count   = roomData.roomChildA70Count;
                    bArrangement.roomChildB50Count   = roomData.roomChildB50Count;
                    bArrangement.roomChildC30Count   = roomData.roomChildC30Count;
                    bArrangement.roomChildDnoneCount = roomData.roomChildDnoneCount;
                    bArrangement.roomChildEcount     = roomData.roomChildEcount;
                    bArrangement.roomChildFcount     = 0;
                    bArrangement.roomChildOtherCount = 0;
                    
                } else {
                    const personCount = bArrangement!.roomPaxMaleCount + bArrangement!.roomPaxFemaleCount 
                    + bArrangement!.roomChildA70Count + bArrangement!.roomChildB50Count + bArrangement!.roomChildC30Count + bArrangement!.roomChildDnoneCount + bArrangement!.roomChildEcount;
            
                    bArrangement.quantityPerson = personCount;
                    

                    bArrangement.roomChildFcount     = 0;
                    bArrangement.roomChildOtherCount = 0;
                }

                bArrangement.quantityRoom = undefined;
                quantity = bArrangement.quantityPerson;
            }

            bArrangement.totalPrice = quantity * bArrangement.unitPrice;

            if (StringControl.isEmpty(bArrangement.status)) {
                if (masterArrangement.arrangementTicketType === 'TICKET') {
                    bArrangement.status = 'TICKET_BLANK';
                } else {
                    bArrangement.status = 'BLANK';
                }
            }
            
            if (bArrangement.id === undefined || bArrangement.id === 0) {
                // 新規の場合
                bArrangement.tempBArrangementId = 'arrangement_' + roomData.roomDate + '_' + rowIndex;
            }

            bArrangement.tempBRoomInformationId = 'room_info_' + roomData.roomDate;

            if (masterArrangement.accountingItemPrice > 0) {

                const order = this.getRoomUnit.bOrders?.find(item => {

                    if (bArrangement.id === undefined || bArrangement.id === 0) {
                        return item.tempBArrangementId === bArrangement.tempBArrangementId;
                    } else {
                        return item.bArrangementId === bArrangement.id;
                    }
                });

                if (order === undefined) {
                    // 新規の場合
                    const aOrder = this.addArrangementOrder(roomData, masterArrangement);
                    aOrder.useDate = bArrangement.useDate;
                    aOrder.tempBArrangementId = bArrangement.tempBArrangementId;
                    aOrder.bArrangementId = bArrangement.id ?? 0;

                    //aOrder.dataType = 'add';

                    this.getRoomUnit.bOrders!.push(aOrder);
                } else {
                    // 更新の場合
                    const updateOrder = this.updateArrangementOrder(order,roomData, masterArrangement);

                    updateOrder.useDate = bArrangement.useDate;
                }            
            }

            return bArrangement;
        },

        /**
         * 手入力手配を追加
         * @param roomData 部屋情報
         * @param planId プランID
         * @param planArrangement プラン手配情報
         */
        updateBArrangementWithArrangementWithNotRoomDate(bArrangement : IBArrangementComponent, masterArrangement:any) {
            
            bArrangement.planArrangementId = undefined;
            bArrangement.arrangementId = masterArrangement.id;
            
            bArrangement.unitPrice = masterArrangement.accountingItemPrice;
            bArrangement.quantityType = masterArrangement.quantityType;
            
            if (StringControl.isEmpty(bArrangement.status)) {
                if (masterArrangement.arrangementTicketType === 'TICKET') {
                    bArrangement.status = 'TICKET_BLANK';
                } else {
                    bArrangement.status = 'BLANK';
                }
            }

            return bArrangement;
        },

        /**
         * 手入力有料手配の売上作成
         * @param roomData 
         * @param planId 
         * @param planArrangement 
         */
        addArrangementOrder(roomData : IRoomInformationComponent,  masterArrangement:any) {

            const authStore = useAuthStore();
            const { groupId, companyId, storeId } = authStore.targetStore!;
        
            // 人数取得 大人
            const adultPeople = roomData.roomPaxMaleCount + roomData.roomPaxFemaleCount;
        
            // 人数取得 子供
            const roomChildA70Count = roomData.roomChildA70Count;
            const roomChildB50Count = roomData.roomChildB50Count;
            const roomChildC30Count = roomData.roomChildC30Count;
            const roomChildDnoneCount = roomData.roomChildDnoneCount;
            const roomChildEcount = roomData.roomChildEcount;
            const childrenCount = roomChildA70Count + roomChildB50Count + roomChildC30Count + roomChildDnoneCount + roomChildEcount;
        
            // 室料の設定
            // 大人室料の計算
            const order = this.addOrderComponent(roomData.roomDate);
        
            order.groupId = groupId;
            order.companyId = companyId!;
            order.storeId = storeId!;
            order.bBasicInformationId = this.bBasicInformation.id!
        
            order.bRoomUnitId = this.getRoomUnit.id ?? 0;
            order.bRoomInformationId = roomData.id ?? 0;
            order.useDate = roomData.roomDate;


            if (masterArrangement.quantityType === 'ROOM') {
                order.quantity = masterArrangement.quantityRoom ?? 1;
            } else if (masterArrangement.quantityType === 'PERSON') {
                // quantity_person:  "MALE,FEMALE,A,B,C,D,E"

                const personArray = masterArrangement.quantityPerson.split(',');

                let personCount = 0;

                personArray.forEach((str: string) => {
                    switch (str) {
                        case 'MALE':
                            personCount += roomData.roomPaxMaleCount;
                            break;
                        case 'FEMALE':
                            personCount += roomData.roomPaxFemaleCount;
                            break;
                        case 'A':
                            personCount += roomChildA70Count;
                            break;
                        case 'B':
                            personCount += roomChildB50Count;
                            break;
                        case 'C':
                            personCount += roomChildC30Count;
                            break;
                        case 'D':
                            personCount += roomChildDnoneCount;
                            break;
                        case 'E':
                            personCount += roomChildEcount;
                            break; 
                        default:
                            break;
                    }
                })


                order.quantity = personCount;
            }

            order.unitPrice = masterArrangement.accountingItemPrice;

            order.amount = order.quantity * order.unitPrice;

            order.itemId = masterArrangement.accountingItemId
            order.itemName = masterArrangement.name;
            order.itemPrintTitle = masterArrangement.accountingItemPrintTitle;
            order.itemPrintTitleE = masterArrangement.accountingItemPrintTitleE;
            order.taxation = masterArrangement.accountingItemTaxation;
            order.taxRateType = masterArrangement.accountingItemTaxRateType;


            order.billStatus = 'NOT';
            //order.dataType = 'add';
            

            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;

            order.perRoomPaxCount     = adultPeople + childrenCount;
            order.roomPaxMaleCount    = roomData.roomPaxMaleCount;
            order.roomPaxFemaleCount  = roomData.roomPaxFemaleCount;
            order.roomChildA70Count   = roomChildA70Count;
            order.roomChildB50Count   = roomChildB50Count;
            order.roomChildC30Count   = roomChildC30Count;
            order.roomChildDnoneCount = roomChildDnoneCount;
            order.roomChildEcount     = roomChildEcount;

            order.bArrangementId = 0;

            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }

            order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
        
            return order;
        },

        updateArrangementOrder(order:IOrderComponent,  roomData : IRoomInformationComponent,  masterArrangement:any) {
        
            // 人数取得 大人
            const adultPeople = roomData.roomPaxMaleCount + roomData.roomPaxFemaleCount;
        
            // 人数取得 子供
            const roomChildA70Count = roomData.roomChildA70Count;
            const roomChildB50Count = roomData.roomChildB50Count;
            const roomChildC30Count = roomData.roomChildC30Count;
            const roomChildDnoneCount = roomData.roomChildDnoneCount;
            const roomChildEcount = roomData.roomChildEcount;
            const childrenCount = roomChildA70Count + roomChildB50Count + roomChildC30Count + roomChildDnoneCount + roomChildEcount;
        

            order.bRoomInformationId = roomData.id ?? 0;

            // 室料の設定
            // 大人室料の計算
            order.useDate = roomData.roomDate;

            if (masterArrangement.quantityType === 'ROOM') {
                order.quantity = masterArrangement.quantityRoom ?? 1;
            } else if (masterArrangement.quantityType === 'PERSON') {
                // quantity_person:  "MALE,FEMALE,A,B,C,D,E"

                const personArray = masterArrangement.quantityPerson.split(',');

                let personCount = 0;

                personArray.forEach((str: string) => {
                    switch (str) {
                        case 'MALE':
                            personCount += roomData.roomPaxMaleCount;
                            break;
                        case 'FEMALE':
                            personCount += roomData.roomPaxFemaleCount;
                            break;
                        case 'A':
                            personCount += roomChildA70Count;
                            break;
                        case 'B':
                            personCount += roomChildB50Count;
                            break;
                        case 'C':
                            personCount += roomChildC30Count;
                            break;
                        case 'D':
                            personCount += roomChildDnoneCount;
                            break;
                        case 'E':
                            personCount += roomChildEcount;
                            break; 
                        default:
                            break;
                    }
                })


                order.quantity = personCount;
            }

            order.unitPrice = masterArrangement.accountingItemPrice;

            order.amount = order.quantity * order.unitPrice;

            order.itemId = masterArrangement.accountingItemId
            order.itemName = masterArrangement.name;
            order.itemPrintTitle = masterArrangement.accountingItemPrintTitle;
            order.itemPrintTitleE = masterArrangement.accountingItemPrintTitleE;
            order.taxation = masterArrangement.accountingItemTaxation;
            order.taxRateType = masterArrangement.accountingItemTaxRateType;

            order.useRoomId = roomData.assignedRoomId;
            order.useRoomNo = roomData.assignedRoomNumber;

            order.perRoomPaxCount     = adultPeople + childrenCount;
            order.roomPaxMaleCount    = roomData.roomPaxMaleCount;
            order.roomPaxFemaleCount  = roomData.roomPaxFemaleCount;
            order.roomChildA70Count   = roomChildA70Count;
            order.roomChildB50Count   = roomChildB50Count;
            order.roomChildC30Count   = roomChildC30Count;
            order.roomChildDnoneCount = roomChildDnoneCount;
            order.roomChildEcount     = roomChildEcount;

            if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                order.tempUnitId = 'room_unit_' + this.roomIdx
            }

            order.tempBRoomInformationId = 'room_info_' + roomData.roomDate;
        
            return order;
        },


        /**
         * CI/CO 変更する前に、チェックを行う
         * @param targetCheckInDate 変更先CI
         * @param targetCheckOutDate 変更先CO
         * @param oldCheckInDate 変更元CI
         * @param oldCheckOutDate 変更元CO
         */
        checkRoomUnitInfo(targetCheckInDate: Date, targetCheckOutDate: Date, oldCheckInDate: Date, oldCheckOutDate: Date) {
            console.log('checkRoomUnitInfo')

            if (isSameDay(targetCheckInDate, oldCheckInDate) && isSameDay(targetCheckOutDate, oldCheckOutDate)) {
                return undefined
            }

            const authStore = useAuthStore();


            // -1: 全てリセット 
            // 0 : 正常 
            // 1 : 手配済みの手配あり、強制解除？ 
            // 2 : 手配削除不可 
            // 3 : 翌日手配あり、減泊不可
            // 4 : CO change payment delete
            let resultFlag = 0;

            // 到着日部屋情報を追加
            const addArrivalDayRoomInformation = false;
            // 到着日部屋情報に、プラン手配、室料を追加
            let addArrivalDayArrangementAndOrder = false; 
        
            const oldUseDateArray = [] as string[];
            const targetUseDateArray = [] as string[];
            const targetInOutDateArray = [] as string[];
        
            this.getRoomUnit.bRoomInformation.forEach(roomInfo => {
                oldUseDateArray.push(roomInfo.roomDate);
            });
        
            // 変更先の泊数
            const oldNights: number = DateControl.differenceInDaysWithStartOfDay(oldCheckOutDate, oldCheckInDate);
            const nights: number = DateControl.differenceInDaysWithStartOfDay(targetCheckOutDate, targetCheckInDate);

            if (nights == 0) {
                // 0泊の場合
                const useDateStr = DateControl.formatDateToStringForFormat1(targetCheckInDate);
                targetUseDateArray.push(useDateStr);
                targetInOutDateArray.push(useDateStr);

            } else {
                for (let i = 0; i < nights; i++) {
                
                    const useDate = addDays(targetCheckInDate , i);
                    const useDateStr = DateControl.formatDateToStringForFormat1(useDate);
    
                    targetUseDateArray.push(useDateStr);
                    targetInOutDateArray.push(useDateStr);

                    if (i === nights - 1) {
                        const coDateStr = DateControl.formatDateToStringForFormat1(targetCheckOutDate);
                        targetInOutDateArray.push(coDateStr);
                    }
                }
            }
            
            const keepUseDateArray: string[] = targetUseDateArray.filter((x) => oldUseDateArray.includes(x));

            // 削除先の利用日
            const preDeleteUseDateArray: string[] = oldUseDateArray.filter((x) => !targetUseDateArray.includes(x));

            // 追加先の利用日
            const newRoomInformationDateArray: string[] = targetUseDateArray.filter((x) => !keepUseDateArray.includes(x));
            

            console.log('変更先CI = ', targetCheckInDate, ' CO = ', targetCheckOutDate);
            console.log("変更先の利用日 ", targetUseDateArray);
            console.log("変更先のCI~CO ", targetInOutDateArray)

            console.log('追加先CI = ', targetCheckInDate, ' CO = ', targetCheckOutDate);
            console.log("追加先の利用日 ", newRoomInformationDateArray);

            console.log('元CI = ', oldCheckInDate, ' CO = ', oldCheckOutDate);
            console.log("削除先の利用日 ", preDeleteUseDateArray);
            

            // diff > 0  : 後ろ
            // diff == 0 : 元日付に一致
            // diff < 0  : 前


            if (preDeleteUseDateArray.length === oldUseDateArray.length) {
                // 元の宿泊期間 不要
                resultFlag = -1;
            }

            // 削除先の部屋情報のIndex
            const preDeleteRoomInformationIndexs = [] as any[];
            const preDeleteRoomInformationIdArray = [] as any[];
            const preDeleleRoomInformationTempIdArray = [] as any[];

            // 旧手配と手配の売上を削除
            const deleteArrangementIndexs = [] as any[];
            // 手配の売上明細ID
            const deleteArrangementOrdersIdArray = [] as any[];
            // 新規プラン手配の売上明細ID
            const deleteArrangementOrdersTempIdArray = [] as any[];

            // 削除先の売上明細のIndex
            const deleteOrdersIndexs = [] as any[];
            const deleteOrdersIdArray = [] as any[];

            // 返金用 売上明細ID
            const repaymentOfOrderIndexs = [] as any[];
            const repaymentOfOrderIdArray = [] as any[];

            // 追加する部屋情報
            const addRoomInformations = [];

            
            // 削除先の利用日により、チェックを行なって、削除先のデータを取得
            for (let i = 0; i < preDeleteUseDateArray.length; i++) {
                const deleteUseDate = preDeleteUseDateArray[i];

                // 部屋情報を削除
                this.getRoomUnit.bRoomInformation.forEach((item, index) => {

                    if (item.roomDate === deleteUseDate) {
                        preDeleteRoomInformationIndexs.push(index);

                        if (!StringControl.numberTypeIsEmpty(item.id)) {
                            preDeleteRoomInformationIdArray.push(item.id!);
                        } else {
                            const tempId = 'room_info_' + item.roomDate;
                            preDeleleRoomInformationTempIdArray.push(tempId);
                        }
                    }
                });

                // 手配をチェック
                // 手配削除不可の場合、Errorを返す
                this.getRoomUnit.bArrangements?.forEach((item, index) => {
                    
                    if (preDeleteRoomInformationIdArray.includes(item.bRoomInformationId) || 
                    preDeleleRoomInformationTempIdArray.includes(item.tempBRoomInformationId)) {

                        if (!StringControl.numberTypeIsEmpty(item.planArrangementId) && 
                        StringControl.numberTypeIsEmpty(item.arrangementId)) { // プラン手配
                            // プランに含まれる手配、未発券または未手配の場合、削除可
                            if (!deleteArrangementIndexs.includes(index)) {
                                deleteArrangementIndexs.push(index);

                                StringControl.numberTypeIsEmpty(item.id)? deleteArrangementOrdersTempIdArray.push(item.tempBArrangementId) : deleteArrangementOrdersIdArray.push(item.id);
    
                                if (!(item.status == 'BLANK' || item.status == 'TICKET_BLANK')) {
                                    // 利用済み、一部利用、手配完了
                                    resultFlag = resultFlag <= 1 ? 1 : resultFlag;
                                }
                            }

                        } else if (StringControl.numberTypeIsEmpty(item.planArrangementId) && 
                        !StringControl.numberTypeIsEmpty(item.arrangementId) &&
                        !targetInOutDateArray.includes(item.useDate)
                        ) {// 手入力手配
                            // 手入力手配削除不可
                            resultFlag = 2;
                        } else {
                            console.log('手配保留')
                        }
                    } else {
                        // 元の初日のみ削除
                        if (isSameDay(oldCheckInDate, new Date(item.useDate)) && !isSameDay(targetCheckInDate, new Date(item.useDate)) && item.planArrangementTermType === 'FIRST_DAY') {
                            if (!deleteArrangementIndexs.includes(index)) {
                                // プランに含まれる手配、未発券または未手配の場合、削除可
                                deleteArrangementIndexs.push(index);
        
                                StringControl.numberTypeIsEmpty(item.id)? deleteArrangementOrdersTempIdArray.push(item.tempBArrangementId) : deleteArrangementOrdersIdArray.push(item.id);
        
                                if (!(item.status == 'BLANK' || item.status == 'TICKET_BLANK')) {
                                    // 利用済み、一部利用、手配完了
                                    resultFlag = resultFlag <= 1 ? 1 : resultFlag;
                                }
                            }
                        }
                    }
                });

                // 売上をチェック
                this.getRoomUnit.bOrders?.forEach((item, index) => {

                    // 手配の売上
                    const arrangementOfOrder = this.getRoomUnit.bArrangements.find(a => a.id === (item.bArrangementId || a.tempBArrangementId === item.tempBArrangementId));

                    if (preDeleteRoomInformationIdArray.includes(item.bRoomInformationId) || 
                    preDeleleRoomInformationTempIdArray.includes(item.tempBRoomInformationId) || 
                    (arrangementOfOrder !== undefined && isSameDay(oldCheckInDate, new Date(arrangementOfOrder.useDate)) && arrangementOfOrder.planArrangementTermType === 'FIRST_DAY') ||
                    (isSameDay(oldCheckInDate, new Date(item.useDate)) && !isSameDay(targetCheckInDate, new Date(item.useDate)) && item.itemRecordTerm === 'ARRIVAL')) {

                        if (!StringControl.numberTypeIsEmpty(item.bRoomInformationId)) {

                            // 削除先部屋情報に一致する売上明細
                            if (!StringControl.isEmpty(item.billBussinessDate)) {

                                const orderDate = new Date(item.billBussinessDate!);
                                const bussinessDate = new Date(authStore.bussinessDate!);
                                if (isSameDay(orderDate, bussinessDate) || item.billStatus !== 'DONE') {

                                    if (StringControl.numberTypeIsEmpty(item.bArrangementId) && StringControl.isEmpty(item.tempBArrangementId)) {
                                        // 手配以外
                                        if (!deleteOrdersIndexs.includes(index)) {
                                            // 精算取消できる、削除可 || 未精算
                                            deleteOrdersIndexs.push(index);

                                            if (!StringControl.numberTypeIsEmpty(item.id)) {
                                                deleteOrdersIdArray.push(item.id);
                                            }
                                        }
                                    } else {
                                        // 手配の売上
                                        const arrangementOfOrder = this.getRoomUnit.bArrangements.find(a => a.id === (item.bArrangementId || a.tempBArrangementId === item.tempBArrangementId));
                                        if (arrangementOfOrder !== undefined) {

                                            if (StringControl.numberTypeIsEmpty(arrangementOfOrder.planArrangementId) && 
                                            !StringControl.numberTypeIsEmpty(arrangementOfOrder.arrangementId) &&
                                            targetInOutDateArray.includes(arrangementOfOrder.useDate)
                                            ) {// 手入力手配
                                                // 手入力手配削除不可
                                                console.log('手配保留')
                                            } else {
                                                // 未利用、未手配の手配売上
                                                if ((arrangementOfOrder.status == 'BLANK' || arrangementOfOrder.status == 'TICKET_BLANK') &&
                                                (deleteArrangementOrdersTempIdArray.includes(item.tempBArrangementId) || deleteArrangementOrdersIdArray.includes(item.bArrangementId))) {
                                                    if (!deleteOrdersIndexs.includes(index)) {
                                                        deleteOrdersIndexs.push(index);

                                                        if (!StringControl.numberTypeIsEmpty(item.id)) {
                                                            deleteOrdersIdArray.push(item.id);
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                } else {
                                    // 精算取消できない、返金追加要
                                    if (!repaymentOfOrderIndexs.includes(index)) {
                                        repaymentOfOrderIndexs.push(index);
                                        if (!StringControl.numberTypeIsEmpty(item.id)) {
                                            repaymentOfOrderIdArray.push(item.id)
                                        }
                                    }
                                }
                            } else {
                                // 異常データ、削除可 || 未精算
                                // 手配の売上
                                const arrangementOfOrder = this.getRoomUnit.bArrangements.find(a => a.id === (item.bArrangementId || a.tempBArrangementId === item.tempBArrangementId));
                                if (arrangementOfOrder !== undefined) {

                                    if (StringControl.numberTypeIsEmpty(arrangementOfOrder.planArrangementId) && 
                                    !StringControl.numberTypeIsEmpty(arrangementOfOrder.arrangementId) &&
                                    targetInOutDateArray.includes(arrangementOfOrder.useDate)
                                    ) {// 手入力手配
                                        // 手入力手配削除不可
                                        console.log('手配保留')
                                    } else {
                                        // 未利用、未手配の手配売上
                                        if ((arrangementOfOrder.status == 'BLANK' || arrangementOfOrder.status == 'TICKET_BLANK') &&
                                        (deleteArrangementOrdersTempIdArray.includes(item.tempBArrangementId) || deleteArrangementOrdersIdArray.includes(item.bArrangementId))) {
                                            if (!deleteOrdersIndexs.includes(index)) {
                                                deleteOrdersIndexs.push(index);

                                                if (!StringControl.numberTypeIsEmpty(item.id)) {
                                                    deleteOrdersIdArray.push(item.id);
                                                }
                                            }
                                        }
                                    }
                                } else {
                                    if (!deleteOrdersIndexs.includes(index)) {
                                        deleteOrdersIndexs.push(index);

                                        if (!StringControl.numberTypeIsEmpty(item.id)) {
                                            deleteOrdersIdArray.push(item.id);
                                        }
                                    }
                                }
                            }

                        } else {
                            // 新規の室料
                            if (preDeleleRoomInformationTempIdArray.includes(item.tempBRoomInformationId) || 
                            (isSameDay(oldCheckInDate, new Date(item.useDate)) && !isSameDay(targetCheckInDate, new Date(item.useDate)) && item.itemRecordTerm === 'ARRIVAL')) {
                                if (!deleteOrdersIndexs.includes(index)) {
                                    deleteOrdersIndexs.push(index);
                                    if (!StringControl.numberTypeIsEmpty(item.id)) {
                                        deleteOrdersIdArray.push(item.id);
                                    }
                                }
                            }
                        }
                    }
                });
            }


            for (let i = 0; i < newRoomInformationDateArray.length; i++) {
                const addUseDate = newRoomInformationDateArray[i];

                const roomInformation = this.addBRoomInformation() as IRoomInformationComponent;
                roomInformation.bBasicInformationId = this.bBasicInformation.id ?? 0;
                roomInformation.roomDate = addUseDate;
    
                addRoomInformations.push(roomInformation);
            }

            
            for (let i = 0; i < keepUseDateArray.length; i++) {
                const useDateStr = keepUseDateArray[i];
                const useDate = startOfDay(new Date(useDateStr));

                if (isSameDay(useDate, targetCheckInDate) && !isSameDay(targetCheckInDate, oldCheckInDate)) {
                    addArrivalDayArrangementAndOrder = true;
                }
            }

            if (nights === 0 && oldNights === 1) {
                // 1泊から0泊に変更する場合、削除先の部屋情報なくて、チェックを行う
                // 手配をチェック
                // 手配削除不可の場合、Errorを返す
                this.getRoomUnit.bArrangements?.forEach((item, index) => {

                    if (!StringControl.numberTypeIsEmpty(item.planArrangementId) && 
                    StringControl.numberTypeIsEmpty(item.arrangementId) && !targetInOutDateArray.includes(item.useDate)) { // プラン手配
                        
                        if (resultFlag != 2) {
                            resultFlag = 3;
                        }

                    } else if (StringControl.numberTypeIsEmpty(item.planArrangementId) && 
                    !StringControl.numberTypeIsEmpty(item.arrangementId) &&
                    !targetInOutDateArray.includes(item.useDate)
                    ) {// 手入力手配
                        // 手入力手配削除不可
                        resultFlag = 2;
                    } else {
                        console.log('手配保留')
                    }
                });
            }
                            
            // 翌日手配をチェック
            this.getRoomUnit.bArrangements?.forEach((item, index) => {

                const belongRoomInfo = this.getRoomUnit.bRoomInformation.find(r => {
                    return 'room_info_'+r.roomDate === item.tempBRoomInformationId || r.id === item.bRoomInformationId;
                })

                if (belongRoomInfo !== undefined) {
                    const tomorrow = DateControl.formatDateToStringForFormat1(addDays(new Date(belongRoomInfo.roomDate), 1));
                    if (deleteArrangementIndexs.includes(index) && !targetInOutDateArray.includes(item.useDate) && tomorrow === item.useDate) {
                        if (StringControl.numberTypeIsEmpty(item.planArrangementId) && !StringControl.numberTypeIsEmpty(item.arrangementId)) {
                            // 手入力手配あり
                            resultFlag = 2;
                        } else if (!StringControl.numberTypeIsEmpty(item.planArrangementId) && StringControl.numberTypeIsEmpty(item.arrangementId) && 
                        (item.status !== 'BLANK' && item.status !== 'TICKET_BLANK')) {
                            if (resultFlag !== 2) {
                                resultFlag = 3;
                            }
                        }
                    }
                }
            });

            // payment CO delete
            if (this.getRoomUnit.bPayments && this.getRoomUnit.bPayments.length > 0) {
                if (this.getRoomUnit.status==='BOOK' || this.getRoomUnit.status==='STAY') {
                    const COPayment = this.getRoomUnit.bPayments.find(item => {
                        return item.paymentDate===format(oldCheckOutDate,'yyyy/MM/dd') &&
                            format(targetCheckOutDate,'yyyy/MM/dd')<format(oldCheckOutDate,'yyyy/MM/dd');
                    })
                    if (COPayment) resultFlag = 4;
                }
            }

            return {
                resultFlag: resultFlag,
                addArrivalDayRoomInformation: addArrivalDayRoomInformation,
                addArrivalDayArrangementAndOrder: addArrivalDayArrangementAndOrder,
                preDeleteObj: {
                    preDeleteRoomInformationIndexs: preDeleteRoomInformationIndexs,
                    deleteArrangementIndexs: deleteArrangementIndexs,
                    deleteOrdersIndexs: deleteOrdersIndexs
                },
                preAddObj: {
                    addRoomInformations: addRoomInformations,
                    repaymentOfOrderIndexs: repaymentOfOrderIndexs,
                }

            }
        },

        /**
         * CI/CO 変更する前に、チェック行なった結果で更新
         * @param targetObj checkRoomUnitInfoメソッドの結果
         */
        updateRoomUnitInfoWithCheckResult(targetObj: any) {
            const authStore = useAuthStore();

            const tempOrders = [];

            if (targetObj.preDeleteObj) {

                // HOTELOS_DEV-628
                targetObj.preDeleteObj.preDeleteRoomInformationIndexs?.sort((a : number, b : number) => {
                    return a - b;
                });
                // HOTELOS_DEV-628

                for (let i = targetObj.preDeleteObj.preDeleteRoomInformationIndexs.length - 1; i >= 0; i--) {
                    const element = targetObj.preDeleteObj.preDeleteRoomInformationIndexs[i];
                    const deleteRoomInfo = this.getRoomUnit.bRoomInformation.splice(element, 1)[0];

                    if (this.getRoomUnit.bRoomInformationForDel === undefined) {
                        this.getRoomUnit.bRoomInformationForDel = [];
                    }

                    if (deleteRoomInfo !== undefined) {
                        if (deleteRoomInfo.id !== 0) {

                            this.getRoomUnit.bRoomInformationForDel!.push(deleteRoomInfo.id!);
                        }
                    }
                }

                this.getRoomUnit.bRoomInformationForDel?.sort((a,b) => {
                    return a - b;
                });

                // HOTELOS_DEV-628
                targetObj.preDeleteObj.deleteArrangementIndexs?.sort((a : number, b : number) => {
                    return a - b;
                });
                // HOTELOS_DEV-628
                
                for (let i = targetObj.preDeleteObj.deleteArrangementIndexs.length - 1; i >= 0; i--) {
                    const element = targetObj.preDeleteObj.deleteArrangementIndexs[i];
                    const deleteBArrangement = this.getRoomUnit.bArrangements.splice(element, 1)[0];

                    if (this.getRoomUnit.bArrangementForDel === undefined) {
                        this.getRoomUnit.bArrangementForDel = [];
                    }

                    if (deleteBArrangement !== undefined) {
                        if (deleteBArrangement.id !== 0) {
                            this.getRoomUnit.bArrangementForDel!.push(deleteBArrangement.id!);
                        }
                    }
                }

                this.getRoomUnit.bArrangementForDel?.sort((a,b) => {
                    return a - b;
                });

                
                for (let index = 0; index < targetObj.preAddObj.repaymentOfOrderIndexs.length; index++) {
                    const element = targetObj.preAddObj.repaymentOfOrderIndexs[index];

                    const repaymentOrder = this.getRoomUnit.bOrders![element];

                    let order = this.addOrderComponent(authStore.bussinessDate!);

                    order = JSON.parse(JSON.stringify(repaymentOrder));

                    order.id = 0;
                    // order.bRoomInformationId = 0;
                    order.useDate = repaymentOrder.useDate;
                    order.unitPrice = repaymentOrder.unitPrice * -1;
                    order.quantity = repaymentOrder.quantity;
                    order.amount = repaymentOrder.amount * -1;

                    order.packagePlanName = repaymentOrder.bRoomInformationPackagePlanPrintTitle;

                    order.billStatus = 'NOT';

                    order.dataType = 'add';

                    if (this.getRoomUnit.id === 0 || this.getRoomUnit.id === undefined) {
                        order.tempUnitId = 'room_unit_' + this.roomIdx
                    }

                    order.tempBRoomInformationId = 'room_info_' + order.useDate;

                    tempOrders.push(order);
                }

                // HOTELOS_DEV-628
                targetObj.preDeleteObj.deleteOrdersIndexs?.sort((a : number, b : number) => {
                    return a - b;
                });
                // HOTELOS_DEV-628

                for (let i = targetObj.preDeleteObj.deleteOrdersIndexs.length - 1; i >= 0; i--) {
                    const element = targetObj.preDeleteObj.deleteOrdersIndexs[i];
                    const deleteBOrder = this.getRoomUnit.bOrders!.splice(element, 1)[0];

                    if (this.getRoomUnit.bOrdersForDel === undefined) {
                        this.getRoomUnit.bOrdersForDel = [];
                    }

                    if (deleteBOrder !== undefined) {
                        if (deleteBOrder.id !== 0) {
                            this.getRoomUnit.bOrdersForDel!.push(deleteBOrder.id!);
                        }
                    }
                }

                this.getRoomUnit.bOrdersForDel?.sort((a,b) => {
                    return a - b;
                });
            }

            if (targetObj.preAddObj) {
                if (targetObj.preAddObj.addRoomInformations !== undefined && targetObj.preAddObj.addRoomInformations.length > 0) {
                    for (let i = 0; i < targetObj.preAddObj.addRoomInformations.length; i++) {
                        const o = targetObj.preAddObj.addRoomInformations[i];
                        this.getRoomUnit.bRoomInformation.push(o);
                    }

                    this.getRoomUnit.bRoomInformation.sort((a,b) => {
                        if (a.roomDate.toUpperCase() > b.roomDate.toUpperCase()) {
                            return 1;
                        } else if (a.roomDate.toUpperCase() < b.roomDate.toUpperCase()) {
                            return -1;
                        } else {
                            return 0;
                        }
                    })
                }
                

                for (let i = 0; i < tempOrders.length; i++) {
                    const o = tempOrders[i];
                    this.getRoomUnit.bOrders?.push(o);
                }
            }

            console.log('updateRoomUnitInfoWithCheckResult');
            console.log(this.getRoomUnit);
            console.log('updateRoomUnitInfoWithCheckResult');
        }
    }
})

function initializeComponent(): IRegistrationComponent {
    const authStore = useAuthStore();
    
    // HOTELOS_DEV-435
    // 新規登録時における初期表示について、チェックインは業務日付、チェックアウト日は業務日付＋１日とする
    const businessDate = authStore.bussinessDate!;

    const checkInDate = new Date(businessDate);

    const checkOutDate = addDays(checkInDate, 1);

    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        status: '',	//	予約ステータス
        bookingNo: '',	//	予約番号
        checkInDate: checkInDate,	//	チェックイン日
        checkInTime: undefined,	//	チェックイン時間
        checkOutDate: checkOutDate,	//	チェックアウト日
        checkOutTime: undefined,	//	チェックアウト時間
        // checkInDate: format(new Date(), 'yyyy/MM/dd'),	//	チェックイン日
        // checkInTime: "18:00:00",	//	チェックイン時間
        // checkOutDate: format(addDays(new Date(), 1), 'yyyy/MM/dd'),	//	チェックアウト日
        // checkOutTime: "10:00:00",	//	チェックアウト時間
        nights: 1,	//	宿泊日数
        totalRoomCount: 1,	//	利用客室合計数
        grandTotalPaxCount: 1,	//	お客様総合計人数
        totalPaxMaleCount: 0,	//	大人人員(男性)合計
        totalPaxFemaleCount: 0,	//	大人人員(女性)合計
        totalChildA70Count: 0,	//	子供A人数合計
        totalChildB50Count: 0,	//	子供B人数合計
        totalChildC30Count: 0,	//	子供C人数合計
        totalChildDnoneCount: 0,	//	子供D人数合計

        guestOrGroupNameSingleByte: '',	//	団体名または代表者氏名(半角)
        guestOrGroupNameDoubleByte: '',	//	団体名または代表者氏名よみがな(全角)
        guestOrGroupNameKanjiName: '',	//	団体名または代表者氏名 漢字
        guestOrGroupNamePhoneNumber: '',	//	団体名または代表者電話番号
        guestOrGroupNamePhoneNumber2: '',	//	団体名または代表者電話番号２
        groupNameSingleByte: '',
        memo: '',
        bRoomUnit: [
            initializeRoomUnitComponent() as IRoomUnitComponent
        ] as IRoomUnitComponent[],

        payIndivisualOrBulk: 'INDIVIDUAL', // 個別精算・一括清算

    } as IRegistrationComponent
}

// 部屋単位情報
function initializeRoomUnitComponent(): IRoomUnitComponent {
    const authStore = useAuthStore();
    // HOTELOS_DEV-435
    // 新規登録時における初期表示について、チェックインは業務日付、チェックアウト日は業務日付＋１日とする
    const businessDate = authStore.bussinessDate!;

    const checkInDate = new Date(businessDate);

    const checkOutDate = addDays(checkInDate, 1);

    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        status: 'BOOK',	//	予約ステータス
        checkInDate: checkInDate,	//	チェックイン日
        checkInTime: undefined,	//	チェックイン時間
        checkOutDate: checkOutDate,	//	チェックアウト日
        checkOutTime: undefined,	//	チェックアウト時間
        // checkInDate: format(new Date(), 'yyyy/MM/dd'),	//	チェックイン日
        // checkInTime: "18:00",	//	チェックイン時間
        // checkOutDate: format(addDays(new Date(), 1), 'yyyy/MM/dd'),	//	チェックアウト日
        // checkOutTime: "10:00",	//	チェックアウト時間

        arrivalDatetime: undefined, // 実チェックイン日時
        departureDatetime: undefined, // 実チェックアウト日時

        nights: 1,	//	宿泊日数
        memo: '',	//	予約メモ情報
        bRoomInformation: [
            initializeRoomInfoComponent() as IRoomInformationComponent
        ] as IRoomInformationComponent[],

        bRoomInformationForDel: [] as number[],
        
        bGuestInformation: [
            initializeGuestInfoComponent() as IGuestInformationComponent
        ] as IGuestInformationComponent[],

        bOrders: [] as IOrderComponent[],    // 利用明細

        bFreeMemos: [] as IBFreememoComponent[],

        isRepresentativeRoom: "NO",

        needCheck: true,

        bArrangements: [] as IBArrangementComponent[],

        bArrangementForDel: [] as number[],
        otaxNotCounted: "NO",

        nightsChanged: 0,
        // dev-72

        historyType: 'OPERATION',
        receipt: false
        
    } as IRoomUnitComponent;
}

// 部屋情報
function initializeRoomInfoComponent(): IRoomInformationComponent {
    const authStore = useAuthStore();
    const variableStore = useTemairazuStore();

    const businessDate = authStore.bussinessDate!;

    const checkInDate = new Date(businessDate);

    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1,	//	部屋毎のSEQ
        roomDate: CConvertor.formatJpDateTime(checkInDate, false, false),	//	利用年月日
        roomTypeCode: '',	//	部屋タイプコード
        roomTypeName: '',	//	部屋タイプ名
        roomCategory: '',	//	部屋カテゴリー
        viewType: '',	//	部屋眺望
        smokingOrNonSmoking: '',	//	喫煙/禁煙
        perRoomPaxCount: 1,	//	1室利用人数
        roomPaxMaleCount: 0,	//	大人人員(男性)
        roomPaxFemaleCount: 0,	//	大人人員(女性)
        roomChildA70Count: 0,	//	子供A人数
        roomChildB50Count: 0,	//	子供B人数
        roomChildC30Count: 0,	//	子供C人数
        roomChildDnoneCount: 0,	//	子供D人数
        roomChildEcount: 0,	//	子供E人数
        roomChildFcount: 0,	//	子供F人数
        roomChildOtherCount: 0,	//	子供その他人数
        roomChildA70Name: '',	//	子供A名称
        roomChildB50Name: '',	//	子供B名称
        roomChildC30Name: '',	//	子供C名称
        roomChildDnoneName: '',	//	子供D名称
        roomChildEname: '',	//	子供E名称
        roomChildFname: '',	//	子供F名称
        roomChildOtherName: '',	//	子供その他名称
        facilities: '',	//	その他設備
        assignedRoomNumber: '',	//	部屋割り後客室名/番号
        roomSpecialRequest: '',	//	客室に対する特別リクエスト

        travelAgencyId: 0,	// 旅行会社マスタID
        travelAgencyBookingNumber: variableStore.travelAgencyBookingNumber ?? '',	//	旅行会社予約(管理)番号
        travelAgencyBookingDate: '',	//	旅行会社予約受付日
        travelAgencyBookingTime: '',	//	旅行会社受付時間
        travelAgencyReportNumber: '',	//	通知番号
        purposeId: 0,	//	利用目的ID
        salesRouteId: 0,

        packageType: '',	//	企画商品区分
        packagePlanName: '',	//	企画(パッケージ)名
        packagePlanCode: '',	//	企画(パッケージ)コード
        packagePlanContent: '',	//	企画(パッケージ)内容

        packagePlanPrintTitle: '', // 企画(パッケージ)領収書印字名
        packagePlanPrintTitleE: '', // 企画(パッケージ)領収書印字名 英語

        billPage: 1,
        billStatus: 'NOT',

        perPaxRate: 0,	//	大人単価（一人料金)
        perChildA70Rate: 0,	//	子供A単価（一人料金)
        perChildB50Rate: 0,	//	子供B単価（一人料金)
        perChildC30Rate: 0,	//	子供C単価（一人料金)
        perChildDrate: 0,	//	子供D単価（一人料金)
        perChildErate: 0,	//	子供E単価（一人料金)
        perChildFrate: 0,	//	子供F単価（一人料金)
        perChildOtherRate: 0,	//	子供その他単価（一人料金)
        roomChildA70RateName: '',	//	子供A名称
        roomChildB50RateName: '',	//	子供B名称
        roomChildC30RateName: '',	//	子供C名称
        roomChildDrateName: '',	//	子供D名称
        roomChildErateName: '',	//	子供E名称
        roomChildFrateName: '',	//	子供F名称
        roomChildOtherRateName: '',	//	子供その他名称
        totalPerRoomRate: 0,	//	1室あたり宿泊料金合計
        totalPerRoomConsumptionTax: 0,	//	1室あたり宿泊料金消費税合計
        totalRoomHotSpringTax: 0,	//	1室あたり宿泊料金入湯税合計
        totalPerRoomServiceFee: 0,	//	1室あたり宿泊料金サービス料合計

        fareMethodId: 0,	//	精算方法

        bInformationOptions: [
            initializeInformationOptionComponent() as IInformationOptionComponent
         ] as IInformationOptionComponent[],

        // bOrders: [] as IOrderComponent[],    // 利用明細

        // bOrders: [
        //     initialiseBOrderComponent(CConvertor.formatJpDateTime(new Date(), false, false)) as IOrderComponent
        //  ] as IOrderComponent[],    // 利用明細
        isTotalRateChange: 'NO',

    } as IRoomInformationComponent
}

// 部屋オプション情報
function initializeInformationOptionComponent(): IInformationOptionComponent {
    const authStore = useAuthStore();
    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1,	//	部屋毎のSEQ
        bRoomInformationId: 0,	//	部屋情報ID
        optionNo: 0,	//	部屋情報明細オプション番号
        optionDate: new Date(),	//	オプション利用日
        optionName: '',	//	オプション名
        optionCode: '',	//	オプションコード
        optionCategory: 0,	//	オプション区分
        optionPerCategory: '',	//	オプション人員区分
        optionCount: 0,	//	オプション数
        optionRate: 0,	//	オプション料金
        totalOptionRate: 0,	//	オプション料金合計
    } as IInformationOptionComponent

}

// 利用明細
function initialiseBOrderComponent(date: string): IOrderComponent {
    const authStore = useAuthStore();
    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID
        bRoomInformationId: 0,	//	部屋情報ID
        useDate: "", //CConvertor.formatJpDateTime(new Date(), false, false),	// 利用年月日
        useRoomId: 0,	// 利用部屋
        useRoomNo: "",	// 利用部屋名
        itemId:	0,	// 商品コード
        itemName: "0",	// 商品名
        itemNameE: "",	// 商品名（英語）
        itemNameCd: "", // 商品コード
        itemBookkeepingCd: "",
        unitPrice: 0,	// 単価
        quantity: 0,	// 数量
        amount: 0,	// 合計金額
        taxation: '',   // 課税・非課税
        taxRateType: '',  // 消費税区分
        taxRate: 0,	// 消費税率
        taxAmount: 0,	// 消費税額
        totalAmount: 0,
        status: "",	// ステータス 熟考中
        billId: 0,
        billNo: "",	// 伝票番号
        billPage: 1,	// ビルページ
        billRoomId: 0,	// 伝票 部屋ID
        billRoomNo: "",	// 伝票 部屋NO
        billStatus: "NOT",	// 精算ステータス DONE: 精算済、NOT: 未清算
        packagePlanId: 0,	// 企画(パッケージ)id
        packagePlanName: "",	// 企画(パッケージ)名
        packagePlanCode: "",	// 企画(パッケージ)コード
        itemPrintTitle: "", // 会計科目領収書印字名
        itemPrintTitleE: "", // 会計科目領収書印字名（英語）

        memo: "",

        needCheck: true,

        rowIndex: 0,

        bRoomInformationPackagePlanPrintTitle: "",

        // バッチ 統計で必要だった
        perPaxRate: 0,	// 大人単価（一人料金)
        perChildA70Rate: 0,	// 子供A単価（一人料金)
        perChildB50Rate: 0,	// 子供B単価（一人料金)
        perChildC30Rate: 0,	// 子供C単価（一人料金)
        perChildDrate: 0,	// 子供D単価（一人料金)
        perChildErate: 0,	// 子供E単価（一人料金)
        perChildFrate: 0,	// 子供F単価（一人料金)
        perChildOtherRate: 0,	// 子供その他単価（一人料金)
        perRoomPaxCount: 0,	// 1室利用人数
        roomPaxMaleCount: 0,	// 大人人員(男性)
        roomPaxFemaleCount: 0,	// 大人人員(女性)
        roomChildA70Count: 0,	// 子供A人数
        roomChildB50Count: 0,	// 子供B人数
        roomChildC30Count: 0,	// 子供C人数
        roomChildDnoneCount: 0,	// 子供D人数
        roomChildEcount: 0,		// 子供E人数
        roomChildFcount: 0,		// 子供F人数
        roomChildOtherCount: 0,	// 子供その他人数

        bArrangementId: 0,
        tempBArrangementId: '',
        tempBRoomInformationId: '',

        orderIsRoomFee: false,
        isUnitPriceChange: 'NO'
    }
}
// 予約入金情報
function initialiseBPaymentComponent(): IBPaymentComponent{
    const authStore = useAuthStore();
    return {
        id: 0,
        groupId: authStore.targetStore!.groupId,	//	グループID
        companyId: authStore.targetStore!.companyId!,	//	会社ID
        storeId: authStore.targetStore!.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 0,	//	部屋単位情報ID

        paymentDate: "",
        bussinessDate: "",
        salesDate: "",
        status: "",

        billId: 0,	//	精算情報ID
        billPage: 1,	//	ビルページ
        billRoomId: 0,	// 伝票 部屋ID
        billRoomNo: "",	// 伝票 部屋NO
        billStatus: "NOT",	// 精算ステータス DONE: 精算済、NOT: 未清算

        // paymentMethodId: 0,	// 金種ID
        // paymentMethodName: "",	// 金種名
        // paymentAccountId: 0,	// 入金科目ID
        // paymentAccountName: "",	// 入金科目名
        // paymentManageReceivable: "", // 売掛金管理
        // paymentBookkeepingCd: "", // 会計科目コード

        paymentAmount: 0,	// 支払金額
        paymentBRoomUnitId: 0,  // 支払部屋単位ID

        accountingItemId: 0,  // 会計科目ID
        accountingItemName: "",  // 会計科目名
        accountingItemNameE: "",  // 会計科目名（英語）
        accountingItemPrintTitle: "",  // 会計科目領収書印字名
        accountingItemPrintTitleE: "",  // 会計科目領収書印字名（英語）
        accountingItemInterfaceCd01: "", // 外部連携コード1
        accountingItemInterfaceCd02: "",  // 外部連携コード2
        accountingItemPaymentType: "",

        // paymentRoomId: 0,
        // paymentRoomName: "",
        // accountBussinessDate: "",
        // accountStatus: "",
        // exportedStatus: "",
        billingAddressId: 0,
        billingAddressName: "",

        useRoomId: 0,	// 利用部屋
        useRoomNo: "",	// 利用部屋名

        memo: "",

        needCheck: true,

        rowIndex: 0
    }
}

// お客様情報
function initializeGuestInfoComponent(): IGuestInformationComponent {
    const authStore = useAuthStore();
    return {
        id:0,
        groupId: authStore.targetStore?.groupId!,	//	グループID
        companyId: authStore.targetStore?.companyId!,	//	会社ID
        storeId: authStore.targetStore?.storeId!,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1,	//	部屋毎のSEQ
        bRoomInformationId: 0,	//	部屋情報ID
        guestNameSingleByte: '',	//	お客様氏名半角カタカナ英字(姓名)
        guestSurName: '',	//	お客様氏名全角ふりがな(姓)
        guestKanjiName: '',	//	お客様氏名全角漢字(姓名)
        guestGender: '',	//	お客様男女区分
        guestAge: 0,	//	お客様年齢
        guestDateOfBirth: new Date(),	//	お客様生年月日
        guestPhoneNumber: '',	//	お客様電話番号
        guestPhoneNumberType: undefined,	//	お客様電話番号区分
        guestEmergencyPhoneNumber: '',	//	お客様緊急連絡先
        guestEmergencyPhoneNumberType: undefined,	//	お客様緊急連絡先区分
        guestEmail: '',	//	お客様Eメール
        guestCountry: undefined,	//	お客様国籍
        guestStateProvidence: undefined,	//	お客様住所都道府県
        guestCityName: '',	//	お客様住所区市名
        guestAddressLine: '',	//	お客様住所町村名
        guestStreetNumber: '',	//	お客様住所番地名
        guestPostalCode: '',	//	お客様住所郵便番号
        guestAddress1: '',	//	お客様住所1
        guestAddress2: '',	//	お客様住所2
        companyNameSingleByte: '',	//	勤務先半角カタカナ英字(姓名)
        companyKanjiName: '',	//	勤務先名全角漢字(姓名)
        companyPhoneNumber: '',	//	勤務先電話番号
        companyPhoneNumberType: undefined,	//	勤務先電話番号 電話区分ID
        companyEmail: '',	//	勤務先Eメール
        companyStateProvidence: undefined,	//	勤務先住所都道府県
        companyPostalCode: '',	//	勤務先住所郵便番号
        companyAddress1: '',	//	勤務先住所１
        companyAddress2: '',	//	勤務先住所２
        rankId: 0,	//	ランクID
        customerId: 0,	//	顧客ID
        memberId: 0,	//	メンバーID
        expressId: 0,	//	エクスプレスメンバーID
        type: '',
        issuingCountry: '',
        passportNo: '',
        surname: '',
        givenName: '',
        nationality: '',
        dateOfBirth: '',
        sex: '',
        registeredDomicile: '',
        dateOfIssue: '',
        dateOfExpiry: '',
        filePath: '',
        currentSelect: false,
    } as IGuestInformationComponent

}

function initializeBArrangementComponent() {
    const authStore = useAuthStore();
    return {
        id: 0,
        groupId: authStore.targetStore?.groupId!,
        companyId: authStore.targetStore?.companyId!,
        storeId: authStore.targetStore?.storeId!,
        bBasicInformationId: 0,
        bRoomUnitId: 0,
        bRoomInformationId: 0,
        useDate: '',
        planArrangementId: undefined,
        arrangementId: undefined,
        unitPrice: 0,
        quantityType: '',
        quantityRoom: undefined,
        quantityPerson: undefined,
        roomPaxMaleCount: 0,
        roomPaxFemaleCount: 0,
        roomChildA70Count: 0,
        roomChildB50Count: 0,
        roomChildC30Count: 0,
        roomChildDnoneCount: 0,
        roomChildEcount: 0,
        roomChildFcount: 0,
        roomChildOtherCount: 0,
        totalPrice: 0,
        status: undefined,
        tempBArrangementId: '',
        tempBRoomInformationId: ''
    } as IBArrangementComponent;
}
















/** 以下、API用？？？ */

// 基本宿泊情報
function initializeBasicInfo(): IBBasicInformation {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        status: '予約',	//	予約ステータス
        bookingNo: '',	//	予約番号
        checkInDate: new Date(),	//	チェックイン日
        checkInTime: new Date(),	//	チェックイン時間
        checkOutDate: new Date(),	//	チェックアウト日
        checkOutTime: new Date(),	//	チェックアウト時間
        nights: 1,	//	宿泊日数
        totalRoomCount: 1,	//	利用客室合計数
        grandTotalPaxCount: 1,	//	お客様総合計人数
        totalPaxMaleCount: 0,	//	大人人員(男性)合計
        totalPaxFemaleCount: 0,	//	大人人員(女性)合計
        totalChildA70Count: 0,	//	子供A人数合計
        totalChildB50Count: 0,	//	子供B人数合計
        totalChildC30Count: 0,	//	子供C人数合計
        totalChildDnoneCount: 0,	//	子供D人数合計
        typeOfGroup: '',	//	参加形態
        packageType: '',	//	企画商品区分
        packagePlanName: '',	//	企画(パッケージ)名
        packagePlanCode: '',	//	企画(パッケージ)コード
        packagePlanContent: '',	//	企画(パッケージ)内容
        mealCondition: '',	//	食事条件
        specificMealCondition: '',	//	食事有無情報
        mealPlace: '',	//	食事個所情報
        modificationPoint: '',	//	変更ポイント説明
        cancellationNumber: '',	//	宿泊施設取消番号
        specialServiceRequest: '',	//	特別リクエスト
        otherServiceInformation: '',	//	一般情報
        originalMessage: '',	//	通知電文
        followUpInformation: '',	//	詳細情報有無
        travelAgencyBookingNumber: '',	//	旅行会社予約(管理)番号
        travelAgencyBookingDate: new Date(),	//	旅行会社予約受付日
        travelAgencyBookingTime: new Date(),	//	旅行会社受付時間
        travelAgencyReportNumber: '',	//	通知番号
        guestOrGroupNameSingleByte: '',	//	団体名または代表者氏名(半角)
        guestOrGroupNameDoubleByte: '',	//	団体名または代表者氏名よみがな(全角)
        guestOrGroupNameKanjiName: '',	//	団体名または代表者氏名 漢字
        guestOrGroupPhoneNumber: '',	//	団体名または代表者電話番号
        guestOrGroupPhoneNumber2: '',	//	団体名または代表者電話番号２
        transportaion: '',	//	入込方法
        bBasicRateInformation: initializeBasicRateInfo() as IBBasicRateInformation,    // 基本料金情報
        bRoomUnit: [
            initializeRoomUnit() as IBRoomUnit
        ] as IBRoomUnit[]
        // bRoomInformation: initializeRoomInfo() as IBRoomInformation[],  // 部屋情報
        // bMember: initializebMember() as IBMember,   // 会員情報
        // bMemo: initializeMemo() as IBMemo,  // 予約メモ情報
    } as IBBasicInformation
}

// 基本料金情報
function initializeBasicRateInfo(): IBBasicRateInformation {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        roomRateOrPersonalRate: '',	//	料金区分
        taxServiceFee: '',	//	税サ区分
        payment: '',	//	支払い方法
        bareNetRate: 0,	//	ネット決済額
        creditCardAuthority: '',	//	クレジットカード会社名
        creditCardNumber: '',	//	クレジットカード番号
        totalAccommodationCharge: 0,	//	合計宿泊料金(総額)
        totalAccommodationConsumptionTax: 0,	//	合計宿泊料金消費税
        totalAccommodationHotSpringTax: 0,	//	合計宿泊料金入湯税
        totalAccommodationHotelTax: 0,	//	合計宿泊料金ホテル税他
        totalAccommodationServiceFee: 0,	//	合計宿泊料金サービス料
        totalAccommodationOtherFee: 0,	//	合計その他料金
        commissionPercentage: 0,	//	手数料率(%)
        totalAccommodationCommissionAmount: 0,	//	合計手数料総額
    } as IBBasicRateInformation
}

// 部屋単位情報
function initializeRoomUnit(): IBRoomUnit {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        status: '予約',	//	予約ステータス
        checkInDate: new Date(),	//	チェックイン日
        checkInTime: new Date(),	//	チェックイン時間
        checkOutDate: new Date(),	//	チェックアウト日
        checkOutTime: new Date(),	//	チェックアウト時間
        nights: 1,	//	宿泊日数
        memo: '',	//	予約メモ情報
        bRoomInformation: [
            initializeRoomInfo() as IBRoomInformation
        ] as IBRoomInformation[],
    } as IBRoomUnit;
}

// 部屋情報
function initializeRoomInfo(): IBRoomInformation {
    return {
        id: 0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1,	//	部屋毎のSEQ
        roomDate: CConvertor.formatJpDateTime(new Date(), false, false),	//	利用年月日
        roomTypeCode: '',	//	部屋タイプコード
        roomTypeName: '',	//	部屋タイプ名
        roomCategory: '',	//	部屋カテゴリー
        viewType: 0,	//	部屋眺望
        smokingOrNonSmoking: '',	//	喫煙/禁煙
        perRoomPaxCount: 1,	//	1室利用人数
        roomPaxMaleCount: 0,	//	大人人員(男性)
        roomPaxFemaleCount: 0,	//	大人人員(女性)
        roomChildA70Count: 0,	//	子供A人数
        roomChildB50Count: 0,	//	子供B人数
        roomChildC30Count: 0,	//	子供C人数
        roomChildDnoneCount: 0,	//	子供D人数
        roomChildEcount: 0,	//	子供E人数
        roomChildFcount: 0,	//	子供F人数
        roomChildOtherCount: 0,	//	子供その他人数
        roomChildA70Name: '',	//	子供A名称
        roomChildB50Name: '',	//	子供B名称
        roomChildC30Name: '',	//	子供C名称
        roomChildDnoneName: '',	//	子供D名称
        roomChildEname: '',	//	子供E名称
        roomChildFname: '',	//	子供F名称
        roomChildOtherName: '',	//	子供その他名称
        facilities: '',	//	その他設備
        assignedRoomNumber: '',	//	部屋割り後客室名/番号
        roomSpecialRequest: '',	//	客室に対する特別リクエスト
        travelAgencyBookingNumber: '',	//	旅行会社予約(管理)番号
        travelAgencyBookingDate: new Date(),	//	旅行会社予約受付日
        travelAgencyBookingTime: new Date(),	//	旅行会社受付時間
        travelAgencyReportNumber: '',	//	通知番号

        bInformationOption: [
            initializeInformationOption() as IBInformationOption
         ] as IBInformationOption[],
        bRoomRateInformation: initializeRoomRateInfo() as IBRoomRateInformation,
        bGuestInformation: [
            initializeGuestInfo() as IBGuestInformation
         ] as IBGuestInformation[],

    } as IBRoomInformation
}

// 部屋オプション情報
function initializeInformationOption(): IBInformationOption {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomInformationId: 0,	//	部屋情報ID
        // optionNo: 0,	//	部屋情報明細オプション番号
        optionDate: new Date(),	//	オプション利用日
        optionName: '',	//	オプション名
        optionCode: '',	//	オプションコード
        optionCategory: 0,	//	オプション区分
        optionPerCategory: '',	//	オプション人員区分
        optionCount: 0,	//	オプション数
        optionRate: 0,	//	オプション料金
        totalOptionRate: 0,	//	オプション料金合計
    } as IBInformationOption

}

// 部屋料金情報
function initializeRoomRateInfo(): IBRoomRateInformation {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomInformationId: 0,	//	部屋情報ID
        roomDate: CConvertor.formatJpDateTime(new Date(), false, false),	//	利用年月日
        packageType: '',	//	企画商品区分
        packagePlanName: '',	//	企画(パッケージ)名
        packagePlanCode: '',	//	企画(パッケージ)コード
        packagePlanContent: '',	//	企画(パッケージ)内容
        perPaxRate: 0,	//	大人単価（一人料金)
        perChildA70Rate: 0,	//	子供A単価（一人料金)
        perChildB50Rate: 0,	//	子供B単価（一人料金)
        perChildC30Rate: 0,	//	子供C単価（一人料金)
        perChildDrate: 0,	//	子供D単価（一人料金)
        perChildErate: 0,	//	子供E単価（一人料金)
        perChildFrate: 0,	//	子供F単価（一人料金)
        perChildOtherRate: 0,	//	子供その他単価（一人料金)
        roomChildA70RateName: '',	//	子供A名称
        roomChildB50RateName: '',	//	子供B名称
        roomChildC30RateName: '',	//	子供C名称
        roomChildDrateName: '',	//	子供D名称
        roomChildErateName: '',	//	子供E名称
        roomChildFrateName: '',	//	子供F名称
        roomChildOtherRateName: '',	//	子供その他名称
        totalPerRoomRate: 0,	//	1室あたり宿泊料金合計
        totalPerRoomConsumptionTax: 0,	//	1室あたり宿泊料金消費税合計
        totalRoomHotSpringTax: 0,	//	1室あたり宿泊料金入湯税合計
        totalPerRoomServiceFee: 0,	//	1室あたり宿泊料金サービス料合計
    } as IBRoomRateInformation

}

// お客様情報
function initializeGuestInfo(): IBGuestInformation {
    return {
        id: 0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1,	//	部屋毎のSEQ
        bRoomInformationId: 0,	//	部屋情報ID
        guestNameSingleByte: '',	//	お客様氏名半角カタカナ英字(姓名)
        guestSurName: '',	//	お客様氏名全角ふりがな(姓)
        guestKanjiName: '',	//	お客様氏名全角漢字(姓名)
        guestGender: '',	//	お客様男女区分
        guestAge: 0,	//	お客様年齢
        guestDateOfBirth: new Date(),	//	お客様生年月日
        guestPhoneNumber: 0,	//	お客様電話番号
        guestPhoneNumberType: '',	//	お客様電話番号区分
        guestEmergencyPhoneNumber: 0,	//	お客様緊急連絡先
        guestEmergencyPhoneNumberType: '',	//	お客様緊急連絡先区分
        guestEmail: '',	//	お客様Eメール
        guestCountry: '',	//	お客様国籍
        guestStateProvidence: '',	//	お客様住所都道府県
        guestCityName: '',	//	お客様住所区市名
        guestAddressLine: '',	//	お客様住所町村名
        guestStreetNumber: '',	//	お客様住所番地名
        guestPostalCode: '',	//	お客様住所郵便番号
        guestAddress1: '',	//	お客様住所１
        guestAddress2: '',	//	お客様住所２
        companyNameSingleByte: '',	//	勤務先半角カタカナ英字(姓名)
        companyKanjiName: '',	//	勤務先名全角漢字(姓名)
        companyPhoneNumber: '',	//	勤務先電話番号
        companyPhoneNumberType: '',	//	勤務先電話番号 電話区分ID
        companyEmail: '',	//	勤務先Eメール
        companyStateProvidence: '',	//	勤務先住所都道府県
        companyPostalCode: '',	//	勤務先住所郵便番号
        companyAddress1: '',	//	勤務先住所１
        companyAddress2: '',	//	勤務先住所２
        rankId: 0,	//	ランクID
        customerId: 0,	//	顧客ID
        memberId: 0,	//	メンバーID
        expressId: 0,	//	エクスプレスメンバーID
    }as IBGuestInformation

}

// 会員情報
function initializebMember(): IBMember {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        userName: 0,	//	予約者・会員名漢字
        userKana: '',	//	予約者・会員名カタカナ
        userTel: '',	//	予約者・会員電話番号
        userMailAddr: '',	//	予約者・会員Email
        userZip: '',	//	予約者・会員郵便番号
        userAddr: '',	//	予約者・会員住所
        userCorp: '',	//	予約者・会員会社
        userGivinPoints: 0,	//	付与ポイント
        userUsePoints: 0,	//	使用ポイント
        totalAccommodationDecleasePoints: 0,	//	ポイント割引後の総額
        amountClaimed: 0,	//	宿泊者請求額
        memberNumber: '',	//	予約者・会員番号
        printCategory: 0,	//	予約者・案内書印字区分
        memberStatus: 0,	//	会員状態
        userUsePointList: '',	//	使用ポイントリスト
    } as IBMember

}

// 予約メモ情報
function initialiseBFreememoComponent(): IBFreememoComponent {
    return {
        id:0,
        groupId: 0,	//	グループID
        companyId: 0,	//	会社ID
        storeId: 0,	//	店舗ID
        bBasicInformationId: 0,	//	基本宿泊情報ID
        bRoomUnitId: 1, //	部屋毎のSEQ
        freeMemoId: 0,
        detail: '',	//	予約メモ情報
    } as IBFreememoComponent

}


// 入金ダイアログ
function initialiseSettlementPaymentAccountComponent(): IBillingPaymentAccountComponent {
    return {
        accountingItemId: 0,
        accountingItemName: '',
        accountingItemNameE: '',
        accountingItemPrintTitle: '',
        accountingItemPrintTitleE: '',
        accountingItemInterfaceCd01: '',
        accountingItemInterfaceCd02: '',
        billingAddressId: 0,
        billingAddressName: '',
        paymentAmount: 0,
        roomInfomationId: 0,
        packagePlanId: 0,
        bRoomUnitId: 0,
        useRoomId: 0,
        useRoomNo: ''
    } as IBillingPaymentAccountComponent
}