import { defineStore } from 'pinia'
import { IArrangementComponent } from '@/interface/component/master/i-arrangement-component';

export const useArrangementStore = defineStore('arrangement', {
    state: () => ({
        arrangements: [] as IArrangementComponent[],
    }),
    
    actions: {
        getArrangement(id: number){
            const resFind = this.arrangements.find((data) => data.id === id);
            return resFind;
        },

        getArrangementName(id: number) {
            const resFind = this.arrangements.find((data) => data.id === id);
            if (resFind) {
                return resFind.name;
            } else {
                return '';
            }
        },

        getAccountingItemName(id: number) {
            const resFind = this.arrangements.find((data) => data.id === id);
            if (resFind) {
                return resFind.accountingItemName;
            } else {
                return '';
            }
        },

        getAccountingItemPrice(id: number) {
            const resFind = this.arrangements.find((data) => data.id === id);
            if (resFind) {
                return resFind.accountingItemPrice;
            } else {
                return 0;
            }
        }
    }
})

