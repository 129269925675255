import { defineStore } from 'pinia'
import { IHolidayComponent } from "@/interface/component/master/i-holiday-component";

export const useHolidayStore = defineStore('holiday', {
    state: () => ({
        holidays: [] as IHolidayComponent[],
    }),
    
    actions: {
        getHolidayById(id: number) {
            const resFind = this.holidays.find((data) => data.id === id);
            return resFind;
        },

        getHolidayByDate(date: string) {
            const resFind = this.holidays.find((data) => data.holiday === date);
            return resFind;
        }
    },
    persist: {
        key: 'holiday',
        storage: localStorage,
    }
})

