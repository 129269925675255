<template>
	<div class="layout-footer">
		<!-- <img alt="Logo" :src="footerImage()" height="20" class="mr-2" /> -->
			<div class="all-right">
				<span>©Copyright X1Studio 2023-All Rights Reserved</span>
			</div>
			<div class="date flex">
				<div style="text-align: right;">
					<!-- <div>業務日付&emsp;：{{ bussinessDate }}</div> -->
					<div>
						{{ t("FOOT_USER") }}
					</div>
					<div>
						{{ t("FOOT_LOGIN_DATE") }}
					</div>
				</div>
				<div style="text-align: left;">
					<div>{{ auth.user.userName }}</div>
					<div>{{ auth.user.lastLogin ? format() : '' }}</div>
				</div>
			</div>
	</div>
</template>

<script>
	import { useAuthStore } from '@/store/auth';
	import { format } from 'date-fns';
	import i18n from "@/locale/i18n";
	export default {
		name: "AppFooter",
		data() {
			return {
				auth: useAuthStore(),
				t: i18n.global.t,
			}
		},
		methods: {
			footerImage() {
				return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
			},
			format() {
				return format(new Date(this.auth.user.lastLogin), 'yyyy/MM/dd HH:mm');
			}
		},
		computed: {
			darkTheme() {
				return this.$appState.darkTheme;
			},

			bussinessDate() {
				return this.auth.bussinessDate;
			}
		}
	}
</script>

<style scoped>
	.date {
		position: absolute;
		top: .8rem;
		right: 10rem;
	}
	.all-right {
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
		margin: 0 auto;
		text-align: center;
	}
</style>