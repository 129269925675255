// defineStoreをインポート
import { defineStore } from "pinia";
// import axios from 'axios';
import { ITemairazuComponent } from "@/interface/component/linkage/i-temairazu-component";

// export const useTemairazuStore = defineStore('registrationList', {
export const useTemairazuStore = defineStore("linkageTemairazuList", {
  state: () => ({
    condition: initializeComponent() as ITemairazuComponent,
    // searchResult: [] as IRegistrationListComponent[],
    detailBookingBasicInformationId: 0 as number,
    bookingNo: 0 as number,
    travelAgencyBookingNumber: "" as string,
  }),
  actions: {
    initializeCondition() {
      return initializeComponent();
    },
  },
});

function initializeComponent(): ITemairazuComponent {
  return {
    id: 0,
    groupId: 0, //	グループID
    companyId: 0, //	会社ID
    storeId: 0, //	店舗ID
    status: "", //	予約ステータス
    xmlFileRegistrationId: "",
  } as unknown as ITemairazuComponent;
}
