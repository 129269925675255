import { defineStore } from 'pinia'
import { ICountryComponent } from '@/interface/component/registration/i-registration-component';

export const useCountryStore = defineStore('country', {
    state: () => ({
        countries: [] as ICountryComponent[],
    }),
    
    actions: {
        getCountry(settingParam: string) {
            const resFind = this.countries.find((data) => data.settingParam === settingParam);
            return resFind;
        }
    },
    // persist: {
    //     key: 'country',
    //     storage: localStorage,
    // }
})

