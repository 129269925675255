// import { CLog } from './c-log';
// import { IAddress } from 'src/app/Interface/ServerData/Common/i-address';
// import { CUtils } from './c-utils';

export class CConvertor {

  /**
   * スネークケースからキャメルケースへ変換
   * @param str スネークケース文字列
   * @returns キャメルケース文字列
   */
  public static toCamelCase(str: string): string {
    return str.split('_').map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }).join('');
  }

  /**
   * オブジェクト内変数名をスネークケースからキャメルケースへ変換
   * @param obj スネークケース変数名のオブジェクト
   * @returns キャメルケース変数名のオブジェクト
   */
  public static toCamelCaseObject(obj: any): any {
    // CLog.debug(`obj: ${JSON.stringify(obj)}`);
    if (typeof obj === 'string' || obj instanceof String) {
      // CLog.debug('R1');
      return obj;
    } else if (typeof obj === 'number' || obj instanceof Number) {
      // CLog.debug('R2');
      return obj;
    } else if (typeof obj === 'boolean' || obj instanceof Boolean) {
      // CLog.debug('R3');
      return obj;
    }
    // CLog.debug('R0');

    const result: { [key: string]: any; } = {};
    Object.keys(obj).forEach(key => {
      // CLog.debug(`key: ${key}, value: ${JSON.stringify(obj[key])}`);
      if (obj[key] instanceof Array) {
        // CLog.debug('array parse mode');
        // tslint:disable-next-line: forin
        for (const index in obj[key]) {
          // CLog.debug(`index: ${index}, value: ${JSON.stringify(obj[key][index])}`);
          obj[key][index] = this.toCamelCaseObject(obj[key][index]);
        }
      } else if (obj[key] instanceof Object) {
        // CLog.debug('object parse mode');
        obj[key] = this.toCamelCaseObject(obj[key]);
      }

      // CLog.debug('exec convert');
      let newKey = key;
      if (newKey.indexOf('_') !== -1) {
        newKey = CConvertor.toCamelCase(key);
      }
      result[newKey] = obj[key];
    });
    return result;
  }

  /**
   * キャメルケースからスネークケースへ変換
   * @param str キャメルケース文字列
   * @returns スネークケース文字列
   */
  public static toSnakeCase(str: string): string {
    return str.split(/(?=[A-Z])/).join('_').toLowerCase();
  }

  /**
   * オブジェクト内変数名をキャメルケースからスネークケースへ変換
   * @param obj キャメルケース変数名のオブジェクト
   * @returns スネークケース変数名のオブジェクト
   */
  public static toSnakeCaseObject(obj: any): any {
    // CLog.debug(`obj: ${JSON.stringify(obj)}`);
    if (typeof obj === 'string' || obj instanceof String) {
      // CLog.debug('R1');
      return obj;
    } else if (typeof obj === 'number' || obj instanceof Number) {
      // CLog.debug('R2');
      return obj;
    } else if (typeof obj === 'boolean' || obj instanceof Boolean) {
      // CLog.debug('R3');
      return obj;
    }
    // CLog.debug('R0');

    const result: { [key: string]: any; } = {};
    Object.keys(obj).forEach(key => {
      if (obj[key] instanceof Array) {
        // tslint:disable-next-line: forin
        for (const index in obj[key]) {
          obj[key][index] = this.toSnakeCaseObject(obj[key][index]);
        }
      } else if (obj[key] instanceof Object) {
        obj[key] = this.toSnakeCaseObject(obj[key]);
      }
      result[CConvertor.toSnakeCase(key)] = obj[key];
    });
    return result;
  }

  /**
   * JSON をXML 文字列に変換
   * @param tag 開始タグと終了タグ
   * @param targets 対象データ
   * @param isInit 初回判定
   */
  public static jsonToXmlString(tag: string, targets: any, isInit: boolean = true): string {
    let xml = isInit ? '<?xml version="1.0" encoding="utf-8"?>' : '';

    if (targets != null) {
      if (targets instanceof Array) {
        // tslint:disable-next-line: forin
        for (const key in targets) {
          xml += this.jsonToXmlString(tag, targets[key], false);
        }
        // CLog.debug(`array set: ${tag} -> ${xml}`);
      } else if (targets instanceof Object) {
        xml += `<${tag}>`;
        // tslint:disable-next-line: forin
        for (const key in targets) {
          xml += this.jsonToXmlString(key, targets[key], false);
        }
        xml += `</${tag}>`;
        // CLog.debug(`object set: ${tag} -> ${xml}`);
      } else {
        xml += `<${tag}>`;
        xml += targets;
        xml += `</${tag}>`;
        // CLog.debug(`normal set: ${tag} -> ${xml}`);
      }
    }

    return xml;
  }

  /**
   * XML 文字列をDocument オブジェクトに変換
   * @param order XML 文字列
   */
  public static xmlStringToDocument(order: string): Document {
    let tmp = order;

    tmp = tmp.replace('?xml version="1.0" encoding="utf-8"?', '');
    tmp = tmp.replace('?xml version="1.0"?', '');
    tmp = tmp.replace('?xml encoding="utf-8"?', '');
    tmp = tmp.replace('<>', '');
    // CLog.debug(`trim head tag: ${tmp}`);

    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(tmp, 'text/xml');
    // console.log(xmlDoc);
    return xmlDoc;
  }

  /**
   * 日本円表記に変換
   * @param order 数値
   * @returns 日本円表記
   */
  public static toJpy(order: number) {
    return '¥ ' + order.toLocaleString();
  }

  /**
   * 誕生日から年齢へ変換
   * @param date 誕生日
   * @returns 年齢
   */
  public static birthDayToAge(date: string) {
    const birthDate = new Date(date);
    const nowDate = new Date();
    const age = nowDate.getFullYear() - birthDate.getFullYear();
    const thisYearsBirthday = new Date(nowDate.getFullYear(), birthDate.getMonth(), birthDate.getDate());
    return age + (thisYearsBirthday.getTime() > nowDate.getTime() ? -1 : 0);
  }

  // /**
  //  * フルネーム変換
  //  * @param firstName 名前
  //  * @param lastName 苗字
  //  */
  // public static toFullName(firstName: string = null, lastName: string = null) {
  //   // CLog.debug(`convert from firstName: ${firstName}, lastName: ${lastName}`);
  //   let name = '';

  //   if (lastName) {
  //     name += lastName;
  //     // CLog.debug(`set lastName: ${lastName} -> fullName: ${name}`);
  //   } else {
  //     CLog.debug(`lastName is null, skip`);
  //   }

  //   if (firstName) {
  //     if (name !== '') {
  //       const reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
  //       if (reg.test(name)) {
  //         name = firstName + ' ' + name;
  //         // CLog.debug(`set EN style, firstName: ${firstName} -> fullName: ${name}`);
  //       } else {
  //         name = name + ' ' + firstName;
  //         // CLog.debug(`set JP style, firstName: ${firstName} -> fullName: ${name}`);
  //       }
  //     } else {
  //       name += firstName;
  //       // CLog.debug(`set firstName: ${firstName} -> fullName: ${name}`);
  //     }
  //   } else {
  //     CLog.debug(`firstName is null, skip`);
  //   }

  //   return name;
  // }

  // /**
  //  * 住所全結合表記へ変換
  //  * @param order 変換元データ
  //  */
  // public static toFullAddress(order: IAddress) {
  //   let style = 'ASIA';
  //   if (order.countryCode) {
  //     if (order.countryCode.indexOf('JPN') === -1) {
  //       style = 'OTHER';
  //     }
  //   }

  //   let address = '';
  //   switch (style) {
  //     case 'ASIA':
  //       address += order.state;
  //       if (order.city) {
  //         address += order.city;
  //       }
  //       if (order.address1) {
  //         address += order.address1;
  //       }
  //       if (order.address2) {
  //         address += order.address2;
  //       }
  //       break;
  //     default:
  //       address += order.address2;
  //       if (order.address1) {
  //         address += (address ? ', ' : '') + order.address1;
  //       }
  //       if (order.city) {
  //         address += (address ? ', ' : '') + order.city;
  //       }
  //       if (order.state) {
  //         address += (address ? ', ' : '') + order.state;
  //       }
  //       break;
  //   }

  //   return address;
  // }

  // /**
  //  * テストデータ日時補完
  //  * @param base 基点日時
  //  * @param target 対象日時
  //  * @returns 補完後日時
  //  */
  // public static correctTestDateTime(base: string, target: string, offset: number = 0) {
  //   let diff = CUtils.getDateTimeDiff(CUtils.getDateStr(base), CUtils.getDateStr(target));
  //   // CLog.debug(`diff: ${diff}`);
  //   if (diff < 0) {
  //     diff = 0;
  //   }
  //   const newDateTime = CUtils.getDateStr(null, diff + offset) + ' ' + CUtils.getTimeStr(target);
  //   // CLog.debug(`before: ${target} to after: ${newDateTime}`);
  //   return newDateTime;
  // }

  /**
   * ISO8601 形式の日時データをyyyy-mm-dd 形式に変換
   * @param order ISO8601 形式の日時データ
   */
  public static iso8601ToNormalDate(order: Date) {
    const y = order.getFullYear();
    const m = ('00' + (order.getMonth() + 1)).slice(-2);
    const d = ('00' + order.getDate()).slice(-2);
    return (y + '-' + m + '-' + d);
  }

  /**
   * ISO8601 形式の日時データをyyyy-mm-dd h:i 形式に変換
   * @param order ISO8601 形式の日時データ
   */
  public static formatYyyyMmDdHhMm(order: Date) {
    const y = order.getFullYear();
    const m = ('00' + (order.getMonth() + 1)).slice(-2);
    const d = ('00' + order.getDate()).slice(-2);
    const h = ('00' + order.getHours()).slice(-2);
    const i = ('00' + order.getMinutes()).slice(-2);
    return (y + '-' + m + '-' + d + ' ' + h + ':' + i);
  }

  /**
   * yyyy-mm-dd h:i:s 形式に変換
   * @param order 日時データ
   * @param includeMinutes 時分を含めるか
   * @param includeSeconds 秒を含めるか
   * @return yyyy-mm-dd h:i:s 形式日時 (文字)
   */
  public static formatRfc3339(order: any, includeHM: boolean = true, includeSeconds: boolean = true) {
    let target = null;
    if (order instanceof Date) {
      // CLog.debug('order is Date');
      target = order;
    } else if (order instanceof String) {
      // CLog.debug('order is String');
      target = new Date(this.formatIso8601(order as string));
    } else {
      // CLog.debug('order is Other type');
      target = new Date(order);
    }

    const yyyy = `${target.getFullYear()}`;
    const MM = `0${target.getMonth() + 1}`.slice(-2);
    const dd = `0${target.getDate()}`.slice(-2);
    const HH = `0${target.getHours()}`.slice(-2);
    const mm = `0${target.getMinutes()}`.slice(-2);
    const ss = `0${target.getSeconds()}`.slice(-2);

    let result = yyyy;
    result += '-' + MM;
    result += '-' + dd;

    if (includeHM) {
      result += ' ' + HH;
      result += ':' + mm;
    }

    if (includeSeconds) {
      result += ':' + ss;
    }

    return result;
  }

  /**
   * 日本形式に変換
   * @param order 日時(文字列)
   * @param includeMinutes 時分を含めるか
   * @param includeSeconds 秒を含めるか
   * @return yyyy/mm/dd h:i:s 形式日時 (文字)
   */
  public static formatJpDateTime(order: any, includeHM: boolean = true, includeSeconds: boolean = true) {
    let target = this.formatRfc3339(order, includeHM, includeSeconds);
    target = target.replace(/-/g, '/');
    return target;
  }

  /**
   * yyyy-mm-dd h:i:s 形式の日時データをISO8601 形式に変換
   * @param order ISO8601 形式の日時(文字列)
   */
  public static formatIso8601(order: string) {
    let result = order;

    // javascript は[yyyy-mm-dd]より[yyyy/mm/dd]がお好み
    // result = result.replace(/-/g, '/');

    // javascript は[yyyy/mm/dd h:i:s]より[yyyy/mm/ddTh:i:s]がお好み
    result = result.replace(' ', 'T');

    // javascript はミリ秒とタイムゾーン無いと拗ねる
    // result += '.000+09:00';

    // CLog.debug(`convert ${order} to ${result}`);
    return result;
  }

  /**
   * HH:mm の文字列を HH:mm:ss 形式に変換
   * @param time HH:mm 形式の文字列データ
   */
  public static insertSeconds(time: string) {
    const timeArray = time.split(/[:]/);
    const h = timeArray.shift();
    const m = timeArray.shift();
    const s = '00';
    return (h + ':' + m + ':' + s);
  }
}
