// defineStoreをインポート
import { defineStore } from 'pinia'
import { IRankComponent } from '@/interface/component/master/i-rank-component';

export const useRankStore = defineStore('rank', {
    state: () => ({
        ranks: [] as IRankComponent[]
    }),
    
    actions: {
        getRank(id: number){
            const resFind = this.ranks.find((data) => data.id === id);
            return resFind;
        },

        getRankName(id: number) {
            const resFind = this.ranks.find((data) => data.id === id);
            if (resFind) {
                return resFind.name;
            } else {
                return '';
            }
        },
    },
    persist: {
        key: 'rank',
        storage: localStorage,
    }
})

