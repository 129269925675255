// Internationalization
// const datetimeFormats = {
//   'en-US': {
//     short: {
//       year: 'numeric', month: 'short', day: 'numeric'
//     },
//     long: {
//       year: 'numeric', month: 'short', day: 'numeric',
//       weekday: 'short', hour: 'numeric', minute: 'numeric'
//     }
//   },
//   'ja-JP': {
//     short: {
//       year: 'numeric', month: 'short', day: 'numeric'
//     },
//     long: {
//       year: 'numeric', month: 'short', day: 'numeric',
//       weekday: 'short', hour: 'numeric', minute: 'numeric', hour12: true
//     }
//   }
// }

import { createI18n } from "vue-i18n";
import enUS from './data/en.json'
import jaJP from './data/ja.json'

// type MessageSchema = typeof jaJP
// // const i18n = createI18n({
// const i18n = createI18n<[MessageSchema], 'en-US' | 'ja-JP'>({
//     locale: 'ja-JP',
//     messages: {
//         'ja-JP': jaJP,
//         'en-US': enUS,
//     },
//     // datetime: datetimeFormats,
// })

const i18n = createI18n<false>({
// const i18n = createI18n({
    legacy: false,
    locale: 'ja',
    fallbackLocale: 'en',
    globalInjection: true,
    messages: {
        'en': enUS,
        'ja': jaJP
    },
    datetimeFormats: {
        'ja': {
        short: {
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            timeZoneName: 'short',
            // timezone: 'Asia/Tokyo'
        }
        }
    },
    numberFormats: {
        'ja': {
        currency: {
            style: 'currency',
            currencyDisplay: 'symbol',
            currency: 'JPY'
        }
        }
    }
})

export default i18n;

