<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppMenuTopBar/>
        <!-- <AppTopBar @menu-toggle="onMenuToggle" /> -->
        <!-- <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
            <locale-dropdown/>
        </div> -->

        <div class="layout-main-container">
            <div class="layout-main">
                <router-view />
            </div>
            <AppFooter />
        </div>

		<!-- <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" /> -->
        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>
        <SpinnerDialog />
	</div>
</template>

<script>
// import AppTopBar from './AppTopbar.vue';
// import AppMenu from './AppMenu.vue';
// import AppConfig from './AppConfig.vue';
import AppFooter from './AppFooter.vue';
// import LocaleDropdown from './components/LocaleDropdown.vue';
import SpinnerDialog from './components/common/SpinnerDialog.vue';
import { useAuthStore } from '@/store/auth';
import AppMenuTopBar from './AppMenuTopBar.vue';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            // menu : [
            //     {
            //         label: 'MENU.HOME',
            //         items: [{
            //             label: 'MENU.DASHBOARD', icon: 'pi pi-fw pi-home', to: '/'
            //         }]
            //     },
            //     {
            //         label: 'MENU.REGISTRATION_MANAGEMENT',
            //         items: [
            //             { label: 'MENU.REGISTRATION_LIST', icon: 'pi pi-fw pi-book', to: '/registration/list' },
            //             { label: 'MENU.REGISTRATION_DETAIL', icon: 'pi pi-fw pi-plus', to: '/registration/detail' }
            //         ]
            //     },
            //     {
            //         label: 'MENU.SCHEDULE',
            //         items: [
            //             { label: 'MENU.SCHEDULE_MAIN', icon: 'pi pi-fw pi-book', to: '/schedule' },
            //         ]
            //     },
            //      {
            //         label: 'MENU.AVAILABILITY',
            //         items: [
            //             { label: 'MENU.AVAILABILITY', icon: 'pi pi-fw pi-book', to: '/availability/list' },
            //         ]
            //     },
            //
            //     // HOTELOS_DEV-196 プラン検索 add start
            //     {
            //         label: 'MENU.PLAN',
            //         items: [
            //             // HOTELOS_DEV-194 料金カレンダー add start
            //             { label: 'MENU.SEASON_CALENDAR', icon: 'pi pi-fw pi-book', to: '/master/plan/season-calendar' },
            //             // HOTELOS_DEV-194 料金カレンダー add end
            //             { label: 'MENU.PLAN_SEARCH', icon: 'pi pi-fw pi-book', to: '/master/plan/list' },
            //         ]
            //     },
            //     // HOTELOS_DEV-196 プラン検索 add end
            //
            //     {
            //         label: 'MENU.MASTER',
            //         items: [
            //             { label: 'MENU.STORE', icon: 'pi pi-fw pi-book', to: '/master/store' },
            //             { label: 'MENU.STORE_SEQUENCE_SETTING', icon: 'pi pi-fw pi-book', to: '/master/store/squence' },
            //             // HOTELOS_DEV-213 20221018 add start
            //             { label: 'MENU.TAX', icon: 'pi pi-fw pi-book', to: '/master/tax' },
            //             // HOTELOS_DEV-213 20221018 add end
            //
            //             // HOTELOS_DEV-211 20221019 add start
            //             { label: 'MENU.CONSUMPTION_TAX', icon: 'pi pi-fw pi-book', to: '/master/consumption-tax' },
            //             // HOTELOS_DEV-211 20221019 add end
            //
            //             // HOTELOS_DEV-223 20221019 部屋タイプ start
            //             { label: 'MENU.ROOM_TYPE', icon: 'pi pi-fw pi-book', to: '/master/room-type' },
            //             // HOTELOS_DEV-223 20221019 部屋タイプ end
            //
            //             // HOTELOS_DEV-225 部屋名称（番号） add start
            //             { label: 'MENU.ROOM_NAME', icon: 'pi pi-fw pi-book', to: '/master/room' },
            //             // HOTELOS_DEV-225 部屋名称（番号） add end
            //
            //             // HOTELOS_DEV-216 利用目的マスタ add start
            //             { label: 'MENU.PURPOSE_GROUP', icon: 'pi pi-fw pi-book', to: '/master/purpose-group' },
            //             { label: 'MENU.PURPOSE', icon: 'pi pi-fw pi-book', to: '/master/purpose' },
            //             // HOTELOS_DEV-216 利用目的マスタ add end
            //
            //             // HOTELOS_DEV-193 プランマスタ add start
            //             { label: 'MENU.PLAN_GROUP', icon: 'pi pi-fw pi-book', to: '/master/plan-group' },
            //             // HOTELOS_DEV-193 プランマスタ add end
            //
            //             // HOTELOS_DEV-231 20221020 add start
            //             { label: 'MENU.PAYMENT_METHOD', icon: 'pi pi-fw pi-book', to: '/master/payment-method' },
            //             // HOTELOS_DEV-231 20221020 add end
            //
            //             // HOTELOS_DEV-232 20221020 add start
            //             { label: 'MENU.PAYMENT_ACCOUNT', icon: 'pi pi-fw pi-book', to: '/master/payment-account' },
            //             // HOTELOS_DEV-232 20221020 add end
            //
            //             // HOTELOS_DEV-231 20221026仕様変更 add start
            //             { label: 'MENU.ACCOUNTING_ITEM', icon: 'pi pi-fw pi-book', to: '/master/accounting-item' },
            //             // HOTELOS_DEV-231 20221026仕様変更 add end
            //             // HOTELOS_DEV-219 20221114 add start
            //             { label: 'MENU.USER', icon: 'pi pi-fw pi-book', to: '/master/user' },
            //             // HOTELOS_DEV-219 20221114 add end
            //
            //             // HOTELOS_DEV-205 手配マスタ add start
            //             { label: 'MENU.ARRANGEMENT_GROUP', icon: 'pi pi-fw pi-book', to: '/master/arrangement-group' },
            //             { label: 'MENU.ARRANGEMENT', icon: 'pi pi-fw pi-book', to: '/master/arrangement' },
            //             // HOTELOS_DEV-205 手配マスタ add end
            //
            //             // HOTELOS_DEV-234 20221108仕様変更 add start
            //             { label: 'MENU.BILLING_ADDRESSES', icon: 'pi pi-fw pi-book', to: '/master/billing-address' },
            //             // HOTELOS_DEV-234 20221108仕様変更 add end
            //
            //             // HOTELOS_DEV-217 20221109仕様変更 add start
            //             { label: 'MENU.SALES_ROUTE_GROUP', icon: 'pi pi-fw pi-book', to: '/master/sales-route-group' },
            //             { label: 'MENU.SALES_ROUTE', icon: 'pi pi-fw pi-book', to: '/master/sales-route' },
            //             // HOTELOS_DEV-217 20221109仕様変更 add end
            //
            //             // HOTELOS_DEV-205 手配マスタ add start
            //             { label: 'MENU.ARRANGEMENT_GROUP', icon: 'pi pi-fw pi-book', to: '/master/arrangement-group' },
            //             { label: 'MENU.ARRANGEMENT', icon: 'pi pi-fw pi-book', to: '/master/arrangement' },
            //             // HOTELOS_DEV-205 手配マスタ add end
            //
            //           // HOTELOS_DEV-209 子供の種別 20221111 add start
            //           { label: 'MENU.CHILD_FEE', icon: 'pi pi-fw pi-book', to: '/master/child-fee' },
            //           // HOTELOS_DEV-209 子供の種別 20221111 add end
            //             // HOTELOS_DEV-239 子供の種別 20221125 add start
            //             { label: 'MENU.FREE_MEMO', icon: 'pi pi-fw pi-book', to: '/master/free-memo' },
            //             // HOTELOS_DEV-239 子供の種別 20221125 add end
            //
            //         ]
            //     },
            //     {
            //         label: 'MENU.LINKAGE',
            //         items: [
            //             { label: 'MENU.LINKAGE_TEMAIRAZU', icon: 'pi pi-fw pi-book', to: '/linkage/temairazulist' },
            //         ]
            //     },
            //     {
            //         label: 'MENU.SYS_ADMIN',
            //         items: [
            //             { label: 'MENU.GROUP', icon: 'pi pi-fw pi-book', to: '/admin/master/group' },
            //             { label: 'MENU.COMPANY', icon: 'pi pi-fw pi-book', to: '/admin/master/company' },
            //             { label: 'MENU.STORE', icon: 'pi pi-fw pi-book', to: '/admin/master/store' },
            //         ]
            //     },

				// {
				// 	label: 'UI Components', icon: 'pi pi-fw pi-sitemap',
				// 	items: [
				// 		{label: 'Form Layout', icon: 'pi pi-fw pi-id-card', to: '/formlayout'},
				// 		{label: 'Input', icon: 'pi pi-fw pi-check-square', to: '/input'},
                //         {label: "Float Label", icon: "pi pi-fw pi-bookmark", to: "/floatlabel"},
                //         {label: "Invalid State", icon: "pi pi-fw pi-exclamation-circle", to: "/invalidstate"},
				// 		{label: 'Button', icon: 'pi pi-fw pi-mobile', to: '/button'},
				// 		{label: 'Table', icon: 'pi pi-fw pi-table', to: '/table'},
				// 		{label: 'List', icon: 'pi pi-fw pi-list', to: '/list'},
				// 		{label: 'Tree', icon: 'pi pi-fw pi-share-alt', to: '/tree'},
				// 		{label: 'Panel', icon: 'pi pi-fw pi-tablet', to: '/panel'},
				// 		{label: 'Overlay', icon: 'pi pi-fw pi-clone', to: '/overlay'},
                //         {label: "Media", icon: "pi pi-fw pi-image", to: "/media"},
				// 		{label: 'Menu', icon: 'pi pi-fw pi-bars', to: '/menu'},
				// 		{label: 'Message', icon: 'pi pi-fw pi-comment', to: '/messages'},
				// 		{label: 'File', icon: 'pi pi-fw pi-file', to: '/file'},
				// 		{label: 'Chart', icon: 'pi pi-fw pi-chart-bar', to: '/chart'},
				// 		{label: 'Misc', icon: 'pi pi-fw pi-circle-off', to: '/misc'},
				// 	]
				// },
                // {
                //     label: 'PrimeBlocks',
                //     items: [
                //         {label: 'Free Blocks', icon: 'pi pi-fw pi-eye', to: '/blocks', badge: "NEW"},
                //         {label: 'All Blocks', icon: 'pi pi-fw pi-globe', url: 'https://www.primefaces.org/primeblocks-vue', target: '_blank'}
                //     ]
                // },
                // {
                //     label: 'Utilities',
                //     items: [
                //         {label: 'PrimeIcons', icon: 'pi pi-fw pi-prime', to: '/icons'},
                //         {label: 'PrimeFlex', icon: 'pi pi-fw pi-desktop', url: 'https://www.primefaces.org/primeflex/', target: '_blank'},
                //     ]
                // },
				// {
				// 	label: 'Pages', icon: 'pi pi-fw pi-clone',
				// 	items: [
				// 		{label: 'Crud', icon: 'pi pi-fw pi-user-edit', to: '/crud'},
				// 		{label: 'Timeline', icon: 'pi pi-fw pi-calendar', to: '/timeline'},
                //         {label: 'Landing', icon: 'pi pi-fw pi-globe', to: '/landing'},
                //         {label: 'Login', icon: 'pi pi-fw pi-sign-in', to: '/login'},
                //         {label: 'Error', icon: 'pi pi-fw pi-times-circle', to: '/error'},
                //         {label: 'Not Found', icon: 'pi pi-fw pi-exclamation-circle', to: '/notfound'},
                //         {label: 'Access Denied', icon: 'pi pi-fw pi-lock', to: '/access'},
				// 		{label: 'Empty', icon: 'pi pi-fw pi-circle-off', to: '/empty'},
				// 	]
				// },
                // {
                //     label: 'Menu Hierarchy', icon: 'pi pi-fw pi-search',
                //     items: [
                //         {
                //             label: 'Submenu 1', icon: 'pi pi-fw pi-bookmark',
                //             items: [
                //                 {
                //                     label: 'Submenu 1.1', icon: 'pi pi-fw pi-bookmark',
                //                     items: [
                //                         {label: 'Submenu 1.1.1', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 1.1.2', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 1.1.3', icon: 'pi pi-fw pi-bookmark'},
                //                     ]
                //                 },
                //                 {
                //                     label: 'Submenu 1.2', icon: 'pi pi-fw pi-bookmark',
                //                     items: [
                //                         {label: 'Submenu 1.2.1', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 1.2.2', icon: 'pi pi-fw pi-bookmark'}
                //                     ]
                //                 },
                //             ]
                //         },
                //         {
                //             label: 'Submenu 2', icon: 'pi pi-fw pi-bookmark',
                //             items: [
                //                 {
                //                     label: 'Submenu 2.1', icon: 'pi pi-fw pi-bookmark',
                //                     items: [
                //                         {label: 'Submenu 2.1.1', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 2.1.2', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 2.1.3', icon: 'pi pi-fw pi-bookmark'},
                //                     ]
                //                 },
                //                 {
                //                     label: 'Submenu 2.2', icon: 'pi pi-fw pi-bookmark',
                //                     items: [
                //                         {label: 'Submenu 2.2.1', icon: 'pi pi-fw pi-bookmark'},
                //                         {label: 'Submenu 2.2.2', icon: 'pi pi-fw pi-bookmark'}
                //                     ]
                //                 }
                //             ]
                //         }
                //     ]
                // },
                // {
                //     label: 'Get Started',
                //     items: [
                //         {label: 'Documentation', icon: 'pi pi-fw pi-question', command: () => {window.location = "#/documentation"}},
                //         {label: 'View Source', icon: 'pi pi-fw pi-search', command: () => {window.location = "https://github.com/primefaces/sakai-vue"}}
                //     ]
                // }
            // ]
        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
      },
    },
    unmounted() {
    //   let authStore = useAuthStore();
    //   authStore.unregisterAllWSChannel();
    },
    mounted() {
      //Register laravel websocket listener
    //   let authStore = useAuthStore();
    //   authStore.registerWSListener();
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        },
        menu() {
            let authStore = useAuthStore();
            return authStore.menus ? authStore.menus : [];
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
    // "AppTopBar": AppTopBar,
    // "AppMenu": AppMenu,
    // "AppConfig": AppConfig,
    "AppFooter": AppFooter,
    // "LocaleDropdown": LocaleDropdown,
    SpinnerDialog,
    'AppMenuTopBar': AppMenuTopBar
}
}
</script>

<style lang="scss">
@import './App.scss';
</style>
